import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Box,
  List,
  ListItemText,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Pagination,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { DialogMode } from "../../types/dialogmode";
import { IVendor } from "../../types/vendor";
import VendorAsListItem from "./listitems/VendorAsListItem";

interface IVendorsToCreateListProps {
  mode: DialogMode;
  vendors?: IVendor[];
  vendorsWithProgress?: {
    vendor: IVendor;
    progress: number;
    createdVendor: IVendor;
  }[];
  handleDeleteVendorToCreate?: (index: number) => void;
  handleDeleteCreatedVendor?: (vendor: IVendor) => void;
  uploadsProgress?: { filename: string; progress: number }[];
}
function VendorsToCreateList({ ...props }: IVendorsToCreateListProps) {
  const {
    mode,
    vendors,
    vendorsWithProgress,
    handleDeleteVendorToCreate,
    handleDeleteCreatedVendor,
    uploadsProgress,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  // items per page should be as many as possible, depending on the window height

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(itemsPerPage);

  useEffect(() => {
    if (vendors && vendors?.length > 0) {
      setNumPages(Math.ceil(vendors.length / itemsPerPage));
    }
  }, [vendors, itemsPerPage]);

  // if the user deletes the last file on the last page, we need to go back one page
  useEffect(() => {
    if (pageNumber > numPages) {
      setPageNumber(numPages);
      setMin((numPages - 1) * itemsPerPage);
      setMax(numPages * itemsPerPage);
    }
  }, [pageNumber, numPages]);

  return (
    <Box>
      <List dense>
        {vendorsWithProgress?.map((vendor, i) => {
          if (i > max) return null;
          if (i < min) return null;

          return (
            <VendorAsListItem
              key={`VendorAsListItem-${vendor?.vendor?.name}-${i}`}
              onClick={() => console.log("file button clicked")}
              vendor={vendor?.vendor}
              onDelete={() =>
                mode !== DialogMode.ViewOnly &&
                handleDeleteVendorToCreate &&
                handleDeleteVendorToCreate(i)
              }
              uploading={vendor.progress > 0}
              uploadPercentProgress={vendor.progress}
              uploaded={vendor.progress === 100}
            />
          );
        })}
      </List>
      {numPages > 1 && (
        <Box mt={2} justifyContent="center" display="flex">
          <Pagination
            count={numPages}
            color="primary"
            size="small"
            showFirstButton
            showLastButton
            onChange={(event, value) => setPageNumber(value)}
            variant="text"
          />
        </Box>
      )}
    </Box>
  );
}

export default VendorsToCreateList;
