import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISignature, ISignee } from "../../../../types/signature";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { User, Users } from "react-feather";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { darken } from "polished";

const Divider = styled(MuiDivider)(spacing);

const Signee = styled(Grid)`
  width: 100%;
  padding: 4px;
  align-items: center;

  // background: ${(props) =>
    darken(0.03, props.theme.palette.background.default)};

  &:hover {
    // cursor: pointer;
    // background: ${(props) =>
      darken(0.03, props.theme.palette.background.default)};
  }
`;

const SigneeIcon = styled(User)`
  margin-right: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const SigneesIcon = styled(Users)`
  margin-right: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function RejectDialog({
  open,
  onClose,
  onReject,
}: {
  open: boolean;
  onClose: () => void;
  onReject: (reason: string) => void;
}) {
  const { t } = useTranslation();
  const [rejectReason, setRejectReason] = useState("");

  const handleReject = () => {
    onReject(rejectReason);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ px: 6, py: 4, minWidth: "600px" }}>
        <Typography variant="h3" color="primary" mb={3}>
          {t("Reject_to_Sign")}
        </Typography>
        <Typography variant="body1" mb={3}>
          {t(
            "Are you sure you want to reject this invitation? This action cannot be undone."
          )}
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          label={t("Reason")}
          multiline
          rows={3}
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />

        <Box textAlign="right" mt={4}>
          <Button variant="outlined" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: 3 }}
            disabled={!rejectReason}
            onClick={handleReject}
          >
            {t("Reject")}
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
}

export default RejectDialog;
