import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper as MuiPaper,
  Skeleton,
  Snackbar,
  SnackbarContent,
  SwipeableDrawer,
  Typography,
  Drawer as MuiDrawer,
  Backdrop,
  AppBar,
  Toolbar,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import { AddAPhoto, Folder } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Footer from "../components/Footer";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import dashboardItems from "../components/sidebar/dashboardItems";
import useAuth from "../hooks/useAuth";
import {
  hasAccessToken,
  setAccessTokenForTenant,
} from "../redux/slices/authSlice";
import {
  useGetTrustedPartnerTrustsQuery,
  useLazyGetAccessTokenForClientQuery,
} from "../redux/slices/indexApiSlice";
import { ITrustedPartnerTrust } from "../types/trustedpartner";
import { SidebarItemsType } from "../types/sidebar";
import AskMeAnythingChatBot from "../pages/components/AskMeAnythingChatBot";
import { useTranslation } from "react-i18next";
import { IGraphUser } from "../types/user";
import { setUsers as setUsersInStore } from "../redux/slices/usersSlice";
import { useLazyGetUsersQuery } from "../redux/slices/graphApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";
import InitiateDocumentsDialog from "../components/dialogs/InitiateDocumentsDialog";
import InitiateVendorsDialog from "../components/dialogs/InitiateVendorsDialog";
import NoteDialog from "../components/dialogs/NoteDialog";
import { DialogMode } from "../types/dialogmode";
import AnalyzingContractsLoader from "../components/AnalyzingContractsLoader";

const drawerBleeding = 56;
const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div<{ open?: boolean }>`
  z-index: ${(props) => props.theme.zIndex.drawer};

  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(props) => (props.open ? drawerWidth : drawerBleeding)}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div<{ skipSideNav?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: absolute;
  top: 60px;
  left: ${(props) => (props.skipSideNav ? 0 : drawerBleeding)}px;
  width: calc(100% - ${(props) => (props.skipSideNav ? 0 : drawerBleeding)}px);
  // height: 100%;
  overflow: visible;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  // overflow: auto; // hidden

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    // box-shadow: none;
  }
`;

const FixedContent = styled(Box)`
  position: fixed;
  right: 20;
  left: calc(258px + 20px);
  zindex: 1000;
  display: flex;
  alignitems: flex-end;
  justifycontent: flex-end;
  pointerevents: none;
`;

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export enum DisplayModes {
  DASHBOARD = "dashboard",
  MANAGEMENT = "management",
  CONNECTIONS = "connections",
}

interface DashboardType {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const router = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarItems, setSidebarItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Access the state passed during navigation
  // const reload = router.state && router.state.mode;
  const [mode, setMode] = useState<DisplayModes>(router.state?.mode);

  const accessToken = useSelector(
    (state: RootState) => state.auth?.accessToken
  );

  const [getUsers] = useLazyGetUsersQuery();

  // check if there are trusted partner trusts
  const { data: trusts = [], isSuccess } = useGetTrustedPartnerTrustsQuery(
    user.id ?? skipToken
  );

  const [getAccessTokenForClient] = useLazyGetAccessTokenForClientQuery();

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);

    // also close the drawer
    setOpen(false);
  }, [router.pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const removeHiddenPages = (arr: any) => {
    const newSidebarItems = arr.map((item: any) => {
      const newPages = item.pages?.filter((page: any) => !page.hidden);

      return {
        title: item.title,
        pages: newPages,
      };
    });

    return newSidebarItems;
  };

  useEffect(() => {
    setSidebarItems(removeHiddenPages(dashboardItems));
  }, []);

  useEffect(() => {
    if (isSuccess && accessToken) {
      if (trusts.length > 0) {
        // there are trusts, so show the contracts under management

        console.log("showing contracts under management", trusts);
        const newSidebarItems = dashboardItems.map((item) => {
          let index: number;
          const newPages = item.pages?.map(
            (page: SidebarItemsType, i: number) => {
              if (page?.title === "Managed Contracts") {
                index = i;
                return {
                  ...page,
                  hidden: false,
                  children: trusts.map((trust: ITrustedPartnerTrust) => {
                    return {
                      href: `/contracts-under-management/${trust.requestorId}/contracts`,
                      title: trust.requestorName,
                      icon: Folder,
                    };
                  }),
                };
              }

              return page;
            }
          );

          // // add each client as a main menu item
          // trusts.forEach((trust: ITrustedPartnerTrust) => {
          //   newPages.splice(index + 1, 0, {
          //     href: `/contracts-under-management/${trust.id}`,
          //     title: trust.requestorName,
          //     children: [],
          //     hidden: false,
          //     icon: FolderCopy,
          //   });
          // });

          return {
            title: item.title,
            pages: newPages,
          };
        });

        // remove the hidden items
        setSidebarItems(removeHiddenPages(newSidebarItems));

        // also, retrieve the access token for each trust
        trusts.forEach((trust: ITrustedPartnerTrust) => {
          getAccessTokenForClient(trust.requestorId)
            .unwrap()
            .then((payload) => {
              // send the access token to the store
              dispatch(
                setAccessTokenForTenant({
                  tenantId: trust.requestorId,
                  accessToken: payload,
                })
              );

              setLoading(false);
            });
        });
      } else {
        setLoading(false);
      }
    }
  }, [isSuccess, trusts, accessToken]);

  // Recursive function to fetch all users
  const getAllUsers = async (
    nextPage: string,
    arr: IGraphUser[]
  ): Promise<IGraphUser[]> => {
    // Fetch users for the current page
    const { data, error } = await getUsers({
      nextPage,
    });

    // If there's an error, throw it
    if (error) {
      throw new Error(`Failed to fetch users: ${error}`);
    }

    arr.push(...data.value);

    // If there's a next page, recursively fetch users for the next page
    if (data["@odata.nextLink"]) {
      await getAllUsers(data["@odata.nextLink"], arr);
    } else {
      // If there's no next page, return the users from the current page
      return arr || [];
    }

    return arr || [];
  };

  const fetchAllUsers = async () => {
    try {
      const allUsers = await getAllUsers("", []);

      // sort the users
      const usersSorted = allUsers?.sort((a, b) => {
        if (!a.displayName || !b.displayName) return 0;

        return a.displayName.localeCompare(b.displayName);
      });

      // add the users to the store
      dispatch(setUsersInStore(usersSorted));
    } catch (error) {
      console.error("Error fetching all users:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      // get all users
      fetchAllUsers();
    }
  }, [accessToken]);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const container = window !== undefined ? () => document.body : undefined;

  const isUpSm = window.innerHeight > 600;

  useEffect(() => {
    if (mode) {
      if (mode === DisplayModes.CONNECTIONS) {
        navigate("/admin/connected-drives", {
          state: { mode: DisplayModes.CONNECTIONS },
        });
      } else if (mode === DisplayModes.DASHBOARD) {
        navigate("/dashboard/analytics", {
          state: { mode: DisplayModes.DASHBOARD },
        });
      } else if (mode === DisplayModes.MANAGEMENT) {
        navigate("/", {
          state: { mode: DisplayModes.MANAGEMENT },
        });
      }
    }
  }, [mode]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: "100%",
            overflow: "visible",
            transition: "all 0.3s ease",
          },
          body: {
            // overflow: isUpSm ? "hidden" : "scroll",
          },
        }}
      />

      <Navbar
        onDrawerToggle={handleDrawerToggle}
        mode={mode}
        setMode={setMode}
      />

      <Drawer
        open={open}
        onMouseEnter={toggleDrawer(true)}
        // onBlur={toggleDrawer(false)}
        onMouseLeave={toggleDrawer(false)}
        hidden={
          mode === DisplayModes.CONNECTIONS || mode === DisplayModes.DASHBOARD
        }
      >
        <Toolbar />
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={sidebarItems}
            isDrawerOpen={open}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{
              style: { width: open ? drawerWidth : drawerBleeding },
            }}
            items={sidebarItems}
            isDrawerOpen={open}
          />
        </Box>
      </Drawer>

      {/* z index needs to be 1 higher than the drawer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={open}
      />

      <AppContent skipSideNav={mode && mode !== DisplayModes.MANAGEMENT}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={loading}
          message={t("Connecting to server")}
          action={<CircularProgress size="1.5rem" color="info" />}
          key={"bottomright"}
          autoHideDuration={5000}
        />

        {!loading && (
          <MainContent py={isLgUp ? 5 : 5} px={2} pl={5}>
            {children}
            <Outlet />
          </MainContent>
        )}

        <Grid size={12} height={100} />

        <Footer drawerBleeding={drawerBleeding} />
      </AppContent>
      <AskMeAnythingChatBot />
    </Root>
  );
};

export default Dashboard;
