import { SvgIcon } from "@mui/material";

const KvKLogo = (props: any) => (
  <SvgIcon {...props}>
    <svg
      className="fill-color-primary-petrol-base u-display-none u-display-inline-block@size-m u-vertical-align-middle"
      focusable="false"
      width="138px"
      height="16px"
      viewBox="0 0 621.74 81.02"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g fill="#00526e">
        <path d="M346.36,29.57h-5.05v-12.94h-13.04v12.94h-5.07V.4h5.07V12.54h13.04V.4h5.05V29.57Z"></path>
        <path d="M375.84,15.75c0,2.86-.49,5.37-1.48,7.52-.99,2.16-2.4,3.81-4.24,4.97s-3.95,1.73-6.34,1.73-4.47-.58-6.32-1.73-3.28-2.8-4.29-4.94c-1.01-2.14-1.52-4.6-1.53-7.39v-1.64c0-2.85,.5-5.36,1.51-7.53,1.01-2.18,2.43-3.84,4.27-5,1.84-1.15,3.94-1.73,6.32-1.73s4.48,.57,6.32,1.71c1.84,1.14,3.26,2.78,4.26,4.93,1,2.14,1.51,4.64,1.52,7.48v1.62Zm-5.07-1.52c0-3.23-.61-5.71-1.83-7.43-1.22-1.72-2.96-2.58-5.2-2.58s-3.9,.86-5.14,2.57c-1.24,1.72-1.87,4.14-1.89,7.28v1.68c0,3.21,.62,5.68,1.87,7.43,1.25,1.75,2.98,2.62,5.2,2.62s3.97-.85,5.18-2.56c1.21-1.71,1.81-4.21,1.81-7.49v-1.52Z"></path>
        <path d="M402.43,.4V19.9c0,3.1-.99,5.55-2.98,7.36-1.98,1.81-4.63,2.72-7.92,2.72s-5.99-.89-7.95-2.68c-1.96-1.78-2.95-4.26-2.95-7.42V.4h5.05V19.92c0,1.95,.49,3.44,1.48,4.47,.99,1.03,2.44,1.54,4.37,1.54,3.9,0,5.85-2.06,5.85-6.17V.4h5.05Z"></path>
        <path d="M419.15,23.22L426.56,.4h5.57l-10.5,29.17h-4.91L406.26,.4h5.55l7.33,22.82Z"></path>
        <path d="M446.74,22.78h-11.3l-2.36,6.79h-5.27L438.83,.4h4.55l11.04,29.17h-5.29l-2.38-6.79Zm-9.88-4.09h8.46l-4.23-12.1-4.23,12.1Z"></path>
        <path d="M472.35,22.06c0-1.28-.45-2.27-1.35-2.97-.9-.69-2.53-1.4-4.88-2.1-2.35-.71-4.22-1.5-5.61-2.36-2.66-1.67-3.99-3.85-3.99-6.53,0-2.35,.96-4.29,2.88-5.81s4.4-2.28,7.46-2.28c2.03,0,3.84,.37,5.43,1.12,1.59,.75,2.84,1.81,3.75,3.2,.91,1.38,1.36,2.92,1.36,4.6h-5.05c0-1.52-.48-2.71-1.43-3.58-.96-.86-2.32-1.29-4.1-1.29-1.66,0-2.94,.35-3.86,1.06-.91,.71-1.37,1.7-1.37,2.97,0,1.07,.49,1.96,1.48,2.67,.99,.71,2.62,1.41,4.89,2.08,2.27,.67,4.09,1.44,5.47,2.3,1.38,.86,2.38,1.85,3.03,2.97s.96,2.42,.96,3.92c0,2.43-.93,4.37-2.8,5.8-1.86,1.44-4.39,2.15-7.58,2.15-2.11,0-4.05-.39-5.82-1.17-1.77-.78-3.15-1.86-4.13-3.24-.98-1.38-1.47-2.98-1.47-4.81h5.07c0,1.66,.55,2.94,1.64,3.85,1.1,.91,2.67,1.36,4.71,1.36,1.76,0,3.09-.36,3.98-1.07,.89-.71,1.33-1.66,1.33-2.83Z"></path>
        <path d="M503.36,4.49h-9.1V29.57h-5.03V4.49h-9.02V.4h23.14V4.49Z"></path>
        <path d="M526.89,23.22l7.41-22.82h5.57l-10.5,29.17h-4.91L514.01,.4h5.55l7.33,22.82Z"></path>
        <path d="M565.74,15.75c0,2.86-.49,5.37-1.48,7.52-.99,2.16-2.4,3.81-4.24,4.97-1.84,1.16-3.95,1.73-6.34,1.73s-4.47-.58-6.32-1.73c-1.85-1.16-3.28-2.8-4.29-4.94-1.01-2.14-1.52-4.6-1.53-7.39v-1.64c0-2.85,.5-5.36,1.51-7.53,1.01-2.18,2.43-3.84,4.27-5,1.84-1.15,3.94-1.73,6.32-1.73s4.48,.57,6.32,1.71c1.84,1.14,3.26,2.78,4.26,4.93,1,2.14,1.51,4.64,1.52,7.48v1.62Zm-5.07-1.52c0-3.23-.61-5.71-1.83-7.43s-2.96-2.58-5.2-2.58-3.9,.86-5.14,2.57c-1.24,1.72-1.87,4.14-1.89,7.28v1.68c0,3.21,.62,5.68,1.87,7.43,1.25,1.75,2.98,2.62,5.2,2.62s3.97-.85,5.18-2.56c1.21-1.71,1.81-4.21,1.81-7.49v-1.52Z"></path>
        <path d="M594.49,15.75c0,2.86-.49,5.37-1.48,7.52-.99,2.16-2.4,3.81-4.24,4.97s-3.95,1.73-6.34,1.73-4.47-.58-6.32-1.73c-1.85-1.16-3.28-2.8-4.29-4.94-1.01-2.14-1.52-4.6-1.53-7.39v-1.64c0-2.85,.5-5.36,1.51-7.53,1.01-2.18,2.43-3.84,4.27-5,1.84-1.15,3.94-1.73,6.32-1.73s4.48,.57,6.32,1.71c1.84,1.14,3.26,2.78,4.26,4.93,1,2.14,1.51,4.64,1.52,7.48v1.62Zm-5.07-1.52c0-3.23-.61-5.71-1.83-7.43-1.22-1.72-2.96-2.58-5.2-2.58s-3.9,.86-5.14,2.57c-1.24,1.72-1.87,4.14-1.89,7.28v1.68c0,3.21,.62,5.68,1.87,7.43,1.25,1.75,2.98,2.62,5.2,2.62s3.97-.85,5.18-2.56c1.21-1.71,1.81-4.21,1.81-7.49v-1.52Z"></path>
        <path d="M610.46,18.35h-5.65v11.22h-5.07V.4h10.26c3.37,0,5.96,.76,7.79,2.27,1.83,1.51,2.75,3.7,2.75,6.56,0,1.95-.47,3.59-1.41,4.9-.94,1.32-2.25,2.33-3.94,3.04l6.55,12.15v.26h-5.43l-5.85-11.22Zm-5.65-4.09h5.21c1.71,0,3.05-.43,4.01-1.29,.96-.86,1.44-2.04,1.44-3.54s-.44-2.77-1.33-3.63c-.89-.85-2.21-1.3-3.98-1.32h-5.35V14.27Z"></path>
        <path d="M346.48,66.79c0,2.86-.49,5.37-1.48,7.52-.99,2.16-2.4,3.81-4.24,4.97s-3.95,1.73-6.34,1.73-4.47-.58-6.32-1.73-3.28-2.8-4.29-4.94c-1.01-2.14-1.52-4.6-1.53-7.39v-1.64c0-2.85,.5-5.36,1.51-7.53,1.01-2.18,2.43-3.84,4.27-5,1.84-1.16,3.94-1.73,6.32-1.73s4.48,.57,6.32,1.71c1.84,1.14,3.26,2.78,4.26,4.93,1,2.14,1.51,4.64,1.52,7.48v1.62Zm-5.07-1.52c0-3.23-.61-5.71-1.83-7.43-1.22-1.72-2.96-2.59-5.2-2.59s-3.9,.86-5.14,2.57c-1.24,1.72-1.87,4.15-1.89,7.28v1.68c0,3.21,.62,5.68,1.87,7.43,1.25,1.75,2.98,2.63,5.2,2.63s3.97-.85,5.18-2.56c1.21-1.71,1.81-4.21,1.81-7.49v-1.52Z"></path>
        <path d="M374.67,80.62h-5.07l-13-20.7v20.7h-5.07v-29.17h5.07l13.04,20.78v-20.78h5.03v29.17Z"></path>
        <path d="M380.46,80.62v-29.17h8.62c2.58,0,4.87,.57,6.86,1.72,2,1.15,3.54,2.78,4.64,4.89,1.1,2.11,1.64,4.53,1.64,7.25v1.46c0,2.77-.55,5.2-1.65,7.29-1.1,2.1-2.67,3.71-4.71,4.85-2.04,1.13-4.37,1.7-7,1.7h-8.4Zm5.07-25.09v21.04h3.31c2.66,0,4.7-.83,6.12-2.49,1.42-1.66,2.15-4.05,2.17-7.16v-1.62c0-3.17-.69-5.58-2.06-7.25-1.38-1.67-3.37-2.5-5.99-2.5h-3.55Z"></path>
        <path d="M424.32,67.59h-11.98v8.98h14.01v4.05h-19.07v-29.17h18.94v4.09h-13.87v8.05h11.98v4.01Z"></path>
        <path d="M441.6,69.4h-5.65v11.22h-5.07v-29.17h10.26c3.37,0,5.96,.76,7.79,2.27,1.83,1.51,2.74,3.7,2.74,6.56,0,1.95-.47,3.59-1.41,4.9-.94,1.32-2.25,2.33-3.94,3.04l6.55,12.15v.26h-5.43l-5.85-11.22Zm-5.65-4.09h5.21c1.71,0,3.05-.43,4.01-1.29,.96-.86,1.44-2.04,1.44-3.54s-.44-2.77-1.33-3.63c-.89-.85-2.21-1.3-3.98-1.32h-5.35v9.78Z"></path>
        <path d="M480.04,80.62h-5.07l-13-20.7v20.7h-5.07v-29.17h5.07l13.04,20.78v-20.78h5.03v29.17Z"></path>
        <path d="M502.88,67.59h-11.98v8.98h14.01v4.05h-19.07v-29.17h18.94v4.09h-13.87v8.05h11.98v4.01Z"></path>
        <path d="M516.21,51.44l8.42,22.36,8.39-22.36h6.55v29.17h-5.05v-9.62l.5-12.86-8.62,22.48h-3.63l-8.6-22.46,.5,12.84v9.62h-5.05v-29.17h6.57Z"></path>
        <path d="M562.41,67.59h-11.98v8.98h14.01v4.05h-19.07v-29.17h18.93v4.09h-13.87v8.05h11.98v4.01Z"></path>
        <path d="M579.7,69.4h-5.65v11.22h-5.07v-29.17h10.26c3.37,0,5.96,.76,7.79,2.27,1.83,1.51,2.75,3.7,2.75,6.56,0,1.95-.47,3.59-1.41,4.9-.94,1.32-2.25,2.33-3.94,3.04l6.55,12.15v.26h-5.43l-5.85-11.22Zm-5.65-4.09h5.21c1.71,0,3.05-.43,4.01-1.29,.96-.86,1.44-2.04,1.44-3.54s-.44-2.77-1.33-3.63c-.89-.85-2.21-1.3-3.98-1.32h-5.35v9.78Z"></path>
        <path d="M610.03,73.1c0-1.28-.45-2.27-1.35-2.97-.9-.69-2.53-1.39-4.88-2.1-2.35-.71-4.22-1.5-5.61-2.36-2.66-1.67-3.99-3.85-3.99-6.53,0-2.35,.96-4.29,2.87-5.81s4.41-2.28,7.46-2.28c2.03,0,3.84,.37,5.43,1.12,1.59,.75,2.84,1.81,3.75,3.2,.91,1.38,1.36,2.91,1.36,4.6h-5.05c0-1.52-.48-2.71-1.43-3.58-.96-.86-2.32-1.29-4.1-1.29-1.66,0-2.94,.35-3.86,1.06-.91,.71-1.37,1.7-1.37,2.97,0,1.07,.49,1.96,1.48,2.67,.99,.72,2.62,1.41,4.89,2.08,2.27,.68,4.09,1.44,5.47,2.3,1.38,.86,2.38,1.85,3.03,2.97s.96,2.42,.96,3.92c0,2.43-.93,4.36-2.8,5.8-1.86,1.44-4.39,2.15-7.58,2.15-2.11,0-4.05-.39-5.82-1.17-1.77-.78-3.15-1.86-4.13-3.24-.98-1.38-1.47-2.98-1.47-4.81h5.07c0,1.66,.55,2.94,1.64,3.85,1.1,.91,2.67,1.36,4.71,1.36,1.76,0,3.09-.36,3.98-1.07,.89-.71,1.33-1.66,1.33-2.84Z"></path>
      </g>
      <g fill="#00526e">
        <path d="M64.23,35.7L91.87,.4H56.56l-24.45,33.84V.4H.02v.03s-.02,.07-.02,.1c0,.03,0,.06,.02,.1V80.53H.05s.03,.07,.04,.1H32.1s.04-.07,.04-.11c0-.04-.01-.09-.03-.13v-30.1s-.07-2.19,2.09-2.98c2.16-.79,3.59,1.33,3.59,1.33l19.56,31.55c.01,.15,.14,.3,.36,.44h36.2c.05-.03,.12-.06,.16-.1h0l-29.86-44.83Z"></path>
        <path d="M265.07,35.7L292.71,.4h-35.3l-24.46,33.84V.4h-32.09v.03s-.02,.07-.02,.1c0,.03,0,.06,.02,.1V80.53h.04s.03,.07,.04,.1h32.01s.04-.07,.04-.11c0-.04-.01-.09-.03-.13v-30.1s-.07-2.19,2.09-2.98c2.16-.79,3.59,1.33,3.59,1.33l19.57,31.55c.01,.15,.14,.3,.36,.44h36.19c.06-.03,.12-.06,.16-.1h.01l-29.86-44.83Z"></path>
        <polygon points="96.7 .3 130.54 .3 144.48 45.13 144.69 45.13 158.74 .3 191.19 .3 161.41 80.61 125.18 80.61 96.7 .3"></polygon>
      </g>
      <title>KVK Houvast voor ondernemers</title>
    </svg>
    ;
  </SvgIcon>
);

export default KvKLogo;
