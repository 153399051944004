import React, { useEffect, useState, useTransition } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Typography,
  Autocomplete,
  Box,
  Tooltip,
  IconButton,
  Fade,
  CardActions,
  CardActionArea,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useGetTrustedPartnerInviteResponsesQuery } from "../../../redux/slices/indexApiSlice";

import {
  ITrustedPartnerInvite,
  ITrustedPartnerInviteResponse,
  ITrustedPartnerTrust,
} from "../../../types/trustedpartner";
import { DialogMode } from "../../../types/dialogmode";
import { Check } from "react-feather";
import DateTimeTranslate from "../../../components/DateTimeTranslate";
import TrustedPartnerInviteDialog from "../../../components/dialogs/TrustedPartnerInviteDialog";
import { format } from "date-fns";
import { getTenantId } from "../../../redux/slices/authSlice";
import { store } from "../../../redux/store";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

interface IInviteProps {
  invite: ITrustedPartnerInvite;
}
function Invite({ ...props }: IInviteProps) {
  const { t } = useTranslation();
  const { invite } = props;

  const { data: responses = [], refetch } =
    useGetTrustedPartnerInviteResponsesQuery(invite.id ?? skipToken);

  // get the tenant id from the store using getTenantId
  const tenantId = getTenantId(store.getState());

  return (
    <Grid key={`trusted-partner-invite-${invite.appRegistration?.tenantId}`}>
      <Card mb={6}>
        {/* <CardActionArea> uncomment to make clickable */}
        <CardContent>
          <Grid container spacing={6}>
            <Grid size={12}>
              {invite.tenantId === tenantId ? (
                <Typography variant="h6" gutterBottom>
                  {t("Invitation sent to", {
                    name: invite.partnerName,
                  })}
                </Typography>
              ) : (
                <Typography variant="h6" gutterBottom>
                  {t("Invitation received from", {
                    name: invite.requestorName,
                  })}
                </Typography>
              )}

              {invite.tenantId === tenantId ? (
                // we are on the requestor side
                <Typography variant="body2" gutterBottom>
                  {t("Invitation sent on", {
                    name: invite.author?.name,
                    date: invite.created && new Date(invite.created),
                  })}
                </Typography>
              ) : (
                <Typography variant="body2" gutterBottom>
                  {t("Invitation received on", {
                    name: invite.author?.name,
                    date: invite.created && new Date(invite.created),
                  })}
                </Typography>
              )}
            </Grid>

            {responses?.length > 0 && (
              <Grid>
                <Grid container spacing={6}>
                  {responses?.map((response) => {
                    return (
                      <Grid key={`trusted-partner-invite-${response.id}`}>
                        <Typography variant="h6" gutterBottom>
                          {t(response.response)} {t("by")}{" "}
                          {response.author?.name} ({response.author?.upn})
                        </Typography>
                        <Typography variant="body2" gutterBottom></Typography>
                        {invite.created && (
                          <Typography variant="body2" gutterBottom>
                            <DateTimeTranslate
                              date={new Date(invite.created)}
                              format="dateTime"
                            />
                          </Typography>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {/* </CardActionArea> */}

        <CardActions>
          {(invite.partnerId === tenantId && responses?.length) === 0 && (
            // no responses yet, so allow the user to accept or decline
            // display accept and decline buttons if we are on the partner side
            <TrustedPartnerInviteDialog
              invite={invite}
              mode={DialogMode.Edit}
              refetch={refetch}
            />
          )}

          {invite.tenantId === tenantId && responses?.length === 0 && (
            // no responses yet, so allow the user to revoke the invite
            // display the delete button if we are on the requestor side
            <TrustedPartnerInviteDialog
              invite={invite}
              mode={DialogMode.Delete}
              refetch={refetch}
            />
          )}

          {responses?.length > 0 && (
            // there are responses, so allow the user to revoke the decision
            <TrustedPartnerInviteDialog
              invite={invite}
              responses={responses}
              mode={DialogMode.Delete}
              refetch={refetch}
            />
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}

export default Invite;
