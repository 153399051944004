import styled from "@emotion/styled";
import {
  AutoAwesome,
  Cancel,
  CheckCircle,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ExternalLink } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DialogMode } from "../../types/dialogmode";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEstimateCostsMutation } from "../../redux/slices/aiSlice";
import { useLazyGetDriveItemContentAsPDFQuery } from "../../redux/slices/graphApiSlice";
import { IDocument } from "../../types/document";
import { use } from "i18next";

interface IAnalysisConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  documents: IDocument[];
  afterUpload?: boolean;
}

const AnalysisConfirmationDialog = ({
  ...props
}: IAnalysisConfirmationDialogProps) => {
  const { t } = useTranslation();

  const [totalCost, setTotalCost] = useState<number>(0);
  const [allDone, setAllDone] = useState<boolean | null>();

  const [
    requestCosts,
    { data: estimatedCosts, isLoading: gettingCosts, isSuccess: costsSuccess },
  ] = useEstimateCostsMutation();

  const [getDriveItemContentAsPDF, { data: driveItemContentAsPDF }] =
    useLazyGetDriveItemContentAsPDFQuery();

  useEffect(() => {
    if (costsSuccess) {
      setTotalCost(
        (prev: number) =>
          prev + parseFloat(estimatedCosts.AnalyzeCostWithMarginFloat)
      );
    }
  }, [costsSuccess]);

  const resetAndClose = () => {
    props.onCancel();
  };

  const handleConfirm = (e: any) => {
    e.stopPropagation();

    props.onConfirm();

    resetAndClose();
  };

  const getDocumentCost = async (document: IDocument): Promise<number> => {
    if (document.location === "sharepoint") {
      // we need to pass a downloadUrl
      const response = await getDriveItemContentAsPDF(document);
      if (response.data) {
        const downloadUrl = response.data;
        await requestCosts({ ...document, downloadUrl });
      }
    } else {
      await requestCosts(document);
    }

    return 0;
  };

  const requestCostEstimation = async () => {
    setTotalCost(0);
    setAllDone(false);

    // request the cost estimation for each document
    for (const document of props.documents) {
      await getDocumentCost(document);
    }

    console.log("totalCost", totalCost);
    setAllDone(true);
  };

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {t("Start AI analysis")}
        </DialogTitle>

        <DialogContent>
          <Box textAlign="center" mt={3} height={100}>
            <LoadingButton
              variant="contained"
              loading={gettingCosts || allDone === false}
              onClick={requestCostEstimation}
            >
              {t("Estimate costs")}
            </LoadingButton>

            {totalCost > 0 && !gettingCosts && allDone === true && (
              <Fade in={totalCost > 0} timeout={1000}>
                <Box mt={2}>
                  <Typography variant="caption" color="textSecondary">
                    {t("Estimated_costs_result", {
                      cost: new Intl.NumberFormat(navigator.language, {
                        style: "currency",
                        currency: "USD",
                        compactDisplay: "long",
                        maximumFractionDigits: 2,
                      }).format(totalCost),
                    })}
                  </Typography>
                </Box>
              </Fade>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => resetAndClose()}
            color="primary"
            autoFocus
            variant="outlined"
          >
            {props.afterUpload
              ? t("Continue without AI analysis")
              : t("Cancel")}
          </Button>

          <Button onClick={handleConfirm} variant="contained" color="primary">
            {t("Start AI analysis")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnalysisConfirmationDialog;
