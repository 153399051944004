import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  useAddSharePointConnectionMutation,
  useDeleteSharePointConnectionMutation,
} from "../../redux/slices/indexApiSlice";
import {
  AutoFixHigh,
  Delete,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  Cancel,
  Close as CloseIcon,
  Preview,
  PreviewRounded,
} from "@mui/icons-material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import { IActivity } from "../../types/activity";
import useAuth from "../../hooks/useAuth";
import {
  useGetDrivesQuery,
  useGetFollowedSitesQuery,
} from "../../redux/slices/graphApiSlice";
import { ISharePointConnection } from "../../types/sharepointconnection";
import SharePointConnectionForm from "../forms/SharePointConnectionForm";
import { IDriveItem } from "../../types/driveItem";
import DriveItemCard from "../cards/DriveItemCard";
import { grey } from "@mui/material/colors";
import { ba } from "@fullcalendar/core/internal-common";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().optional(),
  endDate: Yup.date().optional(),
  value: Yup.number().optional(),
});

type SharePointFilesDialogProps = {
  driveItems: IDriveItem[];
};

const SharePointFilesDialog = (props: SharePointFilesDialogProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={() => setOpen(true)}
        startIcon={<Preview />}
      >
        {t("View files", { count: props.driveItems?.length })}
      </Button>

      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("View files", { count: props.driveItems?.length })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {/* <DialogContentText>Files in this drive</DialogContentText> */}

          <Grid
            container
            spacing={3}
            p={4}
            direction="row"
            sx={
              {
                // backgroundColor: "background.default",
              }
            }
          >
            {props.driveItems?.map((item: any) => (
              <Grid key={`drive-item-${item.id}`} width={250} height={350}>
                <DriveItemCard driveItem={item} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveClick()} color="primary">
            Save
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SharePointFilesDialog;
