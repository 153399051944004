import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  Divider as MuiDivider,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar as MuiAvatar,
  SvgIcon,
  ListItemText,
  IconButton as MuiIconButton,
  Box,
  ListItemButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { Alarm, Clear } from "@mui/icons-material";
import { INotification } from "../../../types/notification";
import {
  useDeleteNotificationMutation,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
} from "../../../redux/slices/indexApiSlice";
import { rgba } from "polished";
import {
  convertCreatedDate,
  convertNotificationDate,
} from "../../../utils/string";
import { isUserUnReadNotification } from "../../../utils/userReadyNotification";
import useAuth from "../../../hooks/useAuth";

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const Divider = styled(MuiDivider)(spacing);
const IconButton = styled(MuiIconButton)(spacing);

function NotificationItem({
  notification,
  Icon,
  hasBeenRead,
  onDelete,
}: {
  notification: INotification;
  Icon: React.ElementType;
  hasBeenRead: boolean;
  onDelete: any;
}) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [deleteNotification, { isLoading: isDeleting, isSuccess: isDeleted }] =
    useDeleteNotificationMutation();
  const [updateNotification, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateNotificationMutation();
  const [isRead, setIsRead] = useState(hasBeenRead);
  const navigate = (notification: INotification) => {
    switch (notification.type) {
      case "contractExpiry":
      case "note": {
        return `/contracts/${notification.contractId}/viewOnly`;
      }
      default: {
        return "#";
      }
    }
  };

  const handleConfirmDelete = async (e: any) => {
    setIsDisabled(true);
    e.stopPropagation();
    e.preventDefault();
    // Call the delete function to delete the item
    await deleteNotification(notification.id);
    onDelete(notification.id);
  };

  const handleReadNotification = async (e: any) => {
    // Call the delete function to delete the item
    await updateNotification(notification);
    setIsRead(true);
  };

  return (
    <ListItemButton
      divider
      onClick={handleReadNotification}
      disabled={isDisabled}
    >
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          notification.type === "contractExpiry"
            ? t("Contract Expiry")
            : t("Note")
        }
        primaryTypographyProps={{
          variant: "subtitle2",
          color: isRead ? "textSecondary" : "textPrimary",
          sx: { fontWeight: isRead ? "regular" : "bold" },
        }}
        secondary={
          <>
            <Box>
              {notification?.type === "contractExpiry" &&
                convertNotificationDate(notification)}
              {notification?.type === "note" && notification.text}{" "}
              <Box
                component={Link}
                to={navigate(notification)}
                sx={{ textDecoration: "none" }}
              >
                {t("go_to_contract")}.
              </Box>
            </Box>
            <Typography color="textSecondary" variant="body2">
              {convertCreatedDate(notification.insertionTime)}
            </Typography>
          </>
        }
        secondaryTypographyProps={{
          color: isRead ? "textSecondary" : "textPrimary",
          sx: { fontWeight: isRead ? "regular" : "bold" },
        }}
      />
      <IconButton
        aria-label="Delete"
        mx={2}
        onClick={handleConfirmDelete}
        disabled={isDisabled}
      >
        <Clear />
      </IconButton>
    </ListItemButton>
  );
}

function NotificationList() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [unread, setUnread] = useState<INotification[]>([]);
  const {
    data: notifications,
    isSuccess,
    refetch,
  } = useGetNotificationsQuery();
  useEffect(() => {
    if (isSuccess) {
      setUnread(
        notifications.filter((n: INotification) =>
          isUserUnReadNotification(n, user?.id)
        )
      );
    }
  }, [notifications, isSuccess]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onDelete = (id: any) => {
    refetch();
  };

  return (
    <React.Fragment>
      <Helmet title={t("Notifications")!} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Notifications")}
          </Typography>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Typography variant="h5" gutterBottom display="inline">
        {t("Most Recent")}
      </Typography>
      <Grid>
        {notifications && notifications?.length > 0 ? (
          <List disablePadding>
            {notifications?.map((notification: INotification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                Icon={Alarm}
                onDelete={onDelete}
                hasBeenRead={!isUserUnReadNotification(notification, user?.id)}
              />
            ))}
          </List>
        ) : (
          <Box
            sx={{ borderRadius: "16px" }}
            mt={6}
            p={10}
            display="flex"
            justifyContent="center"
            bgcolor={(props) => rgba(props.palette.primary.main, 0.125)}
          >
            <Typography
              variant="h5"
              color="textPrimary"
              sx={{ color: (props) => props.palette.primary.main }}
            >
              {t("No new notifications")}
            </Typography>
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default NotificationList;
