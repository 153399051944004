import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";

import { IContract } from "../../../../types/contract";

interface IContractCategoryProps {
  contract?: IContract;
  hideTitle?: boolean;
}
function ContractCategory({ ...props }: IContractCategoryProps) {
  const { contract } = props;
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Category")}
        </Typography>
      )}

      <Typography variant="body2">
        {/* color="textSecondary" */}
        {contract?.category}
      </Typography>
    </Box>
  );
}

export default ContractCategory;
