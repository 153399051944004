import React, { useEffect, useState } from "react";
import { IVendor } from "../../types/vendor";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Autocomplete,
  Slider,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import {
  DatePicker,
  StaticDatePicker,
  DateTimePicker,
  StaticDateTimePicker,
  TimePicker,
  StaticTimePicker,
} from "@mui/x-date-pickers";
import DataGridKPIs from "../datagrids/DataGridKPIs";
import { IKPI } from "../../types/kpi";
import { DialogMode } from "../../types/dialogmode";
import { useTranslation } from "react-i18next";
import useAppSelector from "../../hooks/useAppSelector";
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

// Define the color gradient based on score values
const ScoreSlider = styled(Slider)(({ theme }) => ({
  color:
    "linear-gradient(90deg, rgba(36,0,0,1) 0%, rgba(15,121,9,1) 61%, rgba(0,255,51,1) 100%)",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string().optional(),
  date: Yup.date().optional(),
  score: Yup.number().optional(),
  comment: Yup.string().optional(),
});

function KPIForm({ ...props }) {
  const { t } = useTranslation();
  const [kpiTab, setKpiTab] = useState("new");
  const kpis: IKPI[] = useAppSelector((state) => state.kpi.kpiList);
  const handleKpiTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setKpiTab(newValue);
  };
  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (props.mode === DialogMode.Add) {
        await props.addKPI(values);

        //  resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === DialogMode.Edit) {
        await props.updateKPI(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleSelectKPIFromLibrary = async (kpis: IKPI[], helpers: any) => {
    if (kpis?.length > 0) {
      const kpi = kpis[kpis?.length - 1];
      helpers.setFieldValue("kpiId", kpi.id);
      helpers.setFieldValue("name", kpi.name);
      helpers.setFieldValue("description", kpi.description);
      setValue({
        name: kpi.name,
        description: kpi.description,
        kpiId: kpi?.id,
      });
      //  setKpiTab("new");
    }
  };

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const initialValues = props.vendor
    ? { ...props.kpi, vendorId: props.vendor.id }
    : props.contract
    ? { ...props.kpi, contractId: props.contract.id }
    : { ...props.kpi };
  let [value, setValue] = React.useState<any>(
    props.mode === DialogMode.Edit ? props.kpi : null
  );
  const filter = createFilterOptions<any>();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Card mb={6}>
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                {t("Your data has been submitted successfully!")}
              </Alert>
            )}

            {errors && !isValid && !status?.sent && (
              <Grid>
                <Alert severity="error" my={3}>
                  {t("An error occurred!")}
                  {errors && Object.keys(errors).length > 0 && (
                    <pre style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(errors, null, 2)}
                    </pre>
                  )}
                </Alert>
              </Grid>
            )}

            {/* {isSubmitting && <CircularProgress />} */}

            <Form id="kpi-form" onSubmit={handleSubmit}>
              <Grid container gap={3} flexDirection="column">
                <Grid>
                  <Grid container gap={3} flexDirection="column">
                    {/* {(props.vendor || props.contract) && (
                      <Grid>
                        <Tabs value={kpiTab} onChange={handleKpiTabChange}>
                          <Tab label={t("New")} value="new" />
                          <Tab label={t("Choose from library")} value="library" />
                        </Tabs>
                      </Grid>
                    )} */}

                    {/* {kpiTab === "new" && ( */}
                    <Grid>
                      <Grid container gap={3} flexDirection="column">
                        <Grid>
                          {props.vendor || props.kpi?.vendorId ? (
                            <Autocomplete
                              value={value}
                              onChange={(event, newValue) => {
                                if (typeof newValue === "string") {
                                  setValue({
                                    name: newValue,
                                  });
                                  setFieldValue("name", newValue);
                                  setFieldValue("description", "");
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  setValue({
                                    name: newValue.inputValue,
                                  });
                                  setFieldValue("name", newValue?.inputValue);
                                  setFieldValue("description", "");
                                } else {
                                  setValue(newValue);
                                  setFieldValue("name", newValue?.name);
                                  setFieldValue(
                                    "description",
                                    newValue?.description ?? ""
                                  );
                                }
                              }}
                              // filterOptions={(options, params) => {
                              //   const filtered = filter(options, params);

                              //   const { inputValue } = params;
                              //   // Suggest the creation of a new value
                              //   const isExisting = options.some((option) => inputValue === option.name);
                              //   if (inputValue !== '' && !isExisting) {
                              //     filtered.push({
                              //       inputValue,
                              //       name: `Add "${inputValue}"`,
                              //     });
                              //   }

                              //   return filtered;
                              // }}
                              selectOnFocus
                              // clearOnBlur
                              handleHomeEndKeys
                              id="vendor-kpi-name-field"
                              options={
                                kpis?.map((option: IKPI) => {
                                  return {
                                    ...option,
                                    group: t("Choose from library"),
                                  };
                                }) ?? []
                              }
                              groupBy={(option) => option.group}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                              }}
                              renderOption={(props, option) => (
                                <li {...props} key={props.id}>
                                  {option.name}
                                </li>
                              )}
                              freeSolo
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  name="name"
                                  value={values?.name}
                                  error={Boolean(touched.name && errors.name)}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  label={t("Name")}
                                />
                              )}
                            />
                          ) : (
                            <TextField
                              name="name"
                              label={t("Name")}
                              value={values.name}
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              // helperText={touched.name && errors.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          )}
                        </Grid>
                        <Grid>
                          <TextField
                            name="description"
                            label={t("Description")}
                            InputLabelProps={{
                              shrink: values?.description?.length > 0,
                            }}
                            value={values.description}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            // helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                            multiline={true}
                            minRows={3}
                            maxRows={5}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* )}

                    {kpiTab === "library" && (
                      <Grid>
                        <DataGridKPIs onSelect={(sel: IKPI[]) => handleSelectKPIFromLibrary(sel, { setFieldValue })} />
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>

                {(props.vendor || props.kpi?.vendorId) && (
                  <>
                    <Grid size={6}>
                      <DatePicker
                        label={t("Start date")}
                        value={new Date(values.date)}
                        onChange={(date) => setFieldValue("date", date)}
                        disablePast
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                      />
                    </Grid>

                    <Grid size={6}>
                      <Box>
                        <Typography id="score-slider" gutterBottom>
                          {t("Score")}
                        </Typography>
                        <Grid container spacing={8} alignItems="center">
                          <Grid>
                            <Slider
                              name="score"
                              defaultValue={initialValues?.score}
                              value={values.score}
                              // valueLabelDisplay="auto"
                              aria-labelledby="score-slider"
                              step={1}
                              marks
                              min={0}
                              max={10}
                              onChange={(event: any, value: any) =>
                                setFieldValue("score", value)
                              }
                              color="primary"
                            />
                          </Grid>
                          <Grid>
                            <Avatar
                              sx={{
                                backgroundColor:
                                  values.score < 5
                                    ? "red"
                                    : values.score < 8
                                    ? "orange"
                                    : "green",
                                color: (theme) => theme.palette.common.white,
                              }}
                            >
                              {values.score || "?"}
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid size={6}>
                      <TextField
                        name="comment"
                        label={t("Comment")}
                        value={values.comment}
                        error={Boolean(touched.comment && errors.comment)}
                        fullWidth
                        // helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        multiline={true}
                        minRows={3}
                        maxRows={5}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default KPIForm;
