import { SvgIcon } from "@mui/material";

const KvKIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="layer"
      x="0px"
      y="0px"
      viewBox="0 0 652 652"
    >
      <g>
        <path
          fill="#00526E"
          d="M507.5,339.5c-1.4-2.3-2.4-3.6-3.6-4.3c-2.7-1.6-6.5-0.6-8.2,2.3c-1,1.9-1,6-1,7.4v57.8h-65.3V239.3h65.3v68.9   l49.8-68.9h71.9l-56.3,71.9l61,91.5h-74.5L507.5,339.5z M275.2,402.7H349l60.6-163.3h-66.1L315,330.4h-0.5l-28.3-91.1h-68.9   L275.2,402.7z M138.3,402.7h74.5l-61-91.5l56.3-71.9h-71.9l-49.8,68.9v-68.9H21.1v163.3h65.3v-57.8c0-1.4,0-5.6,1-7.4   c1.7-2.9,5.5-3.9,8.2-2.3c1.2,0.7,2.2,2,3.6,4.3L138.3,402.7z"
        />
      </g>
      <title>KVK</title>
    </svg>
  </SvgIcon>
);

export default KvKIcon;
