import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  AvatarGroup,
  Box,
  Button,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { useGetClientsUnderManagementQuery } from "../../../redux/slices/indexApiSlice";

import { useTranslation } from "react-i18next";

import { ITrustedPartnerInvite } from "../../../types/trustedpartner";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function ClientsUnderManagement() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Access the state passed during navigation
  const reload = location.state && location.state.reload;

  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
    }
  }, [refetch]);

  useEffect(() => {
    if (reload) {
      setRefetch(true);
    }
  }, [reload]);

  const { data: invites } = useGetClientsUnderManagementQuery();

  return (
    <React.Fragment>
      <Helmet title="Contracts" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Contracts")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Dashboard")}
            </Link>
            <Typography> {t("Contracts")}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent pb={1}>
          <Typography variant="h6" gutterBottom>
            Contracts
          </Typography>
          <Typography variant="body2" gutterBottom>
            A fast and reliable way to manage your contracts.
          </Typography>

          <Box>
            {invites?.map((invite: ITrustedPartnerInvite) => {
              return (
                <Card key={invite.id} mb={6}>
                  <CardActionArea>
                    <CardMedia
                      // image={thumbnails.value[0].medium?.url}
                      title={invite.requestorName}
                    />

                    <CardContent>
                      <Tooltip
                        title={invite.requestorName}
                        arrow
                        placement="top"
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            lineHeight: "1.2em",
                            height: "2.4em",
                          }}
                        >
                          {invite.requestorName}
                        </Typography>
                      </Tooltip>
                    </CardContent>
                  </CardActionArea>

                  <CardActions>
                    <Button
                      // onClick={() => handleImportClick()}
                      variant="contained"
                      onClick={() => navigate(`${invite.tenantId}/contracts`)}
                      // disabled={importing || addingDocument}
                    >
                      {t("View Contracts")}
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ClientsUnderManagement;
