import styled from "@emotion/styled";
import { format } from "date-fns";
import { PDFDocument } from "pdf-lib";
import {
  AppBar,
  Box,
  Card as MuiCard,
  Chip as MuiChip,
  Paper,
  Toolbar,
  Typography,
  CardContent,
  CardHeader,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import DocumentViewer from "../../../../components/viewers/DocumentViewer";
import AIContractInformation from "../components/ContractMetadata";
import AIContractSigneeManagement from "../components/AIContractSigneeManagement";
import { ReactComponent as Logo } from "../../../../vendor/logo.svg";
import { ISignature, ISignee } from "../../../../types/signature";
import { useGetDocumentContentQuery } from "../../../../redux/slices/indexApiSlice";
import { createFileFromUrl } from "../../../../utils/file";
import {
  useAddContractAISignatureMutation,
  useAddSignatureDocumentContentMutation,
  useGetContractAISignatureQuery,
} from "../../../../redux/slices/digitalSigningSlice";
import { DialogMode } from "../../../../types/dialogmode";
import ContractHeader from "../components/ContractHeader";
import GoBeyondLogo from "../../../../icons/GoBeyondLogo";
import SignButton from "./SignButton";
import RejectDialog from "./RejectDialog";
import { darken } from "polished";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@mui/icons-material";
import DateTimeTranslate from "../../../../components/DateTimeTranslate";
const mimeDb = require("mime-db");

const Card = styled(MuiCard)(spacing);

const SigningReceipt = ({
  signer,
  signature,
}: {
  signer?: ISignee;
  signature?: ISignature;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card my={6}>
      <CardHeader
        title={
          <Typography
            variant="h6"
            textAlign="center"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <CheckCircle color="success" sx={{ mr: 2 }} />
            {signature?.isApproved && t("You have signed this contract.")}
            {!signature?.isApproved && t("You have rejected this contract.")}
          </Typography>
        }
      />
      <CardContent>
        <Box justifyContent="center" display="flex" flexDirection="column">
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              border: "1px dotted #000",
            }}
          >
            <Typography variant="h5" align="center" mb={2}>
              {t("Receipt")}
            </Typography>
            <Grid container direction="column" display="flex">
              <Grid>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 6, md: 4 }}>
                    <Typography variant="body1"> {t("Name")}:</Typography>
                  </Grid>
                  <Grid size={{ xs: 6, md: 8 }}>
                    <Typography variant="body1">
                      {signer?.displayName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 6, md: 4 }}>
                    <Typography variant="body1">{t("Email")}:</Typography>
                  </Grid>
                  <Grid size={{ xs: 6, md: 8 }}>
                    <Typography variant="body1" noWrap>
                      {signer?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 6, md: 4 }}>
                    <Typography variant="body1">{t("Date")}:</Typography>
                  </Grid>
                  <Grid size={{ xs: 6, md: 8 }}>
                    <Typography variant="body1" noWrap>
                      {signature?.signedDate && (
                        <DateTimeTranslate
                          date={new Date(signature.signedDate)}
                          format="dateTimeAndTimeZone"
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        {/* {isSuccess && <Box mt={3}>{JSON.stringify(companyProfile)}</Box>} */}
      </CardContent>
    </Card>
  );
};

export default SigningReceipt;
