import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  useAddSharePointConnectionMutation,
  useDeleteSharePointConnectionMutation,
} from "../../redux/slices/indexApiSlice";
import {
  AutoFixHigh,
  Delete,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  Cancel,
} from "@mui/icons-material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import { IActivity } from "../../types/activity";
import useAuth from "../../hooks/useAuth";
import {
  useGetDrivesQuery,
  useGetFollowedSitesQuery,
} from "../../redux/slices/graphApiSlice";
import { ISharePointConnection } from "../../types/sharepointconnection";
import SharePointConnectionForm from "../forms/SharePointConnectionForm";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().optional(),
  endDate: Yup.date().optional(),
  value: Yup.number().optional(),
});

type ISharePointConnectionDialogProps = {
  mode: DialogMode;
  newAIinfo?: boolean;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  connection?: ISharePointConnection;
  initialValues?: any;
};

function SharePointConnectionDialog(props: ISharePointConnectionDialogProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [site, setSite] = React.useState("");

  const [
    addConnection,
    { data: addedConnection, isSuccess: isAdded, reset: resetAdd },
  ] = useAddSharePointConnectionMutation();

  const [
    deleteConnection,
    { isLoading: isDeleting, isSuccess: isDeleted, reset: resetDelete },
  ] = useDeleteSharePointConnectionMutation();

  const { user } = useAuth();

  const navigate = useNavigate();

  // const handleSiteChange = (event: SelectChangeEvent) => {
  //   setSite(event.target.value as string);
  // };

  // const handleDriveChange = (event: SelectChangeEvent) => {
  //   setDrive(event.target.value as string);
  // };

  const resetAndClose = () => {
    setOpen(false);

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    if (isDeleted) {
      // redirect to the connections page
      navigate("/connections/sharepoint", {
        state: { reload: true },
      });

      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (isAdded || isDeleted) {
      // || isUpdated
      setSuccess(true);

      if (isAdded) {
        resetAdd();

        // // log activity
        // const activity: IActivity = {
        //   id: "",
        //   date: new Date().toISOString(),
        //   entity: "contract",
        //   contractId: addedContract.id,
        //   vendorId: addedContract.vendorId,
        //   event: "added",
        //   user: user?.id,
        // };

        // addActivity(activity);
      }

      // if (isUpdated) {
      //   resetUpdate();

      //   // // log activity
      //   // const activity: IActivity = {
      //   //   id: "",
      //   //   date: new Date().toISOString(),
      //   //   entity: "contract",
      //   //   contractId: props.contract ? props.contract.id : "",
      //   //   vendorId: props.contract ? props.contract.vendorId : "",
      //   //   event: "updated",
      //   //   user: user?.id,
      //   // };

      //   // addActivity(activity);
      // }

      if (isDeleted) {
        resetDelete();

        // // log activity
        // const activity: IActivity = {
        //   id: "",
        //   date: new Date().toISOString(),
        //   entity: "contract",
        //   contractId: props.connection ? props.contract.id : "",
        //   vendorId: props.contract ? props.contract.vendorId : "",
        //   event: "deleted",
        //   user: user?.id,
        // };

        // addActivity(activity);
      }
    }
  }, [isAdded, isDeleted]); // isUpdated

  const handleAddConnection = async (connection: ISharePointConnection) => {
    // const connection = {
    //   siteId: site,
    //   driveId: drive,
    //   webUrl: sites?.find((s: any) => s.id === site)?.webUrl,
    //   siteDisplayName: sites?.find((s: any) => s.id === site)?.displayName,
    //   driveName: drives?.find((d: any) => d.id === drive)?.name,
    // };

    await addConnection(connection);

    setSuccess(true);

    // close the dialog
    resetAndClose();
  };

  // const handleUpdateContract = async (contract: IContract) => {
  //   await updateConnection(contract);

  //   setSuccess(true);
  //   // close the dialog
  //   // setOpen(false);
  // };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.connection?.id) {
      await deleteConnection(props.connection.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button color="primary" onClick={() => setOpen(true)}>
                  {t("Delete connection")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {mode === DialogMode.Add && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          {t("New connection")}
        </Button>
      )}

      {mode === DialogMode.Edit &&
        (props.iconOnly ? (
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <Button color="primary" onClick={handleEditClick}>
            {t("Edit connection")}
          </Button>
        ))}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New connection")
            : mode === DialogMode.Edit
            ? `${t("Edit")} ${props.connection?.driveName}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this connection?")
            : ""}
        </DialogTitle>
        {mode !== DialogMode.Delete && (
          <DialogContent>
            {/* <DialogContentText>{mode === "add" ? "Add a new contract." : "Edit the contract"}</DialogContentText> */}

            <SharePointConnectionForm
              mode={mode}
              addConnection={handleAddConnection}
              updateConnection={handleAddConnection}
            />
            {/* <ContractForm
              mode={mode}
              addContract={handleAddContract}
              updateContract={handleUpdateContract}
              contract={props.contract}
              initialValues={props.initialValues}
            /> */}
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button onClick={() => resetAndClose()} color="primary">
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="sharepoint-connection-form"
                  color="primary"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button onClick={handleConfirmDelete} color="primary">
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SharePointConnectionDialog;
