import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  TextField as MuiTextField,
  Alert as MuiAlert,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing, SpacingProps, width } from "@mui/system";

import DataGridKPIs from "../../../../components/datagrids/DataGridKPIs";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../../../types/dialogmode";
import useAuth from "../../../../hooks/useAuth";
import { IVendor } from "../../../../types/vendor";
import { useSyncVendorWithKVKMutation } from "../../../../redux/slices/kvkSlice";
import { ICompanyProfileKVK } from "../../../../../../server/types/companyprofilekvk";
import LoadingButton from "@mui/lab/LoadingButton";
import { format } from "date-fns";
import KvKIcon from "../../../../icons/KvKIcon";
import VendorDetailSidebar from "./VendorDetailSidebar";
import KvKLogo from "../../../../icons/KvKLogo";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const timeOut = (time: number) => new Promise((res) => setTimeout(res, time));

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

interface IVendorKVKProps {
  vendor: IVendor;
  refetch: () => void;
  setErrors: React.Dispatch<React.SetStateAction<any[]>>;
}

function VendorKVK({ ...props }: IVendorKVKProps) {
  const { t } = useTranslation();
  const { vendor, refetch } = props;

  // const [
  //   getCompanyProfile,
  //   {
  //     data: companyProfile,
  //     isLoading: gettingCompanyProfile,
  //     isSuccess,
  //     isError,
  //     error,
  //   },
  // ] = useLazyGetCompanyProfileQuery();

  const [
    syncVendorWithKVK,
    {
      isSuccess: isUpdated,
      isLoading: isUpdating,
      isError: updateError,
      error: updateErrorData,
      data: updatedVendor,
      reset: resetUpdate,
    },
  ] = useSyncVendorWithKVKMutation();

  // useEffect(() => {
  //   if (isSuccess && companyProfile) {
  //     const bezoekAdres =
  //       companyProfile?._embedded?.hoofdvestiging?.adressen?.filter(
  //         (a: any) => a.type?.toLowerCase() === "bezoekadres"
  //       )[0];

  //     const correspondentieAdres =
  //       companyProfile?._embedded?.hoofdvestiging?.adressen?.filter(
  //         (a: any) => a.type?.toLowerCase() === "correspondentieadres"
  //       )[0];

  //     const adres = bezoekAdres || correspondentieAdres;

  //     const vendorWithProfile: IVendor = {
  //       ...vendor,
  //       name: companyProfile?.naam,
  //       street:
  //         adres?.straatnaam ||
  //         adres?.volledigAdres ||
  //         adres?.straatHuisnummer ||
  //         vendor.street ||
  //         "",
  //       houseNumber: `${adres?.huisnummer || ""}${
  //         adres?.huisnummerToevoeging || ""
  //       }`,
  //       postalCode: adres?.postcode || vendor.postalCode || "",
  //       city: adres?.plaats || vendor.city || "",
  //       country: adres?.land || vendor.country || "",
  //       website:
  //         companyProfile?._embedded?.hoofdvestiging?.websites?.length > 0
  //           ? companyProfile?._embedded.hoofdvestiging.websites[0]
  //           : vendor.website || "",
  //     };

  //     updateVendor(vendorWithProfile);
  //   }
  // }, [isSuccess, companyProfile]);

  // useEffect(() => {
  //   // catch the errors
  //   if (isError) {
  //     props.addError(error);
  //   }
  // }, [isError]);

  useEffect(() => {
    resetUpdate();
  }, [vendor?.id]);

  useEffect(() => {
    // catch the errors
    if (updateError) {
      props.setErrors((errors) => [...errors, updateErrorData]);
    }
  }, [updateError]);

  useEffect(() => {
    if (isUpdated) {
      if (props.refetch) {
        props.refetch();
      }
    }
  }, [isUpdated]);

  const handleRefreshKVK = async () => {
    props.setErrors([]);
    syncVendorWithKVK(props.vendor);
  };

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box width="100%">
        <Grid container justifyContent="space-between" direction="row">
          <Grid>
            <Typography variant="h6" mb={2}>
              {t("Company profile")}
            </Typography>
          </Grid>

          <Grid>
            <KvKLogo sx={{ width: 220, marginRight: 2 }} />
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="column">
          <Grid>
            <Typography>
              {t("Registration number")}: {vendor?.kvkNumber}
            </Typography>

            <Typography>
              {t("Founded")}:{" "}
              {vendor?.foundedDate
                ? format(new Date(vendor?.foundedDate), "yyyy")
                : ""}
            </Typography>
          </Grid>

          {updateError && (
            <Grid>
              <Alert severity="error">{updateErrorData?.data?.message}</Alert>
            </Grid>
          )}

          {isUpdated && (
            <Grid>
              <Alert severity="success">{t("Success")}</Alert>
            </Grid>
          )}

          <Grid>
            <LoadingButton
              variant="contained"
              onClick={() => handleRefreshKVK()}
              loading={isUpdating}
              disabled={props?.vendor?.kvkNumber === "" || isUpdating}
              // startIcon={<KvKIcon />}
            >
              {t("Retrieve company profile")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default VendorKVK;
