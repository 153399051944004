import {
  Add,
  Check,
  ChevronLeft,
  Email,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ChevronRight,
  AddRounded,
  AddCircle,
  AddCircleOutline,
  MarkEmailRead,
  MarkEmailUnread,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider as MuiDivider,
  FormControlLabel,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  MobileStepper,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Zoom,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  List,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { ISignature, ISignee } from "../../../../types/signature";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { User, Users, ZoomIn } from "react-feather";
import styled from "@emotion/styled";
import useAuth from "../../../../hooks/useAuth";

import { ContractStatus, IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import {
  useDeleteSignatureMutation,
  useLazyGetSigningUrlQuery,
} from "../../../../redux/slices/digitalSigningSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { spacing } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetContractQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import { IGraphUser } from "../../../../types/user";
import { darken } from "polished";
import SigneesTable from "../signing/SigneesTable";
import SignButton from "../signing/SignButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const Divider = styled(MuiDivider)(spacing);

const Signee = styled(Grid)`
  width: 100%;
  padding: 4px;
  align-items: center;

  // background: ${(props) =>
    darken(0.03, props.theme.palette.background.default)};

  &:hover {
    // cursor: pointer;
    // background: ${(props) =>
      darken(0.03, props.theme.palette.background.default)};
  }
`;

const SigneeIcon = styled(User)`
  margin-right: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const SigneesIcon = styled(Users)`
  margin-right: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const steps = ["Invitees", "Formatting", "Send"];

type Props = {
  mode: DialogMode;
  readOnly?: boolean;
  signer?: ISignee;
  signable: boolean;
  signees: Array<ISignee>;
  setSignees: Dispatch<SetStateAction<ISignee[]>>;
  setSignatures: Dispatch<SetStateAction<ISignature[]>>;
  signatures: ISignature[];
  resetSignatures?: React.Dispatch<React.SetStateAction<boolean>>;
  setToggleSignaturePad?: Dispatch<SetStateAction<boolean | undefined>>;
  handleComplete?: any;
  contract: IContract;
  document: IDocument;
  hideTitle?: boolean;
};

const AIContractSigneeManagement: React.FC<Props> = ({
  mode,
  signable,
  signees,
  setSignees,
  setSignatures,
  signatures,
  resetSignatures,
  signer,
  setToggleSignaturePad,
  readOnly = false,
  handleComplete,
  contract,
  document,
  hideTitle,
}) => {
  const { t } = useTranslation();
  const { contractId, status } = useParams();
  const { user, method } = useAuth();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  // const [_signees, _setSignees] = useState<Array<ISignee>>([]);
  const [addedSignee, setAddedSignee] = useState<ISignee>({
    displayName: "",
    email: "",
  });
  const [signeeExisted, setSigneeExisted] = useState(false);
  const [temporarySignees, setTemporarySignees] = useState<ISignee[]>([]);

  const [showSigneesDialog, setShowSigneesDialog] = useState(false);
  const [includeManagerSignature, setIncludeManagerSignature] = useState(false);
  const [includeOwnerSignature, setIncludeOwnerSignature] = useState(false);
  const [signaturesChanged, setSignaturesChanged] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const users = useSelector((state: RootState) => state.users.users);

  const [
    getSigningUrl,
    { data: dataToken, isSuccess: isSuccessToken, isError: isErrorToken },
  ] = useLazyGetSigningUrlQuery();

  const [
    updateContract,
    {
      data: updatedContract,
      isLoading: updating,
      isSuccess: contractUpdated,
      reset: resetContractUpdated,
      isError: errorWhileUpdating,
      error: updateError,
    },
  ] = useUpdateContractMutation();

  const [
    deleteSignature,
    {
      data: deletedSignatureId,
      isLoading: deletingSignature,
      isSuccess: signatureDeleted,
    },
  ] = useDeleteSignatureMutation();

  useEffect(() => {
    if (dataToken && isSuccessToken) {
      navigate(`/signature?code=${dataToken.code}&hex=${dataToken.hex}`);
    }
  }, [dataToken, isSuccessToken]);

  useEffect(() => {
    if (isErrorToken) {
      navigate(`/403`);
    }
  }, [isErrorToken]);

  const resignContract = () => {
    getSigningUrl({ contractId, id: signer?.signatureId });
  };

  useEffect(() => {
    if (signees && signees.length > 0) {
      if (handleComplete) {
        handleComplete();
      }
    }
  }, [signees]);

  useEffect(() => {
    if (contract) {
      // set draggable = true for both.
      // If not, 1 of them may have draggable = false cause compare wrong
      const rootSignatures = contract.signatures?.map((sig) => ({
        ...sig,
        draggable: true,
      }));
      const modifiedSignatures = signatures.map((sig) => ({
        ...sig,
        draggable: true,
      }));

      if (
        rootSignatures &&
        JSON.stringify(rootSignatures) !== JSON.stringify(modifiedSignatures)
      ) {
        setSignaturesChanged(true);
      }
    }
  }, [mode, contract, signatures]);

  const handleAddTemporarySignee = () => {
    const emailExist = temporarySignees.some(
      (sig) => sig.email === addedSignee.email
    );
    setSigneeExisted(emailExist);
    if (!emailExist) {
      setTemporarySignees((prev) => [...prev, { ...addedSignee }]);
      setAddedSignee({ displayName: "", email: "" });
    }
  };

  const handleRemoveTemporarySignee = (email: string) => {
    setTemporarySignees((prev) =>
      prev.filter((signee) => signee.email !== email)
    );
  };

  useEffect(() => {
    if (signatureDeleted && deletedSignatureId) {
      setSignees((prev) =>
        prev.filter((ps) => ps.signatureId !== deletedSignatureId)
      );

      setSignatures((prev) =>
        prev.filter((ps) => ps.id !== deletedSignatureId)
      );
    }
  }, [signatureDeleted, deletedSignatureId]);

  const handleRemoveSignee = (signee: ISignee) => {
    if (setSignees && setSignatures) {
      if (signee.signatureId) {
        // it already has an id, so it exists in the database
        // only allow removal if the user has not sigened yet

        if (signee.hasSigned) {
          console.log("User has already signed the document. Cannot remove.");
          return;
        } else {
          // remove the signature from the database, the useEffect will handle the rest
          deleteSignature({ contractId, id: signee.signatureId });
        }
      } else {
        // just remove it from the state
        setSignees((prev) => prev.filter((ps) => ps.email !== signee.email));
        setSignatures((prev) => prev.filter((ps) => ps.email !== signee.email));
      }
    }
  };

  const handleCancelAddSignatories = () => {
    setShowSigneesDialog(false);
    setTemporarySignees([]);
  };

  const handleContinueAddSignatories = () => {
    if (setSignees && signees) {
      const newSignees: ISignee[] = [...signees];

      const newTemporarySignees = temporarySignees
        .filter(
          (signee) => !newSignees.some((ele) => ele.email === signee.email)
        )
        .map((signee) => {
          return { ...signee, hasSigned: false };
        });

      // add the new signees
      newSignees.push(...newTemporarySignees);

      if (includeManagerSignature && contract?.managerIds) {
        // add the managers
        for (const userId of contract.managerIds) {
          const user = users.find((user: any) => user.id === userId);

          if (user) {
            const userExists = newSignees.some(
              (signee) => signee.email === user?.mail
            );

            if (!userExists) {
              newSignees.push({
                displayName: user?.displayName,
                email: user.mail,
                hasSigned: false,
              });
            }
          } else {
            console.error("User not found for managerId", userId);
          }
        }
      }

      if (includeOwnerSignature && contract?.ownerIds) {
        // add the owners
        for (const userId of contract.ownerIds) {
          const user = users.find((user: any) => user.id === userId);

          if (user) {
            const userExists = newSignees.some(
              (signee) => signee.email === user?.mail
            );

            if (!userExists) {
              newSignees.push({
                displayName: user.displayName,
                email: user.mail,
                hasSigned: false,
              });
            }
          } else {
            console.error("User not found for ownerId", userId);
          }
        }
      }

      setSignees(newSignees);
      setTemporarySignees([]);
      setShowSigneesDialog(false);
    }
  };

  useEffect(() => {
    ensureSignatureFields();
  }, [signees, setSignatures]);

  const ensureSignatureFields = () => {
    if (setSignatures && signees) {
      setSignatures((prev) => {
        const newSignatories = signees
          .filter(
            (signee) => !signatures.some((sig) => sig.email === signee.email)
          )
          .map((signee) => ({
            ...signee,
            signatureUrl: "",
            draggable: true,
            signedDate: null,
            positionPercentValue: { x: 0, y: 0 },
            isDragged: false,
          }));

        return [...prev, ...newSignatories];
      });
    }
  };

  const toggleAddCurrentUser = () => {
    if (signees) {
      const userExists = temporarySignees.some(
        (signee) => signee.email === user?.email
      );

      if (!userExists) {
        const signee: ISignee = {
          displayName: user?.displayName || "",
          email: user?.email || "",
          hasSigned: false,
        };

        setTemporarySignees((prev) => [...prev, signee]);
      }
    }
  };

  const storeUsers = (): IGraphUser[] => {
    // add the add users to a new contract, so send notification and mail of expired contact.
    if (contract && method !== "Google" && users && users.length) {
      // If method is Google then we stored this user by login step
      const userIds: string[] = [
        ...(contract?.ownerIds || []),
        ...(contract?.managerIds || []),
      ];

      const userIdsUnique = userIds?.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      const userStore = users
        .filter((u: IGraphUser) =>
          userIdsUnique.some((id: string) => id == u.id)
        )
        .map((item: IGraphUser) => {
          item = { ...item, mail: item.mail || item.userPrincipalName };
          return item;
        });

      return userStore;
    }

    return [];
  };

  const saveSignatures = async () => {
    if (contract?.id) {
      // const updatedData = updatedContractDataRef.current;
      // update the contract
      const newVersion = {
        ...contract,
        signatures: signatures, // [...updatedData.signatures],
        users: storeUsers(),
        status: ContractStatus.DRAFT, // ensure the status is draft
      };

      await updateContract(newVersion).then((res: any) => {
        if (res.error) {
          console.error("Error while updating contract", res.error);
        }

        if (res.data) {
          console.log("Contract updated successfully");

          // show dialog
          setShowDialog(true);
        }
      });
    }
  };

  const handleCancel = () => {
    if (resetSignatures) {
      // this will reset the signatures to the original state
      resetSignatures(true);
    }

    setSignaturesChanged(false);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return false; //step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    if (activeStep === 2) {
      await saveSignatures();
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    handleCancel();
  };

  return (
    <>
      <Box mb={4} width="100%">
        {!hideTitle && (
          <Typography variant="h6" mb={2}>
            {t("Signatures")}
          </Typography>
        )}

        {document?.location !== "sharepoint" &&
          (signable ||
            signees?.find(
              (sig) => sig.email === user?.email && !sig.hasSigned
            )) &&
          !pathname.includes("signature") && (
            <>
              {signees.length > 0 && signer && (
                <>
                  <Typography variant="body1" mb={4}>
                    {t(
                      "Please check the information of the contract before you sign. This action is irreversible."
                    )}
                  </Typography>

                  <Button
                    sx={{ mb: 3 }}
                    variant="contained"
                    disabled={signer.hasSigned}
                    onClick={resignContract}
                  >
                    {t("Sign")}
                  </Button>

                  <Divider mb={4} />
                </>
              )}
            </>
          )}
        <Box
          sx={{
            maxWidth: 400,
            flexGrow: 1,
            // backgroundColor: "background.paper",
            // px: 2,
            // py: 4,
          }}
          mt={3}
        >
          {signable !== true && mode !== DialogMode.ViewOnly && (
            <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">{t("Optional")}</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{t(label)}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <Box>
                  <Typography variant="body1">
                    {signable &&
                      t(
                        "List of signees to provide signatures for the contract"
                      )}
                    {!signable &&
                      signees.length === 0 &&
                      t("There are no signees.")}{" "}
                    {!signable &&
                      signees.length === 0 &&
                      !readOnly &&
                      t(
                        "Click on “Add signatories” to request digital signatures."
                      )}
                    {!signable &&
                      signees.length === 0 &&
                      readOnly &&
                      t("Edit the contract to add signatories.")}
                    {/* {!signable &&
                      signees.length > 0 &&
                      t(
                        "Click on the user to add one signature block for each signatory"
                      )} */}
                  </Typography>

                  <SigneesTable
                    signees={signees}
                    signable={signable}
                    readOnly={readOnly || mode === DialogMode.ViewOnly}
                    onRemoveSignee={handleRemoveSignee}
                    setShowSigneesDialog={setShowSigneesDialog}
                    disabled={deletingSignature}
                  />
                </Box>
              )}
              {activeStep === 1 && (
                <Box>
                  <Typography variant="body1">
                    {t(
                      "Check the signature fields on the contract before you send the invitation. Drag them to the correct position if needed."
                    )}
                  </Typography>
                  {/* Show a gif that shows how to drag the signature fields */}
                  <Box mt={2} textAlign="center">
                    <img
                      src="/static/img/drag_signature_field.gif"
                      alt={t("Drag and drop the signature fields")!}
                      height={160}
                    />

                    <IconButton
                      onClick={() =>
                        window.open(
                          "/static/img/drag_signature_field.gif",
                          "_blank",
                          "toolbar=no,location=no,menubar=no"
                        )
                      }
                      color="primary"
                    >
                      <ZoomIn />
                    </IconButton>

                    <Typography variant="caption" display="flex">
                      {t("Drag and drop the signature fields")}
                    </Typography>
                  </Box>
                </Box>
              )}
              {activeStep === 2 && (
                <Box>
                  <Typography variant="body1">
                    {t(
                      "Click on the button below to send the signature request to the signees."
                    )}
                  </Typography>

                  {signees && signees.length > 0 && (
                    <Typography variant="body1" mt={2}>
                      {t(
                        "The invitees will receive an email with a link to the contract."
                      )}
                    </Typography>
                  )}

                  <Box mt={2}>
                    <List>
                      {signatures?.map((signature) => (
                        <ListItem key={signature.id}>
                          <ListItemIcon>
                            {signature?.id ? (
                              <MarkEmailRead />
                            ) : (
                              <MarkEmailUnread />
                            )}
                          </ListItemIcon>
                          <ListItemText>
                            {signature.displayName} {signature.email}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              )}

              {signable !== true && mode !== DialogMode.ViewOnly && (
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    <ChevronLeft />
                    {t("Previous step")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}
                  {activeStep < steps.length - 1 ? (
                    <Button
                      onClick={handleNext}
                      disabled={signees?.length === 0}
                    >
                      {t("Next step")}
                      <ChevronRight />
                    </Button>
                  ) : (
                    <>
                      {/* <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mr: 3 }}
                        disabled={!signaturesChanged}
                        onClick={handleCancel}
                      >
                        {t("Cancel")}
                      </Button> */}

                      <LoadingButton
                        variant="contained"
                        loading={updating}
                        onClick={handleNext}
                        disabled={!signaturesChanged}
                      >
                        <Check /> {t("Send for signing")}
                      </LoadingButton>
                    </>
                  )}
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>

      {/* Add Signee dialog  */}
      <Dialog
        open={showSigneesDialog}
        onClose={() => setShowSigneesDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <Card sx={{ px: 6, py: 4, minWidth: "600px" }}>
          <Typography variant="h3" color="primary" mb={3}>
            {t("Invite_Signees")}
          </Typography>
          <Typography variant="body1" mb={3}>
            {t("Invite the users you want to sign this contract")}
          </Typography>

          <Button
            onClick={toggleAddCurrentUser}
            variant="contained"
            sx={{ marginBottom: 2, mr: 4 }}
          >
            {t("Add myself")}
          </Button>

          {contract?.managerIds && contract.managerIds.length > 0 && (
            <FormControlLabel
              sx={{ marginBottom: 2 }}
              label={t("Manager needs to sign")}
              control={
                <Checkbox
                  checked={includeManagerSignature}
                  onChange={() => setIncludeManagerSignature((prev) => !prev)}
                />
              }
            />
          )}

          {contract?.ownerIds && contract.ownerIds.length > 0 && (
            <FormControlLabel
              sx={{ marginBottom: 2 }}
              label={t("Owner needs to sign")}
              control={
                <Checkbox
                  checked={includeOwnerSignature}
                  onChange={() => setIncludeOwnerSignature((prev) => !prev)}
                />
              }
            />
          )}

          {signeeExisted && (
            <Alert severity="warning" sx={{ marginBottom: 3 }}>
              {t("Signee_existed")}
            </Alert>
          )}

          <Box
            sx={{ display: "flex", alignItems: "center", gap: "6px" }}
            mt={6}
          >
            <TextField
              fullWidth
              variant="outlined"
              label={t("Name")}
              value={addedSignee.displayName}
              onChange={(e) =>
                setAddedSignee((prev) => {
                  return { ...prev, displayName: e.target.value };
                })
              }
            />
            <TextField
              fullWidth
              type="email"
              variant="outlined"
              label={t("Email_address")}
              value={addedSignee.email}
              onChange={(e) =>
                setAddedSignee((prev) => {
                  return { ...prev, email: e.target.value };
                })
              }
              error={
                addedSignee.email !== "" && !emailRegex.test(addedSignee.email)
              }
              helperText={
                addedSignee.email !== "" && !emailRegex.test(addedSignee.email)
                  ? t("Invalid_email_address")
                  : ""
              }
            />
            <Button
              sx={{ paddingLeft: 8, paddingRight: 8 }}
              disabled={
                !addedSignee.displayName ||
                !addedSignee.email ||
                !emailRegex.test(addedSignee.email)
              }
              variant="contained"
              onClick={handleAddTemporarySignee}
            >
              {t("Add")}
            </Button>
          </Box>

          {temporarySignees.length > 0 && (
            <Box sx={{ borderTop: "1px solid #ccc", mt: 4 }}>
              {temporarySignees.map((signee) => (
                <Grid
                  container
                  spacing={3}
                  key={signee.email}
                  sx={{ display: "flex", alignItems: "center", p: 2 }}
                >
                  <Grid size={1}>
                    <Avatar src="" />
                  </Grid>
                  <Grid size={9} overflow="hidden">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      noWrap
                      display="block"
                    >
                      {signee.displayName}
                    </Typography>
                    <Typography
                      variant="caption"
                      noWrap
                      display="inline-block"
                      width="100%"
                    >
                      {signee.email}
                    </Typography>
                  </Grid>
                  <Grid
                    size={1}
                    sx={{
                      width: "20%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={() => handleRemoveTemporarySignee(signee.email)}
                    >
                      {t("Remove")}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Box>
          )}

          <Box textAlign="right" mt={4}>
            <Button variant="outlined" onClick={handleCancelAddSignatories}>
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: 3 }}
              disabled={
                temporarySignees.length === 0 &&
                !includeManagerSignature &&
                !includeOwnerSignature
              }
              onClick={handleContinueAddSignatories}
            >
              {t("Continue")}
            </Button>
          </Box>
        </Card>
      </Dialog>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <Card sx={{ px: 6, py: 4, minWidth: "600px" }}>
          <Typography variant="h3" color="primary" mb={3}>
            {t("Success")}
          </Typography>
          <Typography variant="body1" mb={3}>
            {t("A signature request has been sent to the following persons:")}
          </Typography>

          <Box mt={2}>
            <List>
              {signees?.map((signee) => (
                <ListItem key={signee.signatureId}>
                  <ListItemIcon>
                    {signee?.signatureId ? (
                      <MarkEmailRead />
                    ) : (
                      <MarkEmailUnread />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {signee.displayName} {signee.email}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box textAlign="right" mt={4}>
            <Button
              variant="contained"
              onClick={() => {
                setShowDialog(false);
                navigate(`/contracts/${ContractStatus.SIGNING}`);
              }}
            >
              {t("Close")}
            </Button>
          </Box>
        </Card>
      </Dialog>
    </>
  );
};

export default AIContractSigneeManagement;
