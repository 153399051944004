import { useTranslation } from "react-i18next";
import { uppercaseFirstLetter } from "../utils/string";
interface IDateTimeTranslateProps {
  date?: Date;
  format?: "relative" | "dateOnly" | "dateTime" | "dateTimeAndTimeZone" | "ago";
  includeSuffix?: boolean;
}

const DateTimeTranslate: React.FC<IDateTimeTranslateProps> = ({
  date,
  format = "relative",
}) => {
  const { t } = useTranslation();
  return <>{uppercaseFirstLetter(t("date." + format, { date }))}</>;
};

export default DateTimeTranslate;
