import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography as MuiTypography,
  CardActions,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  CardActionArea,
  useTheme,
} from "@mui/material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Description,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Cancel,
  GridView,
  TableView,
  PropaneSharp,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { SpacingProps, alpha, spacing } from "@mui/system";

import {
  useDeleteLibraryKPIMutation,
  useGetLibraryKPIsQuery,
  useUpdateLibraryKPIMutation,
} from "../../redux/slices/indexApiSlice";
import {
  useDeleteVendorKPIMutation,
  useGetVendorKPIsQuery,
} from "../../redux/slices/vendorsApiSlice";

import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { IKPI, IVendorKPI } from "../../types/kpi";
import { Check } from "react-feather";
import KPIDialog from "../dialogs/KPIDialog";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import { t } from "i18next";
import { DialogMode } from "../../types/dialogmode";
import EnhancedDataGrid from "./EnhancedDataGrid";
import { fetchKpi } from "../../redux/slices/kpiSlice";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

// Type guard to check if kpi is of type IVendorKPI
function isVendorKPI(kpi: IKPI | IVendorKPI): kpi is IVendorKPI {
  return (kpi as IVendorKPI).score !== undefined;
}

type KPIProps = {
  image?: string;
  kpi: IKPI | IVendorKPI;
  chip: JSX.Element;
  updateKPI: (kpi: IKPI) => void;
  deleteKPI: (kpi: IKPI) => void;
  handleCardClick?: (selected: IKPI) => void;
  selected?: boolean;
  refetch: () => void;
};
const KPICard: React.FC<KPIProps> = ({
  image,
  kpi,
  chip,
  selected,
  updateKPI,
  deleteKPI,
  handleCardClick,
  refetch,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const theme = useTheme();

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    setShowConfirmation(true);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    await deleteKPI(kpi);

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleOnClick = () => {
    if (handleCardClick) {
      //   setSelected(!isSelected);

      handleCardClick(kpi);
    }
  };

  const cardMedia = image ? (
    <CardMedia image={image} title="Contemplative Reptile" />
  ) : null;
  const cardContent = (
    <CardContent>
      <Grid
        container
        justifyContent="space-between"
        alignItems="top"
        wrap="nowrap"
      >
        <Typography gutterBottom variant="h5" component="h2">
          {kpi?.name}
        </Typography>

        {isVendorKPI(kpi) && (
          <Avatar
            sx={{
              backgroundColor:
                kpi.score < 5 ? "red" : kpi.score < 8 ? "orange" : "green",
              color: (theme) => theme.palette.common.white,
            }}
          >
            {kpi?.score}
          </Avatar>
        )}
      </Grid>

      {/* {chip} */}

      <Tooltip title={kpi?.description}>
        <Typography mb={4} color="textSecondary" component="p">
          {kpi?.description}
        </Typography>
      </Tooltip>

      {isVendorKPI(kpi) && (
        <Grid container gap={3} direction="column">
          <Typography variant="body1" component="p" fontStyle="italic">
            {kpi?.comment}
          </Typography>
          {kpi?.date && (
            <Typography
              variant="body1"
              fontSize={11}
              component="p"
              align="right"
            >
              {format(new Date(kpi?.date), "PP p")}
            </Typography>
          )}
        </Grid>
      )}

      {/* <AvatarGroup max={3}>
<Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
<Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
<Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
</AvatarGroup> */}
    </CardContent>
  );

  const backgroundColor = selected
    ? alpha(theme.palette.primary.main, 0.16)
    : null;

  return (
    <Card onClick={handleOnClick} aria-selected={selected}>
      {handleCardClick ? (
        // if there is an action, wrap the card in a CardActionArea
        <CardActionArea
          aria-selected={selected}
          sx={{ backgroundColor: backgroundColor }}
        >
          {cardMedia}
          {cardContent}
        </CardActionArea>
      ) : (
        <>
          {cardMedia}
          {cardContent}
        </>
      )}
      <CardActions>
        {!showConfirmation && (
          <KPIDialog
            kpi={kpi}
            mode={DialogMode.Edit}
            refetch={refetch}
            iconOnly
          />
        )}

        {showConfirmation ? (
          <Fade in={showConfirmation}>
            <Box m={0} p={0}>
              <Typography component="span" fontSize={12} color="primary">
                {t("Are you sure?")}
              </Typography>
              <IconButton
                color="primary"
                aria-label="Cancel"
                size="small"
                onClick={handleCancelDelete}
              >
                <Cancel />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Confirm"
                size="small"
                onClick={handleConfirmDelete}
              >
                <Check />
              </IconButton>
            </Box>
          </Fade>
        ) : (
          <Tooltip title="Delete">
            <Button
              size="small"
              color="primary"
              aria-label="Delete"
              onClick={handleDeleteClick}
            >
              {t("Delete")}
            </Button>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};

function KPICards({ ...props }) {
  return (
    <Grid container spacing={6}>
      {props.kpis?.map((kpi: IKPI, i: number) => (
        <Grid
          key={i.toString()}
          size={{ xs: 12, lg: 6 }}
          offset={{ xs: 2, lg: 4 }}
        >
          <KPICard
            kpi={kpi}
            updateKPI={props.updateKPI}
            deleteKPI={props.deleteKPI}
            handleCardClick={props.handleCardClick}
            selected={props.rowSelectionModel?.includes(kpi.id)}
            chip={<Chip label="Finished" color="success" />}
            refetch={props.refetch}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function DataGridKPIs({ ...props }) {
  const [view, setView] = useState(props.view || "cards");
  const [updateKPI, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateLibraryKPIMutation();

  const [deleteKPI, { isLoading: deleting, isSuccess: isDeleted }] =
    useDeleteLibraryKPIMutation();
  const [
    deleteVendorKPI,
    {
      isLoading: vendorKPIDeleting,
      isSuccess: isVendorKPIDeleted,
      reset: resetVendorKPIDeleted,
    },
  ] = useDeleteVendorKPIMutation();

  console.log("DataGridKPIs props", props.items);
  const dispatchToStore = useDispatch(); // Get the dispatch function from react-redux
  const {
    data: libraryKPIs,
    isLoading,
    refetch,
  } = useGetLibraryKPIsQuery(props.items ?? skipToken);

  const { data: vendorKPIs = [] } = useGetVendorKPIsQuery(props.vendor.id, {
    skip: !props.vendor,
  });

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [kpis, setKPIs] = useState<IKPI[]>([]);

  useEffect(() => {
    if (props?.refetch) {
      refetch();
    }
  }, [props.refetch]);

  useEffect(() => {
    if (props.vendor && vendorKPIs) {
      setKPIs(vendorKPIs);
    }
  }, [vendorKPIs, props.vendor]);

  useEffect(() => {
    if (!props.vendor && libraryKPIs) {
      setKPIs(libraryKPIs);
    }
  }, [libraryKPIs, props.vendor]);

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  useEffect(() => {
    if (isDeleted || isVendorKPIDeleted) {
      if (props.refetch) {
        props.refetch();
      } else {
        refetch();
        dispatchToStore(fetchKpi(kpis));
      }
    }
  }, [isDeleted || isVendorKPIDeleted]);

  // const paginationModel = gridPaginationModelSelector(apiRef.current.state, apiRef.current.instanceId);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    // get the kpi object from the id
    const selectedKPIs = handleMUIDataGrid().filter((k: IKPI) =>
      rowSelectionModel.includes(k.id)
    );

    props?.onSelect && props?.onSelect(selectedKPIs);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (!isLoading) {
      dispatchToStore(fetchKpi(kpis));
    }
  }, [isLoading || kpis]);

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 1,
      field: "description",
      headerName: "Description",
    },
    {
      flex: 1,
      field: "score",
      headerName: "Score",
    },
    {
      headerName: "Action",
      width: 250,
      field: "action",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              aria-label="view"
              onClick={(event) => {
                // handleViewDetails(event, cellValues);
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>

            <KPIDialog
              contract={props.contract}
              vendor={props.vendor}
              kpi={cellValues.row}
              mode={DialogMode.Edit}
              refetch={props.vendor ? props.refetch : refetch}
              iconOnly
            />

            <KPIDialog
              contract={props.contract}
              vendor={props.vendor}
              kpi={cellValues.row}
              mode={DialogMode.Delete}
              refetch={props.vendor ? props.refetch : refetch}
              iconOnly
              inlineConfirmation
            />
          </>
        );
      },
    },
  ];

  // This function is triggered when the delete button in the toolbar is clicked
  const handleToolbarDeleteClick = (event: any) => {
    console.log(rowSelectionModel);

    rowSelectionModel.forEach((row) => {
      handleDeleteKPI({ id: row } as IKPI);
    });
  };

  const handleMUIDataGrid = () => {
    if (props.items) {
      return props.items;
    } else if (!isLoading && kpis) {
      return kpis;
    }

    return [];
  };

  const rows = handleMUIDataGrid();

  const handleCardClick = (sel: IKPI) => {
    if (rowSelectionModel.includes(sel?.id)) {
      const newRowSelectionModel = rowSelectionModel.filter(
        (row) => row !== sel?.id
      );
      setRowSelectionModel(newRowSelectionModel);
    } else {
      const newRowSelectionModel = [...rowSelectionModel, sel?.id];
      setRowSelectionModel(newRowSelectionModel);
    }
  };

  const handleDeleteKPI = (kpi: IKPI) => {
    if (props.vendor) {
      const vendorKPI: any = { ...kpi, vendorId: props.vendor.id };

      deleteVendorKPI(vendorKPI);
    } else {
      deleteKPI(kpi.id);
    }

    // remove the deleted item from the rowSelectionModel
    const newRowSelectionModel = rowSelectionModel.filter(
      (row) => row !== kpi?.id
    );
    setRowSelectionModel(newRowSelectionModel);
  };

  return view === "cards" ? (
    <Paper>
      <Box
        height={rows.length > 0 ? "55vh" : 400}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiToolbar-root": {
            minHeight: props.vendor || props.contract ? "unset" : "64px",
          },
        }}
      >
        <EnhancedDataGridToolbar
          rowSelectionModel={rowSelectionModel}
          handleDeleteClick={handleToolbarDeleteClick}
          view={view}
          setView={setView}
          title={props.vendor || props.contract ? "" : t("KPI's")}
          isLoading={isLoading}
          deleting={props.vendor ? vendorKPIDeleting : deleting}
        />
        <Box sx={{ overflowY: "auto", maxHeight: "55vh" }} pb={4}>
          <KPICards
            kpis={handleMUIDataGrid()}
            handleCardClick={handleCardClick}
            updateKPI={updateKPI}
            deleteKPI={handleDeleteKPI}
            rowSelectionModel={rowSelectionModel}
            refetch={props.vendor ? props.refetch : refetch}
          />
        </Box>
      </Box>
    </Paper>
  ) : (
    <EnhancedDataGrid
      title={props.vendor || props.contract ? "" : t("KPI's")}
      rows={rows}
      columns={rows.length > 0 ? columns : []}
      loading={isLoading}
      deleting={props.vendor ? vendorKPIDeleting : deleting}
      noRowsOverlayLabel={t("No KPI's")}
      deleteRow={handleDeleteKPI}
      compactHeader={props.compactHeader}
      view={view}
      setView={setView}
      onSelect={props.onSelect}
      selected={props.selected}
      handleRowDoubleClick={null}
    />
  );
}

export default DataGridKPIs;
