import { ContractStatus } from "./contract";
import { IDocument } from "./document";

export type ChatBoxType = {
  id: string;
  firstMessage: string;
  createdAt: string;
};

export enum ChatType {
  GENERAL = "general",
  CONTRACT = "contract",
  SUGGESTED_VENDORS = "searchVendor",
  SUGGESTED_CONTRACTS = "searchContract",
}

export type ChatMessageType = {
  type: ChatType;
  author: Author;
  message: string;
  createdAt: string;
  listofChoices?: ContractChoice[] | VendorChoice[];
};

export type ContractChoice = string[];

export type VendorChoice = {
  vendorID: string;
  email: string;
  description: string;
  website: string;
  address: string;
};

export enum TabType {
  home = "home",
  history = "history",
  chatBox = "chatBox",
}

export type IChatBotHistory = {
  id: string | null;
  content: ChatMessageType[];
  contractId: string | undefined;
  vendorId: string | undefined;
};

export type IAResponse = {
  id: string;
  aiRes: ChatMessageType;
};

export enum Author {
  BOT = "bot",
  USER = "user",
}

export type NewChatBoxMessage = {
  type: ChatType;
  message: string;
};
