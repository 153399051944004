import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Box,
  Button,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import AzureADAvatar from "../avatars/AzureADAvatar";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function QuickSearchToolbar() {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <GridToolbarQuickFilter placeholder={t("Search")!} />
    </Box>
  );
}

interface IDataGridUsersProps {
  view?: "cards" | "table";
  onSelect?: (selected: any) => void;
  selected?: string[];
  refetch?: boolean;
}

function DataGridUsers({ ...props }: IDataGridUsersProps) {
  const { t } = useTranslation();

  // const [users, setUsers] = useState<IGraphUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const users = useSelector((state: RootState) => state.users.users);

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>(props.selected || []);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (props.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  useEffect(() => {
    if (props.selected && users && !isLoading) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, [props.selected, users, isLoading]);

  // const paginationModel = gridPaginationModelSelector(apiRef.current.state, apiRef.current.instanceId);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    if (props.onSelect && !isLoading) {
      props?.onSelect(rowSelectionModel as any);
    }
  }, [rowSelectionModel]);

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   hideable: true,
    // },
    {
      field: "avatar",
      headerName: "",
      width: 40,
      renderCell: (params: any) => {
        const userId = params.row.id;
        // const userPhotoUrl = userPhotos[userId];

        // return <Avatar alt={params.row.displayName} src={userPhotoUrl} />;
        return <AzureADAvatar userId={userId} pictureOnly />;
      },
    },
    {
      flex: 1,
      field: "displayName",
      headerName: t("Name")!,
    },
    // {
    //   flex: 1,
    //   field: "givenName",
    //   headerName: t("First name")!,
    // },
    // {
    //   flex: 1,
    //   field: "surname",
    //   headerName: t("Last name")!,
    // },
    {
      field: "jobTitle",
      headerName: t("Job title")!,
      flex: 1,
    },
    {
      field: "mail",
      headerName: t("Email")!,
      flex: 1,
    },
    // {
    //   field: "mobilePhone",
    //   headerName: "Mobile",
    // },
  ];

  const handleMUIDataGrid = () => {
    if (!isLoading && users) {
      // only get the users that are on the current page
      // const usersOnCurrentPage = users.slice(page * pageSize, page * pageSize + pageSize);

      return users;
    }

    return [];
  };

  return (
    <Paper mb={16} elevation={0}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        <Paper p={4} elevation={0}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid size={12}>
              <TextField // Search field
                variant="outlined"
                label={t("Search")}
                fullWidth
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
        </Paper>

        <DataGrid
          initialState={{
            pagination: { paginationModel: { page: 0, pageSize: 5 } },
          }}
          apiRef={apiRef}
          pageSizeOptions={[5, 10, 25]}
          rows={handleMUIDataGrid()}
          columns={columns}
          autoHeight
          checkboxSelection
          loading={isLoading}
          hideFooterSelectedRowCount
          //  onRowDoubleClick={handleRowDoubleClick}
          // onPaginationModelChange={handlePageChange}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          filterModel={{
            items: [
              {
                id: 1,
                field: "displayName",
                operator: "contains",
                value: searchText,
              },
            ],
          }}
          slots={{
            toolbar: () => (
              <EnhancedDataGridToolbar
                rowSelectionModel={rowSelectionModel}
                view={"table"}
                title={""}
                isLoading={isLoading}
              />
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
            // pagination: {
            //   labelRowsPerPage: t("Rows per page"),
            //   labelDisplayedRows(paginationInfo) {
            //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
            //   },
            // },
          }}
          sx={{
            "& .MuiDataGrid-cell": {
              alignContent: "center",
            },
          }}
          density="standard"
        />
      </Box>
    </Paper>
  );
}

export default DataGridUsers;
