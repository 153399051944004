import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IKPI } from "../../types/kpi";

//Defining our initialState's type
type initialStateType = {
    kpiList: IKPI[];
};

const kpiList: IKPI[] = [];

const initialState: initialStateType = {
    kpiList,
};

export const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        addNewkpi: (state, action: PayloadAction<IKPI>) => {
            //todo
        },
        updatekpi: (state, action: PayloadAction<IKPI>) => {
            //todo
        },
        deletekpi: (state, action: PayloadAction<{ id: string }>) => {
            //todo
        },
        fetchKpi: (state, action: PayloadAction<IKPI[]>) => {
            state.kpiList = action.payload;
        }
    },
});

// To able to use reducers we need to export them.
export const { addNewkpi, updatekpi, deletekpi, fetchKpi } = kpiSlice.actions;

//Selector to access kpiList state.
export const selectkpiList = (state: RootState) => state.kpi.kpiList;

export default kpiSlice.reducer;
