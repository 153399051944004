import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { NumericFormat } from "react-number-format";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractFinancialsProps {
  contract?: IContract;
  hideTitle?: boolean;
}
function ContractFinancials({ ...props }: IContractFinancialsProps) {
  const { contract } = props;
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Value")}
        </Typography>
      )}

      {(contract?.budget || contract?.explanations?.financials) && (
        <Box>
          <Grid container alignContent="center">
            {contract.budget?.currency ? (
              new Intl.NumberFormat(navigator.language, {
                style: "currency",
                currency: contract.budget?.currency,
                compactDisplay: "long",
                maximumFractionDigits: 0,
              }).format(contract.budget?.amount)
            ) : (
              <NumericFormat
                value={contract.budget?.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"? "}
              />
            )}
          </Grid>

          {contract?.budget?.paymentTerms && (
            <Typography pt={1} variant="body1">
              {contract.budget.paymentTerms}
            </Typography>
          )}

          {contract?.explanations?.financials && (
            <Alert
              sx={{ mt: 2 }}
              severity="info"
              variant="outlined"
              icon={<OwlAvatarIcon />}
            >
              <Typography pt={1} variant="body1">
                {contract.explanations.financials}
              </Typography>
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ContractFinancials;
