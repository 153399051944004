import React, { useEffect, useState } from "react";
import { Avatar} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import AzureADAvatar from "../avatars/AzureADAvatar";
const UserAvatar: React.FC = () => {
  const { user, isAuthenticated, method } = useAuth();
  return (
    <>{!!user && method == 'Google' ? <Avatar alt={user.displayName} src={user.avatar} /> : <AzureADAvatar userId={user?.id} />}</>       
  );
};

export default UserAvatar;
