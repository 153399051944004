import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { SendRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grow,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import useBotChat from "../../hooks/useBotChat";
import ChatMessage from "./ChatMessage";
import { convertCreatedDate } from "../../utils/string";

import {
  useAddChatBotMutation,
  useUpdateChatBotMutation,
} from "../../redux/slices/indexApiSlice";
import {
  Author,
  ChatMessageType,
  ChatType,
  IChatBotHistory,
  NewChatBoxMessage,
} from "../../types/chatBot";

import { useParams } from "react-router-dom";
import OwlAvatarIcon from "../../icons/OwlAvatarIcon";
import { IQuestion } from "../../types/question";
import PromptsList from "../../pages/main/prompts/PromptsList";
import DefaultPrompts from "./DefaultPrompts";
import { IPrompt } from "../../types/prompt";

const ChatMessages = styled(Box)`
  padding-top: 20px;
  overflow-y: scroll;
  height: calc(100% - 100px);
  user-select: text;
  cursor: text;
`;

const Message = styled.div<{ position: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.position === "left" ? "flex-start" : "flex-end"};
  padding: 0 20px 20px;
  text-align: ${(props) => props.position};

  user-select: text;
  cursor: text;
`;

export const ChatInputField = styled(TextField)`
  flex: 1;
  border: none;
  outline: none;
  padding-left: ${(props) => props.theme.spacing(3)};
  padding-right: ${(props) => props.theme.spacing(3)};
  border-radius: 30px;
  background: ${(props) => props.theme.palette.neutral.primary};

  & .MuiOutlinedInput-root {
    & fieldset {
      border: none;
    }
    &:hover fieldset {
      border: none;
    }
    &.Mui-focused fieldset {
      border: none;
    }
  }
`;

const TypingDot = styled.div<{ delay?: number }>`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.neutral.contrastText};
  margin-right: 4px;
  animation: typing 1s infinite;
  animation-delay: ${(props) => props.delay}s;

  @keyframes typing {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-4px);
    }
    80%,
    100% {
      transform: translateY(0);
    }
  }
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const RespondingBubble = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.neutral.secondary
      : props.theme.palette.neutral.primary};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.neutral.contrastText
      : props.theme.palette.neutral.contrastText};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

function ChatResponding() {
  const { botName } = useBotChat();

  return (
    <Message position="left">
      <Box
        display="flex"
        justifyContent="center"
        justifySelf="center"
        alignItems="center"
      >
        <OwlAvatarIcon></OwlAvatarIcon>

        <ChatMessageBubbleName variant="body1" ml={2}>
          {botName}
        </ChatMessageBubbleName>
      </Box>

      <RespondingBubble highlighted={false}>
        <span>
          <TypingDot delay={0} />
          <TypingDot delay={0.1} />
          <TypingDot delay={0.2} />
        </span>
      </RespondingBubble>
    </Message>
  );
}

const ChatBox: React.FC<
  IChatBotHistory & { newMessage: NewChatBoxMessage | null }
> = ({ id, content, newMessage }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { contractId, vendorId } = useParams<string>();

  const {
    botName,
    setOpen: setShowBotChat,
    addedMessage,
    setAddedMessage,
  } = useBotChat();

  const [postQuestion, { isLoading, isSuccess, isError, error, data: reply }] =
    useAddChatBotMutation();

  const [
    putQuestion,
    {
      isLoading: isLoadingPut,
      isSuccess: isSuccessPut,
      isError: isErrorPut,
      error: errorPut,
      data: replyPut,
    },
  ] = useUpdateChatBotMutation();

  const inputRef = useRef(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [idChatBox, setIdChatBox] = useState(id);
  const [responding, setResponding] = useState(false);
  const [sentMessage, setSentMessage] = useState("");
  const [chatBoxType, setChatBoxType] = useState<ChatType>(ChatType.GENERAL);

  useEffect(() => {
    setIdChatBox(id);
    setMessages(content);
  }, [id, content]);

  useEffect(() => {
    if (newMessage) {
      if (newMessage.type === ChatType.GENERAL) {
        setShowBotChat(true);
        setChatBoxType(ChatType.GENERAL);
        handleSendMessage();
        setAddedMessage("");
      }
      // if (newMessage.type === ChatType.CONTRACT && !id) {
      //   setChatBoxType(ChatType.CONTRACT);
      // }
    }
  }, [newMessage]);

  useEffect(() => {
    if (chatBoxRef?.current && !responding) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, responding]);

  useEffect(() => {
    if (isSuccess && reply?.id) {
      setIdChatBox(reply.id);
      setMessages((prev) => [...prev, reply.aiRes]);
      setResponding(false);
    }
  }, [reply]);

  useEffect(() => {
    if (isSuccessPut && replyPut?.id) {
      setIdChatBox(replyPut.id);
      setMessages((prev) => [...prev, replyPut.aiRes]);
      setResponding(false);
    }
  }, [replyPut]);

  const handleSendMessage = async (message?: string) => {
    if ((!!sentMessage && inputRef?.current) || addedMessage || message) {
      setMessages((prev) => [
        ...prev,
        {
          author: Author.USER,
          type: ChatType.GENERAL,
          message: message || sentMessage || addedMessage,
          createdAt: new Date().toISOString(),
        },
      ]);
      setResponding(true);
      setSentMessage("");
      if (idChatBox) {
        await putQuestion({
          id: idChatBox,
          question: message || sentMessage || addedMessage,
          type: ChatType.GENERAL,
          contractId: contractId || "",
          vendorId: vendorId || "",
        });
      } else {
        await postQuestion({
          id: undefined,
          question: message || sentMessage || addedMessage,
          type: ChatType.GENERAL,
          contractId: contractId || "",
          vendorId: vendorId || "",
        });
      }
      if (inputRef?.current) {
        (inputRef.current as HTMLInputElement).focus();
      }
    }
  };

  const handleEnterSentMessage = async (event: any) => {
    if (event.key === "Enter" && !!sentMessage && !responding) {
      await handleSendMessage();
    }
  };

  const sendDefaultPrompt = (prompt: IPrompt) => {
    if (!responding) {
      handleSendMessage(prompt.body);
    }
  };

  const initialMessage: ChatMessageType = {
    type: ChatType.GENERAL,
    author: Author.BOT,
    message: t("Bot welcome message in popup"),
    createdAt: new Date().toISOString(),
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        postion: "relative",
        backgroundColor: "background.paper",
      }}
    >
      <ChatMessages ref={chatBoxRef} flexDirection="column">
        <Stack display="flex" justifyContent="space-between" height="100%">
          <Box>
            {!newMessage && <ChatMessage chatMessage={initialMessage} />}

            {messages.map((mess) => {
              if (!mess) return null;

              return (
                <ChatMessage
                  key={`${mess.author}-${mess.createdAt}`}
                  chatMessage={mess}
                  maxWidth={chatBoxRef?.current?.offsetWidth}
                />
              );
            })}
            {responding && <ChatResponding />}
          </Box>

          {!responding && (
            <DefaultPrompts
              maxHeight={220}
              onPromptClick={sendDefaultPrompt}
              view="randomChips"
              max={4}
              disabled={responding}
              showMore={true}
            />
          )}
        </Stack>
      </ChatMessages>

      <Divider></Divider>

      <strong className="no-cursor">
        <Box display="flex" alignItems="center" height={80} pl={3} pr={3}>
          <ChatInputField
            ref={inputRef}
            type="text"
            value={sentMessage}
            onInput={(e) =>
              setSentMessage((e.target as HTMLInputElement).value)
            }
            onKeyDown={handleEnterSentMessage}
            placeholder={t("Ask your question") + "..."}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => handleSendMessage()}
                  disabled={responding || !sentMessage}
                >
                  <SendRounded color="primary" />
                </IconButton>
              ),
            }}
          />
        </Box>
      </strong>
    </Box>
  );
};

export default ChatBox;
