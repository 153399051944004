import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IKPI } from "../../types/kpi";
import { IGraphUser } from "../../types/user";
import { GraphApi } from "./graphApiSlice";

//Defining our initialState's type
type initialStateType = {
  users: IGraphUser[];
};

const initialState: initialStateType = {
  users: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // Reducer to set users
    setUsers: (state, action: PayloadAction<IGraphUser[]>) => {
      state.users = action.payload;
    },
  },
});

// To able to use reducers we need to export them.
export const { setUsers } = usersSlice.actions;

//Selector to access kpiList state.
// export const selectUsers = (state: RootState) => state.users;

export default usersSlice;
