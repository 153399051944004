import React, { useEffect, useState } from "react";
import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { DialogMode } from "../../types/dialogmode";
import { useTranslation } from "react-i18next";
import {
  useGetDrivesQuery,
  useGetFollowedSitesQuery,
  useGetSitesQuery,
  useLazyGetDrivesQuery,
} from "../../redux/slices/graphApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ISharePointConnection } from "../../types/sharepointconnection";
import { Folder } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface AutocompleteOption {
  id: string;
  label: string;
  logo: string;
  description?: string;
}

interface ISharePointConnectionFormProps {
  mode: DialogMode;
  connection?: ISharePointConnection;
  initialValues?: ISharePointConnection;
  addConnection: (values: ISharePointConnection) => void;
  updateConnection: (values: ISharePointConnection) => void;
}

function SharePointConnectionForm({
  ...props
}: ISharePointConnectionFormProps) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    siteId: Yup.string().required(t("Required")!),
    driveId: Yup.string().required(t("Required")!),
  });

  const [siteId, setSiteId] = React.useState(
    props.connection?.siteId ?? props.initialValues?.siteId ?? null
  );
  const { data: sites, isSuccess: sitesLoaded } = useGetFollowedSitesQuery();
  // const { data: sites, isSuccess: sitesLoaded } = useGetSitesQuery();

  const [getDrives, { data: drives, isSuccess: drivesLoaded }] =
    useLazyGetDrivesQuery();
  const [siteOptions, setSiteOptions] = useState<AutocompleteOption[]>([]);
  const [driveOptions, setDriveOptions] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    if (siteId) {
      getDrives(siteId);
    }
  }, [siteId]);

  useEffect(() => {
    if (sites && sitesLoaded) {
      setSiteOptions(
        sites?.map((site: any) => ({
          label: site.displayName,
          id: site.id,
          logo: `${site.webUrl}/_api/siteiconmanager/getsitelogo?type='1'`,
          description: site.webUrl,
        }))
      );
    }
  }, [sites, sitesLoaded]);

  useEffect(() => {
    if (drives && drivesLoaded) {
      setDriveOptions(
        drives.map((drive: any) => ({
          label: drive.name,
          id: drive.id,
          logo: drive.logo,
          description: drive.webUrl,
        }))
      );
    }
  }, [drives, drivesLoaded]);

  const handleSubmit = async (
    values: any,
    { resetForm, errors, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        return;
      }

      console.log("submitting form");
      if (props.mode === DialogMode.Add) {
        await props.addConnection(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === DialogMode.Edit) {
        await props.updateConnection(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues: ISharePointConnection = props.connection
    ? { ...props.connection }
    : props.initialValues
    ? { ...props.initialValues }
    : {
        id: "",
        siteId: "",
        driveId: "",
        webUrl: "",
        siteDisplayName: "",
        driveName: "",
      };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Box my={3}>
          {status && status.sent && (
            <Alert severity="success" my={3}>
              {t("Your data has been submitted successfully!")}
            </Alert>
          )}

          <Form id="sharepoint-connection-form" onSubmit={handleSubmit}>
            <Grid container gap={6} direction="column">
              <Grid>
                <FormControl fullWidth>
                  <Field name="siteId">
                    {() => (
                      <Autocomplete
                        // multiple
                        fullWidth
                        id="siteId"
                        options={siteOptions}
                        freeSolo={false}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Box
                              key={key}
                              component="li"
                              {...optionProps}
                              my={2}
                            >
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                    variant="square"
                                    src={option.logo}
                                    imgProps={{
                                      loading: "lazy",
                                    }}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={option.label}
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      title={option.description}
                                      sx={{
                                        whiteSpace: "wrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {option.description}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </Box>
                          );
                        }}
                        onChange={(event: any, option: any) => {
                          setFieldValue("siteId", option?.id);
                          setSiteId(option?.id);
                        }}
                        onBlur={handleBlur}
                        value={
                          values.siteId && siteOptions && siteOptions.length > 0
                            ? siteOptions.filter(
                                (u) => u.id === values.siteId
                              )[0]
                            : null
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label={t("Select Site")}
                            variant="outlined"
                            error={touched.siteId && errors.siteId}
                            helperText={
                              touched.siteId && errors.siteId
                                ? t(errors.siteId)
                                : null
                            }
                          />
                        )}
                      />
                    )}
                  </Field>
                </FormControl>
              </Grid>

              <Grid>
                <FormControl fullWidth>
                  <Field name="driveId">
                    {() => (
                      <Autocomplete
                        // multiple
                        fullWidth
                        id="driveId"
                        options={driveOptions}
                        freeSolo={false}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Box
                              key={key}
                              component="li"
                              {...optionProps}
                              my={2}
                            >
                              <ListItem>
                                <ListItemIcon>
                                  <Avatar variant="square">
                                    <Folder />
                                  </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                  primary={option.label}
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      title={option.description}
                                      sx={{
                                        whiteSpace: "wrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {option.description}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </Box>
                          );
                        }}
                        onChange={(event: any, option: any) => {
                          setFieldValue("driveId", option?.id);
                        }}
                        onBlur={handleBlur}
                        value={
                          values.driveId &&
                          driveOptions &&
                          driveOptions.length > 0
                            ? driveOptions.filter(
                                (u) => u.id === values.driveId
                              )[0]
                            : null
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label={t("Select Drive")}
                            variant="outlined"
                            error={touched.driveId && errors.driveId}
                            helperText={
                              touched.driveId && errors.driveId
                                ? t(errors.driveId)
                                : null
                            }
                          />
                        )}
                      />
                    )}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
}

export default SharePointConnectionForm;
