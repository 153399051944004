import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  PhoneCall,
  ShoppingBag,
  Twitter,
} from "react-feather";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Cancel,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  Edit as EditIcon,
  ChevronLeft,
  ChevronRight,
  Event,
  CheckBox,
  FormatListNumbered,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  Fade,
  CardMedia as MuiCardMedia,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Icon,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Tab,
  Tabs,
  AvatarGroup,
  useMediaQuery,
  Theme,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing, SpacingProps } from "@mui/system";

import {
  useGetActivitiesForVendorQuery,
  useGetContractsForVendorQuery,
  useGetVendorQuery,
  useGetVendorsQuery,
} from "../../../redux/slices/vendorsApiSlice";
import { IContract } from "../../../types/contract";
import KPIDialog from "../../../components/dialogs/KPIDialog";
import DataGridKPIs from "../../../components/datagrids/DataGridKPIs";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../../types/dialogmode";
import useAuth from "../../../hooks/useAuth";
import { IVendor } from "../../../types/vendor";
import { ICompanyProfileKVK } from "../../../../../server/types/companyprofilekvk";
import VendorInformation from "./VendorInformation";
import DataGridContracts from "../../../components/datagrids/DataGridContracts";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const timeOut = (time: number) => new Promise((res) => setTimeout(res, time));

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

// function Assignees({ ...props }) {
//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Grid container justifyContent="space-between">
//           <Grid>
//             <Typography variant="h6" gutterBottom>
//               Assignees
//             </Typography>
//           </Grid>

//           <Grid>
//             <EditAssigneesDialog {...props} />
//           </Grid>
//         </Grid>

//         <Spacer mb={4} />

//         <Centered>
//           <Box p={2}>
//             <AvatarGroup total={props?.contract?.assignees?.length}>
//               {props?.contract?.assignees?.map((assignee: string) => {
//                 return <AzureADAvatar key={`azure-ad-avatar-${assignee}`} userId={assignee} size={64} />;
//               })}
//             </AvatarGroup>
//           </Box>

//           <Button mr={2} variant="contained" color="primary" size="small">
//             Message
//           </Button>
//         </Centered>
//       </CardContent>
//     </Card>
//   );
// }

// function EditOwnerDialog({ ...props }) {
//   const [open, setOpen] = useState(false);
//   const [selectedOwner, setSelectedOwner] = useState(props?.contract?.owner);

//   const resetAndClose = () => {
//     // set back to original
//     setSelectedOwner(props?.contract?.owner);

//     setOpen(false);
//   };

//   const handleSaveClick = async () => {
//     console.log("save owner", selectedOwner);

//     const updatedContract = {
//       ...props.contract,
//       owner: selectedOwner[0], // only 1 owner is supported at the moment
//     };

//     await props.updateContract(updatedContract);

//     // close the dialog
//     setOpen(false);
//   };

//   return (
//     <>
//       <Button onClick={() => setOpen(true)}>
//         <EditIcon />
//       </Button>
//       <Dialog open={open} maxWidth={"lg"} fullWidth onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
//         <DialogTitle id="form-dialog-title">Edit Owner</DialogTitle>
//         <DialogContent>
//           <DialogContentText>Select the owner of the contract. The owner is the person who is responsible for the contract.</DialogContentText>

//           <DataGridUsers selected={selectedOwner} onSelect={setSelectedOwner} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => resetAndClose()} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={() => handleSaveClick()} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// function Owner({ ...props }) {
//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Grid container justifyContent="space-between">
//           <Grid>
//             <Typography variant="h6" gutterBottom>
//               Contract Owner
//             </Typography>
//           </Grid>

//           <Grid>
//             <EditOwnerDialog {...props} />
//           </Grid>
//         </Grid>

//         <Spacer mb={4} />

//         <Centered>
//           <AzureADAvatar userId={props.contract?.owner} big />
//           <Button mr={2} variant="contained" color="primary" size="small">
//             Message
//           </Button>
//         </Centered>

//         <Grid container direction="row" alignItems="center" mt={2}>
//           <Grid>
//             <AboutIcon>
//               <Briefcase />
//             </AboutIcon>
//           </Grid>
//           <Grid>
//             Contracts assigned: <Link href="https://mira.bootlab.io/">15</Link>
//           </Grid>

//           {props.ownerProfile?.mail && (
//             <Grid container direction="row" alignItems="center" mt={2}>
//               <Grid>
//                 <AboutIcon>
//                   <Mail />
//                 </AboutIcon>
//               </Grid>
//               <Grid>
//                 <Link href="https://mira.bootlab.io/">{props.ownerProfile?.mail}</Link>
//               </Grid>
//             </Grid>
//           )}

//           {props.ownerProfile?.mobilePhone && (
//             <Grid container direction="row" alignItems="center" mt={2}>
//               <Grid>
//                 <AboutIcon>
//                   <Phone />
//                 </AboutIcon>
//               </Grid>
//               <Grid>
//                 <Link href="https://mira.bootlab.io/">{props.ownerProfile?.mobilePhone}</Link>
//               </Grid>
//             </Grid>
//           )}
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// }

interface ISavingsProps {
  totalContractsValue: number;
}
function Savings({ ...props }: ISavingsProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("-");
  const randomPerc = 0.065;

  useEffect(() => {
    if (props.totalContractsValue) {
      const savings = props.totalContractsValue * randomPerc;

      const formattedCurrency = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR", // Replace with your desired currency code
        compactDisplay: "long",
        maximumFractionDigits: 0,
      }).format(savings); // Format the number as currency

      setValue(formattedCurrency);
    }
  }, [props.totalContractsValue]);

  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">{value}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            {t("Potential Savings")}
          </Typography>

          <StatsIcon>
            <SavingsIcon />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={randomPerc * 100}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

interface IActiveContractsProps {
  contracts: IContract[];
}
function ActiveContracts({ ...props }: IActiveContractsProps) {
  const { t } = useTranslation();

  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">{props.contracts?.length}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            {t("Active Contracts")}
          </Typography>

          <StatsIcon>
            <FormatListNumbered />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={30}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

interface IContractsValueProps {
  totalContractsValue: number;
}
function ContractsValue({ ...props }: IContractsValueProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("-");

  useEffect(() => {
    if (props.totalContractsValue) {
      const formattedCurrency = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR", // Replace with your desired currency code
        compactDisplay: "long",
        maximumFractionDigits: 0,
      }).format(props.totalContractsValue); // Format the number as currency

      setValue(formattedCurrency);
    }
  }, [props.totalContractsValue]);

  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">{value}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            {t("Total Contracts Value")}
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={50}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

interface IVendorTimelineProps {
  vendor: IVendor;
}
function VendorTimeline({ ...props }: IVendorTimelineProps) {
  const { t } = useTranslation();

  const steps = [
    {
      icon: <Event />,
      text: "Contract imported.",
    },
    // {
    //   icon: <AzureADAvatar userId={props.contract?.ownerId} size={24} />,
    //   text: "Contract owner was changed.",
    // },
    // {
    //   icon: <AzureADAvatar userId={props.contract?.ownerId} size={24} />,
    //   text: `Contract assigned to Mark Green for further review.`,
    // },
    {
      icon: <CheckBox />,
      text: `Contract approved by Mark Green.`,
    },
    {
      icon: <CheckBox />,
      text: `Contract is in validation.`,
    },
  ];

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container>
          <Grid size={12}>
            <Typography variant="h6" gutterBottom>
              {t("Timeline")}
            </Typography>
          </Grid>

          <Grid size={12} sx={{ overflowX: "auto" }} pt={2}>
            <Stepper activeStep={1} alternativeLabel>
              {steps.map((step, i) => (
                <Step key={`step-${i}`}>
                  <StepLabel StepIconComponent={() => step?.icon}>
                    {step.text}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function KPIRow({ ...props }) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmDelete = async () => {
    // Call the delete function to delete the item
    await props.handleDeleteFeedback(props.feedback);

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    // Hide the confirmation
    setShowConfirmation(false);
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {props.kpi?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {props.kpi?.description}
      </TableCell>
      <TableCell component="th" scope="row">
        {props.kpi?.score}
      </TableCell>
      {/* <TableCell>
        <ProductsChip size="small" label={props.feedback.category} color="secondary" />
      </TableCell> */}
      <TableCell>
        <KPIDialog
          vendor={props.vendor}
          kpi={props.kpi}
          mode={DialogMode.Delete}
          refetch={props.refetch}
        />

        {showConfirmation ? (
          <Fade in={showConfirmation}>
            <Box>
              <span>Are you sure?</span>
              <IconButton
                aria-label="Cancel"
                size="small"
                onClick={handleCancelDelete}
              >
                <Cancel />
              </IconButton>
              <IconButton
                aria-label="Confirm"
                size="small"
                onClick={handleConfirmDelete}
              >
                <Check />
              </IconButton>
            </Box>
          </Fade>
        ) : (
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              size="small"
              onClick={() => setShowConfirmation(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}

interface IKPIsProps {
  vendor: IVendor;
  refetch: () => void;
}
function KPIs({ ...props }: IKPIsProps) {
  const { t } = useTranslation();

  return (
    <Card mb={6}>
      <CardContent>
        <Grid justifyContent="space-between" container>
          <Grid>
            <Typography variant="h6" gutterBottom>
              {t("KPI's")}
            </Typography>
          </Grid>

          <Grid>
            <KPIDialog
              mode={DialogMode.Add}
              vendor={props.vendor}
              refetch={props.refetch}
            />
          </Grid>
        </Grid>

        <Box mt={3}>
          <DataGridKPIs
            vendor={props.vendor}
            refetch={props.refetch}
            compactHeader
          />
        </Box>
      </CardContent>
    </Card>
  );
}

// Specify the type of data
interface QueryResponse {
  data: ICompanyProfileKVK; // Here, CompanyProfile is the type of your company profile object
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any; // Change `any` to the specific error type if available
  refetch: () => void; // Define the type of the refetch function
  isUnitialized: boolean;
}

interface IKVKProps {
  vendor: IVendor;
  refetch: () => void;
  addError: (error: any) => void;
}

// function KVK({ ...props }: IKVKProps) {
//   const { t } = useTranslation();
//   const { vendor, refetch } = props;

//   const [
//     getCompanyProfile,
//     {
//       data: companyProfile,
//       isLoading: gettingCompanyProfile,
//       isSuccess,
//       isError,
//       error,
//     },
//   ] = useLazyGetCompanyProfileQuery();

//   const [
//     updateVendor,
//     {
//       isSuccess: isUpdated,
//       isLoading: isUpdating,
//       isError: updateError,
//       error: updateErrorData,
//       reset: resetUpdate,
//       data: updatedVendor,
//     },
//   ] = useUpdateVendorMutation();

//   useEffect(() => {
//     resetUpdate();
//   }, [vendor]);

//   useEffect(() => {
//     if (isSuccess && companyProfile) {
//       const bezoekAdres =
//         companyProfile?._embedded?.hoofdvestiging?.adressen?.filter(
//           (a: any) => a.type?.toLowerCase() === "bezoekadres"
//         )[0];

//       const correspondentieAdres =
//         companyProfile?._embedded?.hoofdvestiging?.adressen?.filter(
//           (a: any) => a.type?.toLowerCase() === "correspondentieadres"
//         )[0];

//       const adres = bezoekAdres || correspondentieAdres;

//       const vendorWithProfile: IVendor = {
//         ...vendor,
//         name: companyProfile?.naam,
//         street:
//           adres?.straatnaam ||
//           adres?.volledigAdres ||
//           adres?.straatHuisnummer ||
//           vendor.street ||
//           "",
//         houseNumber: `${adres?.huisnummer || ""}${
//           adres?.huisnummerToevoeging || ""
//         }`,
//         postalCode: adres?.postcode || vendor.postalCode || "",
//         city: adres?.plaats || vendor.city || "",
//         country: adres?.land || vendor.country || "",
//         website:
//           companyProfile?._embedded?.hoofdvestiging?.websites?.length > 0
//             ? companyProfile?._embedded.hoofdvestiging.websites[0]
//             : vendor.website || "",
//       };

//       updateVendor(vendorWithProfile);
//     }
//   }, [isSuccess, companyProfile]);

//   useEffect(() => {
//     if (isUpdated) {
//       resetUpdate();
//     }
//   }, [isUpdated]);

//   useEffect(() => {
//     // catch the errors
//     if (isError) {
//       props.addError(error);
//     }
//   }, [isError]);

//   useEffect(() => {
//     // catch the errors
//     if (updateError) {
//       props.addError(updateErrorData);
//     }
//   }, [updateError]);

//   useEffect(() => {
//     if (isUpdated) {
//       if (props.refetch) {
//         props.refetch();
//       }
//     }
//   }, [isUpdated]);

//   const handleRefreshKVK = async () => {
//     resetUpdate();

//     getCompanyProfile(props.vendor);
//   };

//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Grid justifyContent="space-between" container gap={3}>
//           <Grid>
//             <Typography variant="h6" gutterBottom>
//               {t("Kamer van Koophandel (KVK)")}
//             </Typography>
//           </Grid>

//           <Grid>
//             <Typography>{vendor?.kvkNumber}</Typography>
//             <LoadingButton
//               variant="contained"
//               onClick={() => handleRefreshKVK()}
//               sx={{ mt: 3 }}
//               loading={gettingCompanyProfile}
//               disabled={
//                 props?.vendor?.kvkNumber === "" || gettingCompanyProfile
//               }
//               startIcon={<KvKIcon />}
//             >
//               Refresh KVK
//             </LoadingButton>
//           </Grid>

//           {updateError && (
//             <Grid>
//               <Alert severity="error">{updateErrorData?.data?.message}</Alert>)
//             </Grid>
//           )}

//           {isUpdated && (
//             <Grid>
//               <Alert severity="success">Company details updated</Alert>
//             </Grid>
//           )}
//         </Grid>

//         {/* {isSuccess && <Box mt={3}>{JSON.stringify(companyProfile)}</Box>} */}
//       </CardContent>
//     </Card>
//   );
// }

// function FraudDetection({ ...props }: IKVKProps) {
//   const { t } = useTranslation();
//   const { vendor, refetch } = props;

//   const [refreshKVK, setRefreshKVK] = useState(false);

//   const {
//     data: companyProfile,
//     isLoading: gettingCompanyProfile,
//     isSuccess,
//     isError,
//     error,
//     refetch: getCompanyProfile,
//     isUnitialized,
//   }: QueryResponse = useGetCompanyProfileQuery(props.vendor, {
//     skip: !refreshKVK,
//   });

//   useEffect(() => {
//     if (isSuccess) {
//       setRefreshKVK(false);

//       const dateString = "20160519";
//       const date = new Date(
//         dateString.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3")
//       );

//       const registrationDate = companyProfile?.formeleRegistratiedatum;
//     }
//   }, [isSuccess]);

//   const handleRefreshKVK = async () => {
//     setRefreshKVK(true);
//   };

//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Grid justifyContent="space-between" container gap={3}>
//           <Grid>
//             <Typography variant="h6" gutterBottom>
//               {t("Fraud Detection")}
//             </Typography>
//           </Grid>

//           <Grid>
//             <LoadingButton
//               variant="contained"
//               onClick={() => handleRefreshKVK()}
//               sx={{ mt: 3 }}
//               loading={gettingCompanyProfile}
//             >
//               Refresh KVK
//             </LoadingButton>
//           </Grid>

//           {isError && (
//             <Grid>
//               <Alert severity="error">{error?.data?.message}</Alert>
//             </Grid>
//           )}

//           {companyProfile?.formeleRegistratiedatum && (
//             <Grid>
//               <Alert severity="success">
//                 Registration date:{" "}
//                 {format(
//                   new Date(
//                     companyProfile?.formeleRegistratiedatum.replace(
//                       /^(\d{4})(\d{2})(\d{2})$/,
//                       "$1-$2-$3"
//                     )
//                   ),
//                   "P"
//                 )}
//               </Alert>
//             </Grid>
//           )}
//         </Grid>

//         {/* {isSuccess && <Box mt={3}>{JSON.stringify(companyProfile)}</Box>} */}
//       </CardContent>
//     </Card>
//   );
// }

// Make VendorDetail become Wrapper comp contain 2 comp (1 show vendor's information and 1 show vendor's contracts).
// VendorDetailMode is used to check which comp will show.
enum VendorDetailMode {
  Information = "information",
  Contracts = "contracts",
}

function VendorDetails() {
  const { vendorId, detailMode } = useParams(); // Extracts the :id and :detailMode parameter from the URL
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [mode, setMode] = useState<DialogMode>(DialogMode.ViewOnly);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalContractsValue, setTotalContractsValue] = useState(0);
  const [errors, setErrors] = useState<any[]>([]);

  const {
    data: vendors,
    isLoading: vendorsLoading,
    isSuccess: vendorsLoaded,
    refetch: refetchVendors,
  } = useGetVendorsQuery();

  const {
    data: vendor,
    isLoading: vendorLoading,
    refetch,
  } = useGetVendorQuery(vendorId || "", { skip: !vendorId });

  const {
    data: contracts = [],
    isLoading: contractsLoading,
    isSuccess: contractsLoaded,
    refetch: getContracts,
  } = useGetContractsForVendorQuery(vendorId || "", { skip: !vendorId });

  const { user } = useAuth();

  // const {
  //   data: ownerProfile,
  //   isLoading: ownerLoading,
  //   isSuccess: ownerSuccess,
  //   refetch: getOwner,
  // } = useGetUserProfileForUserQuery(
  //   {
  //     id: contract?.data?.owner,
  //   } ?? { skipToken: !contract?.data?.owner }
  // );

  useEffect(() => {
    const totalValue = contracts
      .filter((c: IContract) => c.budget?.amount && !isNaN(c.budget?.amount))
      .reduce((previousValue, contract) => {
        if (contract.budget?.amount) {
          return previousValue + contract.budget.amount;
        }

        return previousValue;
      }, 0);

    setTotalContractsValue(totalValue);
  }, [contracts]);

  // useEffect(() => {
  //   if (ownerProfile && ownerSuccess) {
  //     // get the user photo for the owner
  //     getOwnerPhoto({ id: ownerProfile.id });
  //   }
  // }, [ownerProfile, ownerSuccess]);

  useEffect(() => {
    if (!vendorId && !vendorLoading && vendors && vendors?.length > 0) {
      // if no id is provided, redirect to the first contract
      navigate(`/vendor/${vendors[0].id}/${detailMode}`);
    }

    if (vendorId && !vendorLoading && vendors && vendors?.length > 0) {
      const currentIndex = vendors?.findIndex((c: any) => c.id === vendorId);

      setCurrentIndex(currentIndex);
    }

    if (vendorId) {
      // if the ID was changed, reset the errors
      setErrors([]);
    }
  }, [vendorId, vendorLoading]);

  useEffect(() => {
    if (!detailMode) {
      navigate(`/vendor/${vendorId}/${VendorDetailMode.Information}`);
    }
  }, [detailMode]);

  const prev = () => {
    // get the name of the previous contract

    if (vendors && vendors?.length > 0) {
      const prevVendorId = vendors[currentIndex - 1]?.id || null;

      if (prevVendorId) {
        navigate(`/vendor/${prevVendorId}/${detailMode}`);
      }
    }
  };

  const next = () => {
    // const currentIndex = contracts?.data.findIndex((c: any) => c.id === filename);

    if (vendors && vendors?.length > 0) {
      const nextVendorId = vendors[currentIndex + 1]?.id || null;

      if (nextVendorId) {
        navigate(`/vendor/${nextVendorId}/${detailMode}`);
      }
    }
  };

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const addError = (error: any) => {
    setErrors((prevErrors) => [...prevErrors, error]);
  };

  return (
    <React.Fragment>
      <Helmet title={vendor?.name} />
      <Grid justifyContent="space-between" container wrap="nowrap">
        <Grid size={10}>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/vendors" color="inherit">
              <Typography variant="h3" gutterBottom display="inline">
                {t("Vendors")}
              </Typography>
            </Link>

            <Link
              component={NavLink}
              to={`/vendor/${vendorId}/${VendorDetailMode.Information}`}
              color={
                detailMode === VendorDetailMode.Information
                  ? "primary"
                  : "inherit"
              }
            >
              <Typography variant="h3" gutterBottom display="inline">
                {vendor?.name}
              </Typography>
            </Link>
            {detailMode === VendorDetailMode.Contracts && (
              <Link
                component={NavLink}
                to={`/vendor/${vendorId}/${VendorDetailMode.Contracts}`}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  display="inline"
                  color="primary"
                >
                  {t("Contracts")}
                </Typography>
              </Link>
            )}
          </Breadcrumbs>
        </Grid>

        {vendorsLoaded && (
          <Grid size={2}>
            <Grid container justifyContent="end" direction="row" gap={3}>
              <Grid>
                <Button
                  onClick={prev}
                  variant="contained"
                  disabled={currentIndex === 0}
                >
                  <ChevronLeft />
                </Button>
              </Grid>

              <Grid>
                <Button
                  onClick={next}
                  variant="contained"
                  disabled={currentIndex === vendors?.length - 1}
                >
                  <ChevronRight />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />

      <Paper
        sx={{
          marginTop: 10,
          marginBottom: 60,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
        }}
      >
        {vendorLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {/* {contract && (
          <Box mb={4}>
            <ContractHeader contract={contract} />
          </Box>
        )} */}
        {errors?.length > 0 &&
          errors.map((error: any) => {
            return (
              <Grid size={12}>
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {error?.data?.message
                    ? error?.data.message
                    : error?.message
                    ? error.message
                    : error?.data
                    ? JSON.stringify(error.data)
                    : JSON.stringify(error)}
                </Alert>{" "}
              </Grid>
            );
          })}
        {detailMode === VendorDetailMode.Information && vendor && (
          <VendorInformation
            mode={mode}
            vendor={vendor}
            refetch={refetch}
            setErrors={setErrors}
          />
        )}
        {detailMode === VendorDetailMode.Contracts && (
          <DataGridContracts
            vendor={vendor}
            vendorContracts={contracts}
            vendorContractsLoading={contractsLoading}
            vendorContractsLoaded={contractsLoaded}
          />
        )}
      </Paper>
    </React.Fragment>
  );
}

export default VendorDetails;
