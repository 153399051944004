import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Card as MuiCard,
  Breadcrumbs as MuiBreadcrumbs,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
  Container,
  Popover,
  IconButton,
  Paper,
  Popper,
  Fade,
  Box,
  ListItemButton,
  Button,
  Divider,
  ListItem,
  Fab,
  Collapse,
  useTheme as muiTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ResizableDelta, Rnd, RndResizeCallback } from "react-rnd";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import {
  ArrowBackIos,
  Close,
  Home,
  HomeOutlined,
  Message,
  MessageOutlined,
  SmartToy as SmartToyIcon,
  Close as CloseIcon,
  AspectRatio,
  DragHandle,
  DragIndicator,
  OpenInFull,
  Expand,
  UnfoldLess,
  UnfoldMore,
} from "@mui/icons-material";

import { css } from "@emotion/react";
import { rgba } from "polished";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../types/document";

import useAuth from "../../hooks/useAuth";
import { connect } from "formik";
import { IDriveItem } from "../../types/driveItem";
import { useAskMeAnythingMutation } from "../../redux/slices/aiSlice";
import useBotChat from "../../hooks/useBotChat";

import {
  ChatType,
  IChatBotHistory,
  NewChatBoxMessage,
  TabType,
} from "../../types/chatBot";
import ChatHome from "../../components/botChat/ChatHome";
import ChatHistory from "../../components/botChat/ChatHistory";
import ChatBox from "../../components/botChat/ChatBox";
import zIndex from "@mui/material/styles/zIndex";
import { use } from "i18next";
import { set } from "date-fns";
import GoBeyondLogo from "../../icons/GoBeyondLogo";
import { ChevronLeft } from "react-feather";

const illustrationCardStyle = (props: any) => css`
  ${props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  // margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

const SearchIconWrapper = styled.div`
  height: 100%;
  // pointer-events: none;
  display: flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.header.color};

  svg {
    width: 22px;
    height: 22px;
  }
`;

const BorderlessTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
});

const Content = styled(Box)`
  // background-color: ${(props) => props.theme.sidebar.background};
  background: ${(props) => props.theme.chatbot.color};
  background: ${(props) => props.theme.chatbot.background};

  transform-origin: right top;
  // width: 35vw;
  // min-width: 400px;
  // max-width: 80vw;
  // height: 50vh;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 20px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    transform: scale(1);
  }
`;

const Header = styled(ListItem)<{}>`
  min-height: 50px;
  // background-color: ${(props) => props.theme.sidebar.header.background};
  color: ${(props) => props.theme.sidebar.header.text};
  font-family: ${(props) => props.theme.typography.fontFamily};
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  padding-top: ${(props) => props.theme.spacing(4)};
  padding-bottom: ${(props) => props.theme.spacing(4)};
  flex-grow: 0;

  // &:hover {
  //   background-color: ${(props) => props.theme.sidebar.header.background};
  // }
`;
const BrandIcon = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 120px;
  height: auto;
  margin: 0 auto;
  margin-top: ${(props) => props.theme.spacing(6)};
`;

const NavIcon = styled(Button)<{ state: "active" | "normal" }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.primary.main};
  // props.active ? props.theme.palette.primary.main : "#000"};
  font-weight: ${(props) => (props.state === "active" ? "bolder" : "normal")};

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: transparent;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

const HeaderButton = styled(IconButton)`
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  color: ${(props) => props.theme.sidebar.color};
  cursor: pointer;
`;

interface IAskMeAnythingProps {
  showLabel?: boolean;
  placeholder?: string;
}
function AskMeAnythingChatBot({ showLabel, placeholder }: IAskMeAnythingProps) {
  const { t } = useTranslation();
  const { contractId, vendorId } = useParams();
  const theme = muiTheme();

  const { open: showChat, setOpen: setShowChat, addedMessage } = useBotChat();

  const { user } = useAuth();
  const { setAddedMessage } = useBotChat();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const [expanded, setExpanded] = useState<boolean | undefined>();

  const initialWidth = 400;
  const initialHeight = 600;
  const bottomPadding = 80;

  const initialPosition = {
    x: window.innerWidth - initialWidth - 40, // align it to the right
    y: window.innerHeight - initialHeight - bottomPadding, // align it to the bottom
    width: initialWidth,
    height: initialHeight,
    xOffset: 0,
    yOffset: 0,
  };

  const [controlPosition, setControlPosition] = useState(initialPosition);
  // const [width, setWidth] = useState(intialWidth); // Initial width
  // const [height, setHeight] = useState(initialHeight); // Initial height

  // const [showChat, setShowChat] = useState(false);
  const open = Boolean(anchorEl);
  const [tab, setTab] = useState<TabType>(TabType.home);
  const [chatBox, setChatBox] = useState<IChatBotHistory>({
    id: null,
    content: [],
    contractId,
    vendorId,
  });
  const [newMessage, setNewMessage] = useState<NewChatBoxMessage | null>(null);

  useEffect(() => {
    const storedPosition = sessionStorage.getItem("chatbot-position");
    if (
      storedPosition &&
      JSON.parse(storedPosition)?.x &&
      JSON.parse(storedPosition)?.y
    ) {
      setControlPosition((prev) => JSON.parse(storedPosition));
    } else {
      // calculateDefaultPosition();
    }

    const storedOpen = sessionStorage.getItem("chatbot-open");
    if (storedOpen) {
      setShowChat(JSON.parse(storedOpen));
    }
  }, []); // document.getElementById("ask-me-anything-inputfield")

  useEffect(() => {
    if (
      controlPosition &&
      JSON.stringify(controlPosition) !== JSON.stringify(initialPosition)
    ) {
      // store the position in the session storage
      sessionStorage.setItem(
        "chatbot-position",
        JSON.stringify(controlPosition)
      );
    }
  }, [controlPosition]);

  useEffect(() => {
    if (
      controlPosition.y >
      window.innerHeight - controlPosition.height - bottomPadding
    ) {
      setControlPosition((prev) => ({
        ...prev,
        y: window.innerHeight - controlPosition.height - bottomPadding,
      }));
    }
  }, [window.innerHeight]);

  useEffect(() => {
    if (expanded) {
      const newWidth = initialWidth; //window.innerWidth * 0.3;
      const newHeight = window.innerHeight * 0.8; // fill 80% of the screen

      setControlPosition((prev) => ({
        ...prev,
        width: newWidth,
        height: newHeight,
        x: window.innerWidth - newWidth - 40,
        y: window.innerHeight - newHeight - bottomPadding,
      }));
    } else if (expanded === false) {
      setControlPosition(initialPosition);
    }
  }, [expanded]);

  useEffect(() => {
    if (addedMessage) {
      setChatBox({
        id: null,
        content: [],
        contractId,
        vendorId,
      });
      setTab(TabType.chatBox);
      setNewMessage({
        type: ChatType.GENERAL,
        message: addedMessage,
      });
    }
  }, [addedMessage]);

  // const calculateDefaultPosition = () => {
  //   // the default position is the width of the content box - the width of the search box

  //   // convert 35vw to pixels
  //   const vw = window.innerWidth * 0.01;
  //   const px = controlPosition.width;

  //   // get the with from the input field with id "ask-me-anything-inputfield"
  //   const inputField = document.getElementById("ask-me-anything-inputfield");
  //   const searchBoxWidth = inputField?.clientWidth || 0;

  //   // get the position of the input field in the body
  //   if (inputField && searchBoxWidth > 0) {
  //     const rect = inputField.getBoundingClientRect();

  //     const newDefaultPosition = {
  //       x: rect.left + searchBoxWidth - px - 16,
  //       y: rect.top + inputField?.clientHeight || 0,
  //       width: initialWidth,
  //       height: initialHeight,
  //       xOffset: 0,
  //       yOffset: 0,
  //     };

  //     setControlPosition(newDefaultPosition);
  //     setDefaultPosition(newDefaultPosition);
  //   } else {
  //     // setControlPosition(defaultPosition);
  //   }
  // };

  const onClose = () => {
    setShowChat(false);
    sessionStorage.setItem("chatbot-open", JSON.stringify(false));

    setControlPosition(initialPosition);
  };

  const handleSelectChatBox = (chatBotHistory: IChatBotHistory) => {
    setNewMessage(null);
    setChatBox(chatBotHistory);
    setTab(TabType.chatBox);

    if (chatBotHistory.contractId) {
      // navigate to the contract page
      navigate(`/contract/${chatBotHistory.contractId}`);
    } else if (chatBotHistory.vendorId) {
      // navigate to the vendor page
      navigate(`/vendor/${chatBotHistory.vendorId}`);
    }
  };

  const handleBackToHistory = () => {
    setTab(TabType.history);
  };

  const handleCreateChatBox = () => {
    setNewMessage(null);
    setChatBox({ id: null, content: [], contractId, vendorId });
    setTab(TabType.chatBox);
  };

  return (
    <>
      {/* <Container>
        {" "}
        {JSON.stringify(controlPosition)}
        <br />
        {window.innerHeight}
      </Container> */}
      <Container
        sx={{
          position: "absolute",
        }}
      >
        {showChat && (
          <Rnd
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
              // border: "solid 1px #ddd",
              // background: "#f0f0f0",
              zIndex: theme.zIndex.modal + 1,
              flexDirection: "column",
              position: "fixed",
            }}
            size={{
              width: controlPosition.width,
              height: controlPosition.height,
            }}
            position={{
              x: controlPosition.x + controlPosition.xOffset,
              y: controlPosition.y + controlPosition.yOffset,
            }}
            default={{
              x: controlPosition.x + controlPosition.xOffset,
              y: controlPosition.y + controlPosition.yOffset,
              width: controlPosition.width,
              height: controlPosition.height,
            }}
            onDragStart={() => setIsDragging(true)}
            onDragStop={(e, d) => {
              setControlPosition((prev) => ({
                ...prev,
                x: d.x,
                y: d.y,
              }));
              setIsDragging(false);
            }}
            dragAxis="both"
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={(e, direction, ref, delta, position) => {
              setIsResizing(false);

              setControlPosition({
                width: parseInt(ref.style.width),
                height: parseInt(ref.style.height),
                x:
                  controlPosition.x -
                  (direction === "left" ||
                  direction === "topLeft" ||
                  direction === "bottomLeft"
                    ? delta.width
                    : 0),
                y:
                  controlPosition.y -
                  (direction === "top" ||
                  direction === "topRight" ||
                  direction === "topLeft"
                    ? delta.height
                    : 0),
                xOffset: 0,
                yOffset: 0,
              });
            }}
            onResize={(e, direction, element, delta, position) => {
              if (direction === "left") {
                setControlPosition((prev) => ({
                  ...prev,
                  xOffset: delta.width * -1,
                }));
              } else if (direction === "top") {
                setControlPosition((prev) => ({
                  ...prev,
                  yOffset: delta.height * -1,
                }));
              } else if (direction === "topRight") {
                setControlPosition((prev) => ({
                  ...prev,
                  yOffset: delta.height * -1,
                }));
              } else if (direction === "topLeft") {
                setControlPosition((prev) => ({
                  ...prev,
                  xOffset: delta.width * -1,
                  yOffset: delta.height * -1,
                }));
              } else if (direction === "bottomLeft") {
                setControlPosition((prev) => ({
                  ...prev,
                  xOffset: delta.width * -1,
                }));
              }
            }}
            bounds="body"
            cancel="strong"
            minHeight={initialHeight}
            minWidth={initialWidth}
          >
            <Paper
              sx={{
                cursor: isDragging ? "grabbing" : "grab",
                // zIndex: 1300,
                backgroundColor: "transparent",
                boxShadow: "none",
                height: "100%",
                width: "100%",
              }}
            >
              <Content
                className={showChat ? "show" : ""}
                // style={{
                //   width: controlPosition.width,
                //   height: controlPosition.height,
                // }}
                flexDirection="column"
                display="flex"
                height="100%"
              >
                <Header
                  sx={{
                    justifyContent:
                      tab === TabType.history ? "center" : "initial",
                  }}
                >
                  {/* {controlPosition.y} */}
                  {tab === TabType.home && (
                    <Box>
                      <GoBeyondLogo showText={true} />
                    </Box>
                  )}

                  {/* <Tooltip title={t("Drag me")}>
                  <HeaderButton>
                    <DragIndicator
                      sx={{ cursor: isDragging ? "grabbing" : "grab" }}
                    />
                  </HeaderButton>
                </Tooltip> */}

                  {tab === TabType.history && (
                    <strong className="no-cursor">
                      <Typography
                        variant="h5"
                        display="flex"
                        justifyContent="center"
                      >
                        {t("Conversations")}
                      </Typography>
                    </strong>
                  )}

                  {tab === TabType.chatBox && (
                    <>
                      <strong className="no-cursor">
                        {/* {tab === TabType.history && (
                    <Typography variant="h5" mx="auto">
                      {t("Conversations")}
                    </Typography>
                  )} */}
                        <HeaderButton onClick={handleBackToHistory}>
                          <ChevronLeft />
                        </HeaderButton>
                      </strong>
                      <Container component={"div"} />
                      <strong className="no-cursor">
                        <HeaderButton onClick={() => setExpanded(!expanded)}>
                          {expanded ? <UnfoldLess /> : <UnfoldMore />}
                        </HeaderButton>
                      </strong>
                    </>
                  )}

                  {/* <strong className="no-cursor">
                  <HeaderButton onClick={() => onClose()}>
                    <CloseIcon />
                  </HeaderButton>
                </strong> */}
                </Header>
                {/* {tab !== TabType.home && <Divider />} */}
                <Box sx={{ flex: 1, overflowY: "auto" }}>
                  {tab === TabType.home && (
                    <ChatHome
                      onConversationClick={handleSelectChatBox}
                      onCreateChatBox={handleCreateChatBox}
                      setTab={setTab}
                    />
                  )}
                  {tab === TabType.history && (
                    <ChatHistory
                      onConversationClick={handleSelectChatBox}
                      onCreateChatBox={handleCreateChatBox}
                      refetch={handleBackToHistory}
                    />
                  )}
                  {tab === TabType.chatBox && (
                    <ChatBox
                      id={chatBox.id}
                      content={chatBox?.content}
                      newMessage={newMessage}
                      contractId={chatBox.contractId}
                      vendorId={chatBox.vendorId}
                    />
                  )}
                </Box>
                {tab !== TabType.chatBox && (
                  <Box>
                    <Divider variant="inset" sx={{ marginLeft: 0 }} />
                    <Grid
                      container
                      justifyContent="space-around"
                      alignItems="center"
                      height={80}
                    >
                      <Grid>
                        <NavIcon
                          state={tab === TabType.home ? "active" : "normal"}
                          variant="text"
                          onClick={() => setTab(TabType.home)}
                          sx={{ transition: "all 0.3s" }}
                        >
                          {tab === TabType.home ? (
                            <Home color="primary" />
                          ) : (
                            <HomeOutlined />
                          )}
                          {t("Home")}
                        </NavIcon>
                      </Grid>
                      <Grid>
                        <NavIcon
                          state={tab === TabType.history ? "active" : "normal"}
                          variant="text"
                          onClick={() => setTab(TabType.history)}
                          // slowly transition to the bold font
                          sx={{ transition: "all 0.3s" }}
                        >
                          {tab === TabType.history ? (
                            <Message color="primary" />
                          ) : (
                            <MessageOutlined />
                          )}
                          {t("Conversations")}
                        </NavIcon>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Content>
            </Paper>

            <Fab
              id="close-chat-button"
              color="primary"
              onClick={() => onClose()}
              size="medium"
              sx={{ position: "absolute", bottom: -60, right: 0 }}
            >
              <CloseIcon />
              {/* <ChevronUp /> */}
            </Fab>
          </Rnd>
        )}
      </Container>
    </>
  );
}

export default AskMeAnythingChatBot;
