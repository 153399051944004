import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import FullScreenLoader from "../FullScreenLoader";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();

  // return <FullScreenLoader />;
  if (!isInitialized) {
    return <FullScreenLoader />;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (isInitialized && isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  //  return <React.Fragment>{children}</React.Fragment>;

  // return <Typography>Session expired. Please log out and in again...</Typography>;
  return <Navigate to="/auth/sign-in" />;
}

export default AuthGuard;
