import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";

const DocumentViewerSkeleton = () => {
  return (
    <Box height={900} width="100%">
      <Grid container size={12} spacing={6} direction="row">
        <Grid
          container
          size={4}
          direction="column"
          spacing={6}
          justifyContent="center"
          alignContent="center"
        >
          <Grid>
            <Skeleton variant="rounded" width={100} height={140} />
          </Grid>
          <Grid>
            <Skeleton variant="rounded" width={100} height={140} />
          </Grid>
          <Grid>
            <Skeleton variant="rounded" width={100} height={140} />
          </Grid>
          <Grid>
            <Skeleton variant="rounded" width={100} height={140} />
          </Grid>
          <Grid>
            <Skeleton variant="rounded" width={100} height={140} />
          </Grid>
        </Grid>
        <Grid size={8}>
          <Skeleton variant="rectangular" height={900} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentViewerSkeleton;
