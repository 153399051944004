import { SvgIcon } from "@mui/material";

const SharePointIcon = (props: any) => (
  <SvgIcon {...props}>
    {/* Include the path or content of your SVG icon */}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g id="STYLE_COLOR" data-name="STYLE = COLOR">
        <circle cx="14.99964" cy="9.5" r="9.5" fill="#036c70" />
        <circle cx="23.87464" cy="17.875" r="8.125" fill="#1a9ba1" />
        <circle cx="15.99964" cy="25.5" r="6.5" fill="#37c6d0" />
        <path
          d="M16.66663,7H5.83276a9.50556,9.50556,0,0,0,9.16687,12c.27729,0,.55164-.01263.82288-.03589l.00537.03815A6.50007,6.50007,0,0,0,9.49963,25.5q0,.25231.019.5h7.148A1.33732,1.33732,0,0,0,18,24.66663V8.33331A1.33727,1.33727,0,0,0,16.66663,7Z"
          opacity="0.1"
        />
        <path
          d="M15.66663,8H5.61792a9.50509,9.50509,0,0,0,9.38171,11c.27729,0,.55164-.01263.82288-.03589l.00537.03815A6.50459,6.50459,0,0,0,9.67389,27h5.99274A1.33732,1.33732,0,0,0,17,25.66663V9.33331A1.33727,1.33727,0,0,0,15.66663,8Z"
          opacity="0.2"
        />
        <path
          d="M15.66663,8H5.61792a9.50509,9.50509,0,0,0,9.38171,11c.27729,0,.55164-.01263.82288-.03589l.00537.03815A6.50063,6.50063,0,0,0,9.51868,25h6.148A1.33732,1.33732,0,0,0,17,23.66663V9.33331A1.33727,1.33727,0,0,0,15.66663,8Z"
          opacity="0.2"
        />
        <path
          d="M14.66663,8H5.61792a9.50509,9.50509,0,0,0,9.38171,11c.27729,0,.55164-.01263.82288-.03589l.00537.03815A6.50063,6.50063,0,0,0,9.51868,25h5.148A1.33732,1.33732,0,0,0,16,23.66663V9.33331A1.33727,1.33727,0,0,0,14.66663,8Z"
          opacity="0.2"
        />
        <path
          id="Back_Plate"
          data-name="Back Plate"
          d="M1.33333,8H14.66669A1.33334,1.33334,0,0,1,16,9.33334V22.66666A1.33333,1.33333,0,0,1,14.6667,24H1.33334A1.33334,1.33334,0,0,1,0,22.66666V9.33333A1.33333,1.33333,0,0,1,1.33333,8Z"
          fill="#03787c"
        />
        <path
          d="M5.67039,15.82457a2.64535,2.64535,0,0,1-.82266-.86964A2.36142,2.36142,0,0,1,4.561,13.76466a2.29061,2.29061,0,0,1,.5327-1.5409,3.14167,3.14167,0,0,1,1.416-.92359A5.98158,5.98158,0,0,1,8.44506,11a7.35414,7.35414,0,0,1,2.54889.35738v1.80041a3.98617,3.98617,0,0,0-1.15307-.472,5.59553,5.59553,0,0,0-1.34862-.16183,2.92625,2.92625,0,0,0-1.38581.29321A.91071.91071,0,0,0,6.557,13.65a.84355.84355,0,0,0,.23275.59013,2.12174,2.12174,0,0,0,.62689.44831q.3948.19576,1.17678.51922a1.23212,1.23212,0,0,1,.16857.06743,9.69661,9.69661,0,0,1,1.48348.73173,2.654,2.654,0,0,1,.87661.88313,2.55808,2.55808,0,0,1,.31692,1.33187,2.48083,2.48083,0,0,1-.499,1.60485,2.78893,2.78893,0,0,1-1.33513.89683A6.04879,6.04879,0,0,1,7.70332,21a10.0284,10.0284,0,0,1-1.72275-.14161,5.91231,5.91231,0,0,1-1.3993-.40458V18.55226a4.50013,4.50013,0,0,0,1.41605.67431,5.51323,5.51323,0,0,0,1.55765.24949,2.68013,2.68013,0,0,0,1.41257-.3.94723.94723,0,0,0,.4755-.84636.90389.90389,0,0,0-.26625-.64734,2.70416,2.70416,0,0,0-.73521-.51248Q7.973,16.934,7.056,16.54956A7.85955,7.85955,0,0,1,5.67039,15.82457Z"
          fill="#fff"
        />
        <rect x="0.00003" width="32" height="32" fill="none" />
      </g>
    </svg>
  </SvgIcon>
);

export default SharePointIcon;
