import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { IConnectedDrive } from "../../types/connecteddrive";
import {
  useGetDriveItemsQuery,
  useGetDriveItemThumbnailsQuery,
  useGetDriveQuery,
  useGetSiteQuery,
  useLazyGetDriveItemsQuery,
} from "../../redux/slices/graphApiSlice";
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import SharePointIcon from "../../icons/SharePointIcon";
import {
  CheckCircle,
  Done,
  ErrorOutline,
  OpenInNew,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import ConnectedSharePointDriveDialog from "../dialogs/ConnectedSharePointDriveDialog";
import { DialogMode } from "../../types/dialogmode";
import { IDriveItem } from "../../types/driveItem";
import {
  useAddContractMutation,
  useAddDocumentMutation,
  useGetContractQuery,
  useSyncConnectedDriveMutation,
} from "../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import SharePointFilesDialog from "../dialogs/SharePointFilesDialog";
import useAuth from "../../hooks/useAuth";
import { IDocument } from "../../types/document";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../types/contract";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { StringSchema } from "yup";

type DriveItemCardProps = {
  driveItem: IDriveItem;
};

const DriveItemCard = (props: DriveItemCardProps) => {
  const { t } = useTranslation();

  const driveItem = props.driveItem;

  const { data: thumbnails, isLoading: loadingThumbnails } =
    useGetDriveItemThumbnailsQuery({
      driveId: driveItem?.parentReference.driveId,
      driveItemId: driveItem.id,
    });

  const { data: contract } = useGetContractQuery(driveItem?.id ?? skipToken);

  return (
    <Card variant="outlined">
      <CardActionArea href={`${driveItem.webUrl}?web=1`} target="_blank">
        {!loadingThumbnails &&
          thumbnails?.value &&
          thumbnails?.value.length > 0 && (
            <CardMedia
              sx={{
                height: thumbnails.value[0].medium?.height,
                width: thumbnails.value[0].medium?.width,
                margin: "0 auto",
                marginTop: 3,
              }}
              image={thumbnails.value[0].medium?.url}
              title={driveItem.name}
            />
          )}
        <CardContent>
          <Tooltip title={driveItem.name} arrow placement="top">
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                lineHeight: "1.2em",
                height: "2.4em",
                textAlign: "center",
              }}
            >
              {driveItem.name}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>

      <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
        <Stack direction="column" gap={3} width="100%" justifyContent="start">
          <Divider />

          <Stack direction="row" justifyContent="space-between">
            {driveItem.id && (
              <Icon>
                {contract?.analyzeStatus === AIReadingStatus.COMPLETED ? (
                  <Done color="success" />
                ) : contract?.analyzeStatus === AIReadingStatus.ANALYZING ? (
                  <CircularProgress size={16} />
                ) : contract?.analyzeStatus === AIReadingStatus.ERROR ? (
                  <ErrorOutline color="error" />
                ) : (
                  <Typography variant="caption">
                    {contract?.analyzeStatus}
                  </Typography>
                )}
              </Icon>
            )}

            {contract?.id ? (
              <Button
                variant="contained"
                // size="small"
                color="primary"
                href={`/contract/${contract?.id}`}
                target="_blank"
              >
                {t("View contract")}
              </Button>
            ) : (
              <Button disabled>{t("Not synced")}</Button>
            )}

            <Tooltip title={t("View in SharePoint")!}>
              <IconButton
                size="small"
                color="primary"
                href={`${driveItem.webUrl}?web=1`}
                target="_blank"
                // onClick={() => {}}
              >
                <OpenInNew />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  );
};
export default DriveItemCard;
