import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { green } from "@mui/material/colors";

import {
  Box,
  Chip,
  Drawer as MuiDrawer,
  ListItemButton,
  Fade,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { ReactComponent as LogoTextLight } from "../../vendor/logo_text_light.svg";
import { ReactComponent as LogoTextDark } from "../../vendor/logo_text_dark.svg";
import { SidebarItemsType } from "../../types/sidebar";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import useAuth from "../../hooks/useAuth";
import useTheme from "../../hooks/useTheme";
import { THEMES } from "../../constants";
import { useGetOrganizationBrandingQuery } from "../../redux/slices/graphApiSlice";
import { getTenantId } from "../../redux/slices/authSlice";
import { store } from "../../redux/store";
import GoBeyondLogo from "../../icons/GoBeyondLogo";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
  isDrawerOpen: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  isDrawerOpen,
  ...rest
}) => {
  const { user, isAuthenticated } = useAuth();
  const { theme } = useTheme();
  const tenantId = getTenantId(store.getState());

  const { data: organizationBranding, isSuccess: logoLoaded } =
    useGetOrganizationBrandingQuery(tenantId || "", { skip: !tenantId });

  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarNav items={items} isDrawerOpen={isDrawerOpen} />
      {!!showFooter && isAuthenticated && (
        <Footer isDrawerOpen={isDrawerOpen} />
      )}
    </Drawer>
  );
};

export default Sidebar;
