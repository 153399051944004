import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../../types/dialogmode";
import DataGridActivities from "../../../components/datagrids/DataGridActivities";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ActivityList() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <React.Fragment>
      <Helmet title={t("Activities")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Activities")}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <DataGridActivities />
    </React.Fragment>
  );
}

export default ActivityList;
