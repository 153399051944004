import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";
import { DarkMode, LightMode } from "@mui/icons-material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

type themeOptionsType = {
  [key: string]: {
    img: string;
    name: string;
    theme: any;
  };
};

const themeOptions: themeOptionsType = {
  default: {
    img: "default",
    name: "Default",
    theme: THEMES.DEFAULT,
  },
  blue: {
    img: "blue",
    name: "Blue",
    theme: THEMES.BLUE,
  },
  dark: {
    img: "dark",
    name: "Dark",
    theme: THEMES.DARK,
  },
  green: {
    img: "green",
    name: "Green",
    theme: THEMES.GREEN,
  },
  indigo: {
    img: "indigo",
    name: "Indigo",
    theme: THEMES.INDIGO,
  },
  light: {
    img: "light",
    name: "Light",
    theme: THEMES.LIGHT,
  },
};

function NavbarDarkModeSwitch() {
  const { t } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const { setTheme, theme } = useTheme();

  const toggleDarkMode = (event: React.SyntheticEvent) => {
    if (theme === THEMES.DARK) {
      setTheme(THEMES.LIGHT);
    } else {
      setTheme(THEMES.DARK);
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        title={
          theme === THEMES.DARK
            ? t("Switch to light mode")
            : t("Switch to dark mode")
        }
      >
        <IconButton onClick={toggleDarkMode} color="inherit" size="large">
          {theme === THEMES.DARK ? <LightMode /> : <DarkMode />}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarDarkModeSwitch;
