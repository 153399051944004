import React from "react";
import styled from "@emotion/styled";
import { Euro, Numbers, QuestionMark } from "@mui/icons-material";
import {
  InputAdornment,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Box, spacing } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import {
  Field,
  FieldArray,
  FormikProvider,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
  useFormik,
} from "formik";
import { useEffect, useState } from "react";
import { DollarSign, Flag } from "react-feather";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import useContract from "../../hooks/useContract";

import { IContract } from "../../types/contract";
import { IGraphUser } from "../../types/user";
import { IVendor } from "../../types/vendor";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useGetVendorsQuery } from "../../redux/slices/vendorsApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

interface AutocompleteOption {
  id: string;
  label: string;
  logo: string;
}

type Props = {
  initialValues?: IContract;
  setFormValues: React.Dispatch<any>;
  onError?: (isError: boolean) => any;
};
const ContractForm: React.FC<Props> = ({
  initialValues,
  setFormValues,
  onError,
}) => {
  const { t } = useTranslation();
  const { user, method } = useAuth();
  const { openedContract, analyzedContract: analyzedContractContext } =
    useContract();

  const users = useSelector((state: RootState) => state.users.users);

  const values: Partial<IContract> = {
    subject: "",
    summary: "",
    countryOfJurisdiction: "",
    adminIds: [],
    ownerIds: [],
    managerIds: [],
    vendorId: "",
    vendorName: "",
    vendorAddress: "",
    startDate: undefined,
    endDate: undefined,
    noticePeriod: undefined,
    budget: undefined,
    risks: [],
    name: "",
    id: "",
    created: Date.now(),
    modified: Date.now(),

    // ...analyzedValues,
  };

  const formik = useFormik<Partial<IContract>>({
    initialValues: initialValues || values,
    validationSchema: Yup.object().shape({
      subject: Yup.string().optional(), // .required(t("Required field")!),
      summary: Yup.string().optional(), // .required(t("Required field")!),
      vendorId: Yup.string().optional(), // .required(t("Required field")!),
      address: Yup.string().optional(), // .required(t("Required field")!),
      startDate: Yup.date().optional(),
      // .required(t("Required field")!),
      endDate: Yup.date().when("startDate", (startDate, schema) =>
        values.startDate
          ? schema.min("startDate", "End date must be after start date")
          : schema.optional()
      ),
      // .required(t("Required field")!),
      noticePeriod: Yup.object()
        .optional()
        .shape({
          value: Yup.number()
            .positive(t("Notice period must be a positive number")!)
            .optional(),
          unit: Yup.string().optional(),
        }),
      value: Yup.number()
        .positive(t("Price must be a positive number")!)
        .optional(),
      risks: Yup.array().of(
        Yup.object().shape({
          risk: Yup.string().optional(),
          recommendation: Yup.string().optional(),
        })
      ),
    }),
    onSubmit: (values) => {},
  });

  const {
    data: vendors,
    isLoading: isLoadingVendors,
    isSuccess: vendorsLoaded,
  } = useGetVendorsQuery();

  // const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [vendorOptions, setVendorOptions] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    if (initialValues && vendors && vendorsLoaded && !initialValues.vendorId) {
      formik.setTouched(
        setNestedObjectValues<FormikTouched<FormikValues>>(formik.values, true)
      );
      let vendorId = "";
      if (initialValues.vendorName) {
        let vendorOpt = vendors.find(
          (v: any) => v.name === initialValues.vendorName
        );
        if (!vendorOpt) {
          vendorId = "new";
          let newVendor: AutocompleteOption = {
            label: initialValues.vendorName ?? "",
            id: vendorId,
            logo: "",
            // logo: initialValues.vendorLogo ?? "",
          };
          setVendorOptions((prevValue) => {
            return [...prevValue, newVendor];
          });
        } else {
          vendorId = vendorOpt.id;
        }
      }
      formik.setValues({
        ...initialValues,
        vendorId,
      });
    }
  }, [initialValues?.vendorName, vendors, vendorsLoaded]);

  useEffect(() => {
    if (vendors && vendorsLoaded) {
      setVendorOptions((prevValue) => {
        let newValue = vendors?.map((vendor: IVendor) => ({
          label: vendor.name,
          id: vendor.id,
          logo: vendor.logo,
        }));
        return [...prevValue, ...newValue];
      });
    }
  }, [vendors, vendorsLoaded]);

  useEffect(() => {
    if (onError) {
      onError(!formik.isValid);
    }
  }, [formik.errors]);

  useEffect(() => {
    if (setFormValues) {
      setFormValues({ ...formik.values });
    }
  }, [formik.values]);

  // const dateErrorMessage = useMemo(() => {
  //   switch (dateError) {
  //     case "maxDate": {
  //       return t("Start date must be before end date");
  //     }
  //     case "minDate": {
  //       return t("End date must be after today");
  //     }
  //     case "invalidDate": {
  //       return "Your date is not valid";
  //     }
  //     default: {
  //       return "";
  //     }
  //   }
  // }, [dateError, t]);

  const noticePeriodUnitOptions = [
    { label: t("Days"), value: "days" },
    { label: t("Weeks"), value: "weeks" },
    { label: t("Months"), value: "months" },
  ];

  const currencyOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
  ];

  const fieldVariant = "standard";
  const errorFieldVariant = "filled";

  return (
    <FormikProvider value={formik}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nlNL">
          <Grid container direction="column" spacing={3}>
            <Grid>
              <TextField
                name="subject"
                label={t("Subject")}
                value={formik.values.subject}
                error={Boolean(formik.touched.subject && formik.errors.subject)}
                fullWidth
                helperText={
                  formik.touched.subject && formik.errors.subject
                    ? formik.errors.subject.toString()
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: !!formik.values.subject }}
                my={2}
                variant={
                  formik.touched.subject && formik.errors.subject
                    ? errorFieldVariant
                    : fieldVariant
                }
              />
            </Grid>

            <Grid>
              <TextField
                name="countryOfJurisdiction"
                label={t("Country of jurisdiction")}
                value={formik.values.countryOfJurisdiction}
                error={Boolean(
                  formik.touched.countryOfJurisdiction &&
                    formik.errors.countryOfJurisdiction
                )}
                fullWidth
                helperText={
                  formik.touched.countryOfJurisdiction &&
                  formik.errors.countryOfJurisdiction
                    ? formik.errors.countryOfJurisdiction.toString()
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: !!formik.values.countryOfJurisdiction,
                }}
                my={2}
                variant={
                  formik.touched.countryOfJurisdiction &&
                  formik.errors.countryOfJurisdiction
                    ? errorFieldVariant
                    : fieldVariant
                }
              />
            </Grid>

            <Grid>
              <TextField
                multiline
                name="summary"
                label={t("Summary")}
                value={formik.values.summary}
                error={Boolean(formik.touched.summary && formik.errors.summary)}
                fullWidth
                helperText={
                  formik.touched.summary && formik.errors.summary
                    ? formik.errors.summary.toString()
                    : null
                }
                InputLabelProps={{ shrink: !!formik.values.summary }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                my={2}
                variant={
                  formik.touched.summary && formik.errors.summary
                    ? errorFieldVariant
                    : fieldVariant
                }
              />
            </Grid>

            <Grid>
              <Grid container spacing={3}>
                <Grid size={{ xs: 6 }}>
                  <FormControl fullWidth my={2}>
                    <Field name="vendorId">
                      {() => (
                        <Autocomplete
                          fullWidth
                          id="vendor-select"
                          options={vendorOptions}
                          freeSolo={false}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="40"
                                src={option.logo}
                                srcSet={`${option.logo} 2x`}
                                alt=""
                              />
                              {option.label}
                            </Box>
                          )}
                          onChange={(event: any, option: any) => {
                            formik.setFieldValue("vendorId", option?.id);
                          }}
                          value={
                            formik.values.vendorId &&
                            vendorOptions &&
                            vendorOptions.length > 0
                              ? vendorOptions.filter(
                                  (u) => u.id === formik.values.vendorId
                                )[0]
                              : null
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label={t("Select Vendor")}
                              variant={
                                formik.touched.vendorId &&
                                formik.errors.vendorId
                                  ? errorFieldVariant
                                  : fieldVariant
                              }
                            />
                          )}
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <TextField
                    multiline
                    name="vendorAddress"
                    label={t("Address")}
                    value={formik.values.vendorAddress}
                    error={Boolean(
                      formik.touched.vendorAddress &&
                        formik.errors.vendorAddress
                    )}
                    fullWidth
                    helperText={
                      formik.touched.vendorAddress &&
                      formik.errors.vendorAddress
                        ? formik.errors.vendorAddress.toString()
                        : null
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: !!formik.values.vendorAddress }}
                    my={2}
                    variant={
                      formik.touched.vendorAddress &&
                      formik.errors.vendorAddress
                        ? errorFieldVariant
                        : fieldVariant
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <Grid container spacing={3}>
                <Grid size={{ sm: 12, md: 6 }}>
                  <FormControl variant={fieldVariant} my={2} fullWidth>
                    <Field name="startDate">
                      {() => {
                        const startDate = formik.values.startDate
                          ? dayjs(formik.values.startDate)
                          : null;

                        const endDate = formik.values.endDate
                          ? dayjs(formik.values.endDate)
                          : null;

                        return (
                          <DatePicker
                            label={t("Start date")}
                            value={startDate}
                            onChange={(date) =>
                              formik.setFieldValue("startDate", date)
                            }
                            // maxDate={endDate} //formik.values.startDate ? new Date(formik.values.startDate) : null}
                            slotProps={{
                              textField: {
                                name: "startDate",
                                onBlur: formik.handleBlur,
                                error: Boolean(
                                  formik.touched.startDate &&
                                    formik.errors.startDate
                                ),
                                helperText:
                                  formik.touched.startDate &&
                                  formik.errors.startDate
                                    ? formik.errors.startDate.toString()
                                    : null,
                                variant:
                                  formik.touched.startDate &&
                                  formik.errors.startDate
                                    ? errorFieldVariant
                                    : fieldVariant,
                              },
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid size={{ sm: 12, md: 6 }}>
                  <FormControl variant={fieldVariant} my={2} fullWidth>
                    <Field name="endDate">
                      {() => {
                        const startDate = formik.values.startDate
                          ? dayjs(formik.values.startDate)
                          : null;

                        const endDate = formik.values.endDate
                          ? dayjs(formik.values.endDate)
                          : null;

                        return (
                          <DatePicker
                            label={t("End date")}
                            value={endDate}
                            onChange={(date) =>
                              formik.setFieldValue("endDate", date)
                            }
                            // minDate={startDate} //formik.values.startDate ? new Date(formik.values.startDate) : null}
                            slotProps={{
                              textField: {
                                // helperText: dateError === "minDate" ? dateErrorMessage : null,
                                // error: dateError === "minDate",
                                name: "endDate",
                                onBlur: formik.handleBlur,
                                error: Boolean(
                                  formik.touched.endDate &&
                                    formik.errors.endDate
                                ),
                                helperText:
                                  formik.touched.endDate &&
                                  formik.errors.endDate
                                    ? formik.errors.endDate.toString()
                                    : null,
                                variant:
                                  formik.touched.endDate &&
                                  formik.errors.endDate
                                    ? errorFieldVariant
                                    : fieldVariant,
                              },
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <Grid container spacing={3}>
                <Grid size={{ sm: 12, md: 9 }}>
                  <TextField
                    name="noticePeriod.value"
                    label={t("Notice period")}
                    value={formik.values.noticePeriod?.value}
                    type="number"
                    // helperText={formik.touched.name && formik.errors.name ? formik.errors.name.toString() : null}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    my={2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Numbers sx={{ width: 16 }} />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: !!formik.values.noticePeriod?.value,
                      style: {
                        marginLeft: formik.values.noticePeriod?.value ? 0 : 26,
                      },
                    }}
                    variant={
                      formik.touched.noticePeriod && formik.errors.noticePeriod
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    // helperText={t("A notification will be sent when the contract is about to expire")}
                  />
                </Grid>

                <Grid size={{ sm: 12, md: 3 }} mt={2}>
                  <FormControl fullWidth>
                    <Field
                      name="noticePeriod.unit"
                      value={formik.values.noticePeriod?.unit}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      {() => (
                        <Autocomplete
                          fullWidth
                          disablePortal
                          options={noticePeriodUnitOptions}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label={t("Units")}
                              variant={
                                formik.touched.noticePeriod &&
                                formik.errors.noticePeriod
                                  ? errorFieldVariant
                                  : fieldVariant
                              }
                            />
                          )}
                          disableClearable
                          defaultValue={
                            formik.values.noticePeriod?.unit
                              ? noticePeriodUnitOptions.filter(
                                  (option) =>
                                    option.value ===
                                    formik.values.noticePeriod?.unit
                                )[0]
                              : undefined
                          }
                          onChange={(event: any, option: any) => {
                            formik.setFieldValue(
                              "noticePeriod.unit",
                              option?.value
                            );
                          }}
                          value={
                            noticePeriodUnitOptions.filter(
                              (option) =>
                                option.value ===
                                formik.values.noticePeriod?.unit
                            )[0] || undefined
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <Grid container spacing={3}>
                <Grid size={{ sm: 12, md: 9 }}>
                  <NumericFormat
                    name="budget.amount"
                    label={t("Value")}
                    value={formik.values.budget?.amount}
                    customInput={TextField}
                    thousandSeparator={true}
                    error={Boolean(
                      formik.touched.budget && formik.errors.budget
                    )}
                    helperText={
                      formik.touched.budget && formik.errors.budget
                        ? formik.errors.budget.toString()
                        : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    my={2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formik.values.budget?.currency === "EUR" ? (
                            <Euro sx={{ width: 16 }} />
                          ) : formik.values.budget?.currency === "USD" ? (
                            <DollarSign size={16} />
                          ) : (
                            <QuestionMark sx={{ width: 16 }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: !!formik.values.budget?.amount,
                      style: {
                        marginLeft: formik.values.budget?.amount ? 0 : 26,
                      },
                    }}
                    variant={
                      formik.touched.budget && formik.errors.budget
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;

                      formik.setFieldValue("budget.amount", value);
                      // Here you can update the state with the formatted value or handle it as required.
                      // setValue(formattedValue);
                      // props.onChange(value);
                    }}
                  />

                  {/* <NumericFormat
                    name="budget.amount"
                    label={t("Budget")}
                    value={formik.values.budget?.amount}
                    // type="number"
                    error={Boolean(
                      formik.touched.budget && formik.errors.budget
                    )}
                    helperText={
                      formik.touched.budget && formik.errors.budget
                        ? formik.errors.budget.toString()
                        : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    my={2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formik.values.budget?.currency === "EUR" ? (
                            <Euro fontSize="small" />
                          ) : formik.values.budget?.currency === "USD" ? (
                            <DollarSign fontSize="small" />
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: !!formik.values.budget?.amount,
                      style: { marginLeft: 32 },
                    }}
                    variant={
                      formik.touched.budget && formik.errors.budget
                        ? errorFieldVariant
                        : fieldVariant
                    }
                  /> */}
                </Grid>

                <Grid
                  size={{
                    sm: 12,
                    md: 3,
                  }}
                  mt={2}
                >
                  {/* Currency dropdown */}
                  <FormControl fullWidth>
                    <Field name="budget.currency">
                      {() => (
                        <Autocomplete
                          fullWidth
                          id="currency-select"
                          options={currencyOptions}
                          getOptionLabel={(option) => option.label}
                          value={
                            formik.values.budget?.currency
                              ? currencyOptions.filter(
                                  (option) =>
                                    option.value ===
                                    formik.values.budget?.currency
                                )[0]
                              : undefined
                          }
                          onChange={(event: any, option: any) => {
                            formik.setFieldValue(
                              "budget.currency",
                              option?.value
                            );
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label={t("Currency")}
                              variant={
                                formik.touched.budget && formik.errors.budget
                                  ? errorFieldVariant
                                  : fieldVariant
                              }
                            />
                          )}
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid>
              <FieldArray
                name="risks"
                render={(arrayHelpers) => {
                  const risksTouched: any = arrayHelpers.form.touched?.risks;
                  const risksErrors: any = arrayHelpers.form.errors?.risks;
                  return (
                    <>
                      {formik.values.risks?.map(
                        (
                          risk: { risk: string; recommendation: string },
                          index: number
                        ) => {
                          return (
                            <Grid container spacing={0} mb={3}>
                              <Grid key={index} xs={12}>
                                <TextField
                                  multiline
                                  name={`risks[${index}].risk`}
                                  label={t("Risk")}
                                  value={risk.risk}
                                  error={Boolean(
                                    risksTouched?.at(index)?.risk &&
                                      risksErrors?.at(index)?.risk
                                  )}
                                  fullWidth
                                  helperText={
                                    (risksTouched?.at(index)?.risk &&
                                      risksErrors?.at(index)?.risk) ||
                                    null
                                  }
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  InputLabelProps={{ shrink: !!risk }}
                                  my={2}
                                  variant={
                                    risksTouched?.at(index)?.risk &&
                                    risksErrors?.at(index)?.risk
                                      ? errorFieldVariant
                                      : fieldVariant
                                  }
                                />
                              </Grid>

                              <Grid key={index} xs={12}>
                                <TextField
                                  multiline
                                  name={`risks[${index}].recommendation`}
                                  label={t("Recommendation")}
                                  value={risk.recommendation}
                                  error={Boolean(
                                    risksTouched?.at(index)?.recommendation &&
                                      risksErrors?.at(index)?.recommendation
                                  )}
                                  fullWidth
                                  helperText={
                                    (risksTouched?.at(index)?.recommendation &&
                                      risksErrors?.at(index)?.recommendation) ||
                                    null
                                  }
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  my={2}
                                  variant={
                                    risksTouched?.at(index)?.recommendation &&
                                    risksErrors?.at(index)?.recommendation
                                      ? errorFieldVariant
                                      : fieldVariant
                                  }
                                />
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </>
                  );
                }}
              />
            </Grid> */}
          </Grid>
        </LocalizationProvider>
      </form>
    </FormikProvider>
  );
};

export default ContractForm;
