// ** React Imports
import { Document, Page } from "react-pdf";
import React, { ReactEventHandler, useEffect, useRef, useState } from "react";

export interface IPNGViewerProps {
  arrayBuffer?: ArrayBuffer;
  filename?: string;
}

function PNGViewer({ ...props }: IPNGViewerProps) {
  const imageSource = `data:image/png;base64,${Buffer.from(
    props?.arrayBuffer!
  ).toString("base64")}`;

  return <img alt={props?.filename} src={imageSource} />;
}

export default React.memo(PNGViewer);
// export default DocumentViewer;
