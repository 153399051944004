import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";

const getFileTypeIcon = (filename: string) => {
  const extension = filename.split(".").pop()?.toLowerCase();

  return extension;
};

const FluentUIFileTypeIcon = ({ filename }: { filename: string }) => {
  const extension = getFileTypeIcon(filename);

  return (
    <Icon
      {...getFileTypeIconProps({
        extension: extension,
        imageFileType: "png",
        size: 32,
      })}
    />
  );
  // return <Icon iconName={iconName} />;
};

export default FluentUIFileTypeIcon;
