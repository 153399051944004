import React, { useEffect, useTransition } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Link,
  TextField as MuiTextField,
  Typography,
  Autocomplete,
  Box,
  InputAdornment,
  Alert,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  CloudUpload as MuiCloudUpload,
  Gavel as GavelIcon,
  CalendarToday,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../../redux/slices/indexApiSlice";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ISettings } from "../../../types/settings";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function Public() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Public info
        </Typography>

        <Grid container spacing={6}>
          <Grid size={8}>
            <TextField
              id="username"
              label="Username"
              defaultValue="lucylavender"
              variant="outlined"
              fullWidth
              my={2}
            />

            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                label="Biography"
                id="biography"
                multiline={true}
                rows={3}
                maxRows={4}
                variant="outlined"
                defaultValue="Lucy is a Freelance Writer and Social Media Manager who helps finance professionals and Fin-tech startups build an audience and get more paying clients online."
              />
            </FormControl>
          </Grid>
          <Grid size={4}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
        </Grid>

        <Button variant="contained" color="primary">
          Save changes
        </Button>
      </CardContent>
    </Card>
  );
}

interface CountryType {
  code: string;
  flag: string;
  label: string;
  suggested?: boolean;
}

function DefaultLanguage(props: { value: string; onChange: any }) {
  const { t } = useTranslation();
  const { value, onChange } = props;

  const languageOptions: readonly CountryType[] = [
    { code: "en", flag: "gb", label: "English" }, // do not translate this
    {
      code: "nl",
      flag: "nl",
      label: "Nederlands", // do not translate this
    },
  ];

  return (
    <FormControl fullWidth>
      <Field name="defaultLanguage">
        {() => (
          <Autocomplete
            id="default-language-select"
            sx={{ width: 300 }}
            options={languageOptions}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(event: any, value: any) => {
              onChange(value);
            }}
            defaultValue={
              languageOptions.filter((option) => option.code === value)[0] ||
              undefined
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.flag.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.flag.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={t("Choose default language")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                variant="standard"
              />
            )}
          />
        )}
      </Field>
    </FormControl>
  );
}

function General() {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = React.useState<ISettings | null>(
    null
  );

  const [updateSettings, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateSettingsMutation();

  const {
    data: settings,
    isLoading: settingsLoading,
    isSuccess: settingsLoaded,
    refetch,
    isError: isSettingsError,
    error: settingsError,
  } = useGetSettingsQuery();

  const validationSchema = Yup.object().shape({
    defaultLanguage: Yup.string(),
  });

  const notificationPeriodUnitOptions = [
    { label: t("days"), value: "days" },
    { label: t("weeks"), value: "weeks" },
    { label: t("months"), value: "months" },
  ];

  useEffect(() => {
    if (isUpdated) {
      refetch();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (settingsLoaded) {
      if (settings) {
        setInitialValues(settings);
      } else {
        setInitialValues({} as ISettings);
      }
    }
  }, [settingsLoaded, settingsLoading]);

  const handleSubmit = async (
    values: ISettings,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      await updateSettings(values);

      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  if (!settingsLoaded || !initialValues) {
    return <CircularProgress />;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues!}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldError,
      }) => (
        <Card mb={6}>
          <CardContent>
            {/* <Typography variant="h6" gutterBottom>
              {t("General")}
            </Typography> */}

            <Grid container spacing={6}>
              {isSettingsError && (
                <Grid size={12}>
                  <Alert severity="error">{settingsError}</Alert>
                </Grid>
              )}

              <Grid size={12} mt={2}>
                <Form id="settings-form" onSubmit={handleSubmit}>
                  <Grid container gap={3} direction="column">
                    <Grid>
                      <Grid container direction="column" spacing={3}>
                        <Grid>
                          <Typography variant="h5">{t("Language")}</Typography>
                        </Grid>

                        <Grid>
                          <DefaultLanguage
                            value={values?.defaultLanguage}
                            onChange={(value: any) => {
                              setFieldValue("defaultLanguage", value?.code);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid mt={5}>
                      <Grid container direction="column" spacing={3}>
                        <Grid>
                          <Typography variant="h5">
                            {t("Notification period")}
                          </Typography>
                          <Typography variant="body1">
                            {t("Notification period description")}
                          </Typography>
                        </Grid>

                        <Grid>
                          <Grid container spacing={3} flexDirection="row">
                            <Grid mt={2}>
                              <FormControl>
                                <TextField
                                  name="notificationPeriod.value"
                                  // label={t("Notification period")}
                                  // placeholder={t("Value_placeholder")!}
                                  value={values?.notificationPeriod?.value}
                                  type="number"
                                  error={Boolean(
                                    touched.notificationPeriod &&
                                      errors.notificationPeriod
                                  )}
                                  fullWidth
                                  // helperText={touched.value && errors.value}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  // type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarToday />
                                      </InputAdornment>
                                    ),
                                  }}
                                  variant="standard"
                                  my={2}
                                />
                              </FormControl>
                            </Grid>

                            <Grid mt={0}>
                              <FormControl>
                                <Field
                                  name="notificationPeriod.unit"
                                  value={values?.notificationPeriod?.unit}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                >
                                  {() => (
                                    <Autocomplete
                                      disablePortal
                                      id="notification-period-unit-selector"
                                      options={notificationPeriodUnitOptions}
                                      sx={{ width: 120 }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          label={t("Units")}
                                          variant="standard"
                                        />
                                      )}
                                      onChange={(event: any, option: any) => {
                                        setFieldValue(
                                          "notificationPeriod.unit",
                                          option?.value
                                        );
                                      }}
                                      disableClearable
                                      defaultValue={
                                        notificationPeriodUnitOptions.filter(
                                          (option) =>
                                            option.value ===
                                            values?.notificationPeriod?.unit
                                        )[0] || undefined
                                      }
                                    />
                                  )}
                                </Field>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                        disabled={isSubmitting}
                      >
                        {t("Save")}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function Settings() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Settings")!} />

      <Typography variant="h3" gutterBottom display="inline">
        {t("Settings")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Dashboard")}
        </Link>
        <Typography>{t("Settings")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid size={12}>
          <General />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
