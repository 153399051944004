import { SvgIcon } from "@mui/material";

const Number404Icon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="570"
    height="242"
    viewBox="0 0 570 242"
    fill="none"
  >
    <path
      d="M-34.7443 197.364V153.5L60.5966 3.27272H99.5739V62.8182H76.9602L21.1648 151.227V153.045H159.233V197.364H-34.7443ZM77.642 236V183.955L78.7784 164.75V3.27272H131.392V236H77.642ZM284.972 241.682C264.669 241.682 247.169 236.871 232.472 227.25C217.775 217.553 206.449 203.652 198.494 185.545C190.54 167.364 186.6 145.508 186.676 119.977C186.752 94.447 190.729 72.7803 198.608 54.9773C206.563 37.0985 217.85 23.5 232.472 14.1818C247.169 4.78787 264.669 0.0909004 284.972 0.0909004C305.275 0.0909004 322.775 4.78787 337.472 14.1818C352.244 23.5 363.608 37.0985 371.562 54.9773C379.517 72.8561 383.456 94.5227 383.381 119.977C383.381 145.583 379.403 167.477 371.449 185.659C363.494 203.841 352.169 217.742 337.472 227.364C322.85 236.909 305.35 241.682 284.972 241.682ZM284.972 195.659C297.093 195.659 306.903 189.485 314.403 177.136C321.903 164.712 325.616 145.659 325.54 119.977C325.54 103.159 323.835 89.2955 320.426 78.3864C317.017 67.4015 312.282 59.2197 306.222 53.8409C300.161 48.4621 293.078 45.7727 284.972 45.7727C272.926 45.7727 263.191 51.8712 255.767 64.0682C248.343 76.1894 244.593 94.8258 244.517 119.977C244.441 137.023 246.07 151.152 249.403 162.364C252.813 173.576 257.585 181.947 263.722 187.477C269.858 192.932 276.941 195.659 284.972 195.659ZM412.756 197.364V153.5L508.097 3.27272H547.074V62.8182H524.46L468.665 151.227V153.045H606.733V197.364H412.756ZM525.142 236V183.955L526.278 164.75V3.27272H578.892V236H525.142Z"
      fill="#5184DB"
    />
  </svg>
);

export default Number404Icon;
