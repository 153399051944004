import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { IGraphUser } from "../../types/user";
import { RootState } from "../store";

export const ADD_FILE = "ADD_FILE";
export const REMOVE_FILE = "REMOVE_FILE";

export interface FileState {
  files: { [id: string]: ArrayBuffer };
  base64files: { [id: string]: string };
}

const initialState: FileState = {
  files: {},
  base64files: {},
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    addFile: (
      state,
      action: PayloadAction<{ id: string; buffer: ArrayBuffer }>
    ) => {
      state.files[action.payload.id] = action.payload.buffer;
    },
    addBase64File: (
      state,
      action: PayloadAction<{ id: string; base64: string }>
    ) => {
      state.base64files[action.payload.id] = action.payload.base64;
    },
    removeFile: (state, action: PayloadAction<string>) => {
      delete state.files[action.payload];
    },
    removeBase64File: (state, action: PayloadAction<string>) => {
      delete state.base64files[action.payload];
    },
  },
});

// create a function that selects a file
export const selectFile = (id: string) =>
  createSelector(
    (state: RootState) => state.files.files,
    (files) => {
      return files[id];
    }
  );

// check if the file exists. If yes, return true
export const fileExists = (id: string) =>
  createSelector(
    (state: RootState) => state.files.files,
    (files) => {
      return !!files[id];
    }
  );

// create a function that selects a base64file
export const selectBase64File = (id: string) =>
  createSelector(
    (state: RootState) => state.files.base64files,
    (files) => {
      return files[id];
    }
  );

// check if the base64file exists. If yes, return true
export const fileBase64Exists = (id: string) =>
  createSelector(
    (state: RootState) => state.files.base64files,
    (files) => {
      return !!files[id];
    }
  );

export const { addFile, removeFile, addBase64File, removeBase64File } =
  filesSlice.actions;

export default filesSlice;
