import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { AzureApi } from "./slices/indexApiSlice";
import { GraphApi } from "./slices/graphApiSlice";
import authSliceReducer from "./slices/authSlice";
import { AIApi } from "./slices/aiSlice";
import { kpiSlice } from "./slices/kpiSlice";
import { footerSlice } from "./slices/footerSlice";
import { KVKApi } from "./slices/kvkSlice";
import { DigitalSigningApi } from "./slices/digitalSigningSlice";
import { ChatBotApi } from "./slices/chatBotSlice";
import usersSlice from "./slices/usersSlice";
import filesSlice from "./slices/filesSlice";
import { VendorsApi } from "./slices/vendorsApiSlice";

const actionSanitizer = (action: any) =>
  action.type === "files/addFile" && action.payload
    ? { ...action, payload: "<<LONG_BLOB>>" }
    : action;

export const store = configureStore({
  reducer: {
    [AzureApi.reducerPath]: AzureApi.reducer,
    [VendorsApi.reducerPath]: VendorsApi.reducer,
    [DigitalSigningApi.reducerPath]: DigitalSigningApi.reducer,
    [ChatBotApi.reducerPath]: ChatBotApi.reducer,
    [GraphApi.reducerPath]: GraphApi.reducer,
    [AIApi.reducerPath]: AIApi.reducer,
    [KVKApi.reducerPath]: KVKApi.reducer,
    auth: authSliceReducer,
    kpi: kpiSlice.reducer,
    footer: footerSlice.reducer,
    files: filesSlice.reducer,
    users: usersSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      AzureApi.middleware,
      VendorsApi.middleware,
      DigitalSigningApi.middleware,
      ChatBotApi.middleware,
      GraphApi.middleware,
      AIApi.middleware,
      KVKApi.middleware
    ),

  devTools: {
    actionSanitizer,
    stateSanitizer: (state: any) =>
      state.data ? { ...state, data: "<<LONG_BLOB>>" } : state,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
