import * as msal from "@azure/msal-browser";
import config from "../../config";
import { PublicClientApplication } from "@azure/msal-browser";
let msalInstance: PublicClientApplication;
const clientId = "0c235339-a8d6-47ba-af64-6d68069f7baf";

export const initialize = async () => {
  try {
    const msalConfig = {
      auth: {
        clientId: clientId,
        //authority: `https://login.microsoftonline.com/${tenantId}`, // single tenant
        authority: "https://login.microsoftonline.com/common/", // multi tenant
      },
      cache: {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
        cacheLocation: msal.BrowserCacheLocation.LocalStorage, // "sessionStorage"
      },
    };

    msalInstance = new msal.PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    return await acquireTokenSilent();
  } catch (err) {
    console.error(err);
    return null;
  }
};

const acquireTokenSilent = async () => {
  await msalInstance.handleRedirectPromise();

  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    // No user signed in
    return null;
  }

  const request: msal.SilentRequest = {
    account: accounts[0],
    redirectUri: config.REDIRECT_URL, // "http://localhost:3000
    scopes: config.PERMISSION_SCOPES,
  };

  return await msalInstance.acquireTokenSilent(request);
};

export const login = async () => {
  const msalConfig = {
    auth: {
      clientId: clientId,
      //authority: `https://login.microsoftonline.com/${tenantId}`, // single tenant
      authority: "https://login.microsoftonline.com/common/", // multi tenant
    },
    cache: {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
      cacheLocation: msal.BrowserCacheLocation.LocalStorage, // "sessionStorage"
    },
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  const request = {
    redirectUri: config.REDIRECT_URL, // "http://localhost:3000
    scopes: config.PERMISSION_SCOPES,
  };

  return await msalInstance.loginPopup(request);
};

export const signOut = async () => {
  msalInstance.logoutPopup();
};
