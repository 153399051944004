import { AuthUser } from "./auth";
import { IDatePeriod } from "./datePeriod";
import { IDocument } from "./document";
import { ILocationInText } from "./prompt";
import { ISignature } from "./signature";

export enum ContractStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  SIGNING = "SIGNING",
  SIGNED = "SIGNED",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  EXPIRED = "EXPIRED",
  ALL = "ALL",
}

export type IContract = {
  id: string;
  name: string;
  subject: string;
  type?: string;
  category?: string;
  folders?: string[];
  summary: string;
  countryOfJurisdiction: string;
  vendorId: string; // for creating a link to the Vendors container
  vendorName: string; // for displaying the vendor name at the moment of contract creation
  vendorAddress: string; // for displaying the vendor address at the moment of contract creation
  // category: string;

  seller: {
    // the party that promises to do something, e.g. deliver a product, provide a service, etc. for the other party, typically a vendor or service provider
    name: string;
    address: string;
    contactPersons: {
      name: string;
      email: string;
      phone: string;
      role: string;
    }[];
    explanation: string; // in case a clear promisor is not specified in the contract, use this field to describe the promisor
  };
  buyer: {
    // the party that receives the promise, e.g. the product or service from the other party, typically a customer or client
    name: string;
    address: string;
    contactPersons: {
      name: string;
      email: string;
      phone: string;
      role: string;
    }[];
    explanation: string; // in case a clear promisee is not specified in the contract, use this field to describe the promisee
  };

  noticePeriod?: IDatePeriod; // { value: number; // 30  unit: string; // "days" }
  startDate?: string; // ISO format, e.g. 2023-09-15T00:00:00.000Z
  endDate?: string; // ISO format, e.g. 2023-09-15T00:00:00.000Z
  budget?: {
    amount: number;
    currency: string;
    paymentTerms?: string;
  };
  // ownerId: string;
  // assigneeIds: string[];
  adminIds: string[];
  ownerIds: string[];
  managerIds: string[];
  outlookMessageId?: string; // indicated the outlook message the contract was created from (if applicable)
  created: number;
  modified: number;
  tenantId?: string;
  isAnalyzed?: boolean;
  users?: AuthUser[];
  signatures?: ISignature[];

  // additional fields
  risks: { risk: string; recommendation: string }[];
  // risks: string[];

  // recommendation: string;
  status?: ContractStatus;
  analyzeStatus?: AIReadingStatus;
  analyzeTaskId?: string;
  estimatedCosts?: string; // estimated cost for analyzing the contract
  chatbotId?: string;
  explanations?: {
    dates: string;
    noticePeriod: string;
    seller: string;
    buyer: string;
    financials: string;
  };

  extraContext?: {
    property: string;
    details: string;
    locationInText: string[];
  }[];

  createdBy?: string;
};

export enum AIReadingStatus {
  COMPLETED = "COMPLETED",
  ANALYZING = "ANALYZING",
  ERROR = "ERROR",
  STOPPED = "STOPPED",
  NONE = "NONE",
}

// export enum AnalysisStatus {
//   FAILED = "failed",
//   SUCCESS = "success",
//   ANALYZING = "analyzing",
//   NONE = "none",
//   STOPPED = "stopped",
// }

export type AnalyzedContract = {
  status?: ContractStatus;
  name: string;
  contractId: string;
  progress?: number;
  analyzeStatus?: AIReadingStatus;
  analyzeTaskId?: string;
};
