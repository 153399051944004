import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Tooltip,
  Fade,
  CardActionArea,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing, SpacingProps } from "@mui/system";
import KPIDialog from "../../../components/dialogs/KPIDialog";
import { IKPI } from "../../../types/kpi";
import { Check } from "react-feather";
import DataGridKPIs from "../../../components/datagrids/DataGridKPIs";
import { DialogMode } from "../../../types/dialogmode";
import { t } from "i18next";
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function KPIList({ ...props }) {
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
    }
  }, [refetch]);

  return (
    <React.Fragment>
      <Helmet title="Vendors" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("KPI's")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/libraries">
              {t("Libraries")}
            </Link>
            <Typography> {t("KPI's")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid>
          <KPIDialog mode={DialogMode.Add} refetch={() => setRefetch(true)} />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Box mb={6}>
        By requesting these KPIs from vendors, you can gather valuable
        information to assess their capabilities, performance, and alignment
        with your organization's requirements.
      </Box>

      <DataGridKPIs view="grid" refetch={refetch} />
    </React.Fragment>
  );
}

export default KPIList;
