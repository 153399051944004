import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;

  background-image: url("/static/img/backgrounds/dark-raster.png");
  //background-image: url("/static/img/backgrounds/2.jpg");
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;

  background-color: ${(props) => props.theme.palette.background.default};

  // // background opacity
  // ::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #000;
  //   // background-color: rgba(0, 0, 0, 0.5);
  // }
`;

interface AuthType {
  children?: React.ReactNode;
}

const Auth: React.FC<AuthType> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
