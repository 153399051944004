import React from "react";
import { useState } from "react";

import { Avatar, Box, Fade, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { IContract } from "../../../../types/contract";
import AzureADAvatar from "../../../../components/avatars/AzureADAvatar";
import { DialogMode } from "../../../../types/dialogmode";
import ContractRoleDialog from "../../../../components/dialogs/ContractRoleDialog";
import { PropaneSharp } from "@mui/icons-material";

interface ISidebarRolesProps {
  contract: IContract;
  refetch?: () => void;
  hideTitle?: boolean;
}
function SidebarRoles({ contract, refetch, hideTitle }: ISidebarRolesProps) {
  const { t } = useTranslation();

  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const fields: any[] = ["adminIds", "managerIds", "ownerIds"];

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box width="100%">
        {!hideTitle && (
          <Typography variant="h6" mb={2}>
            {t("Roles")}
          </Typography>
        )}

        <Grid container spacing={3} direction="column" px={2}>
          {fields.map((field, i) => {
            const persons =
              field === "adminIds"
                ? contract.adminIds
                : field === "managerIds"
                ? contract.managerIds
                : field === "ownerIds"
                ? contract.ownerIds
                : [];

            const label =
              field === "adminIds"
                ? t("Administrator")
                : field === "managerIds"
                ? t("Manager")
                : field === "ownerIds"
                ? t("Owner")
                : "";

            return (
              <Grid
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(-1)}
                onBlur={() => setHoveredIndex(-1)}
                key={`role-${field}`}
              >
                <Box
                  display="flex"
                  gap={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{label}</Typography>

                  {persons?.length === 0 ? (
                    <Box>
                      <ContractRoleDialog
                        mode={DialogMode.Add}
                        contract={contract}
                        refetch={refetch}
                        showMenuIcon={i === hoveredIndex}
                        field={field}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <ContractRoleDialog
                        mode={DialogMode.Edit}
                        contract={contract}
                        refetch={refetch}
                        showMenuIcon={i === hoveredIndex}
                        field={field}
                      />
                    </Box>
                  )}
                </Box>

                <Grid container gap={2} direction="column" p={2}>
                  {persons?.map((userId) => (
                    <Grid key={userId}>
                      <AzureADAvatar userId={userId} small />
                    </Grid>
                  ))}

                  {(!persons || persons?.length === 0) && (
                    <Grid>
                      <ContractRoleDialog
                        mode={DialogMode.Add}
                        contract={contract}
                        refetch={refetch}
                        showMenuIcon
                        field={field}
                        iconOnly
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default SidebarRoles;
