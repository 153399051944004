import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Tooltip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useUpdateContractMutation } from "../../redux/slices/indexApiSlice";
import {
  AutoFixHigh,
  Delete,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  Cancel,
  Search,
  PersonAdd,
} from "@mui/icons-material";

import { IContract } from "../../types/contract";
import { DialogMode } from "../../types/dialogmode";
import DataGridUsers from "../datagrids/DataGridUsers";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";
import DataGridCompanies from "../datagrids/DataGridCompanies";
import { useLazySearchCompanyQuery } from "../../redux/slices/kvkSlice";
import KvKIcon from "../../icons/KvKIcon";
import { IVendor } from "../../types/vendor";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

type ISearchCompanyDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  refetch?: () => void;
  searchText: string;
  setSelectedCompany: (selected: any) => void;
  onCancel?: () => void;
  open?: boolean;
};

function SearchCompanyDialog(props: ISearchCompanyDialogProps) {
  const { t } = useTranslation();

  const {
    mode,
    iconOnly,
    inlineConfirmation,
    refetch,
    searchText,
    setSelectedCompany,
  } = props;

  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    if (props.open !== undefined) {
      // open/close the dialog if it's controlled from outside
      setOpen(props.open);
    }
  }, [props.open]);

  // useEffect(() => {
  //   if (isUpdated) {
  //     if (refetch) {
  //       refetch();
  //     }

  //     resetUpdate();

  //     setOpen(false);
  //   }
  // }, [isUpdated]);

  const resetAndClose = () => {
    // set back to original
    // setSelectedUserIds(selectedUserIds);

    setOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleSaveClick = async () => {
    setSelectedCompany(selected);
    setOpen(false);
    // await updateContract(updatedContract);
  };

  return (
    <>
      {/* <Button variant="text" onClick={() => setOpen(true)}>
        <Search sx={{ mr: 2 }} />
      </Button> */}

      {props.open === undefined && (
        <Button variant="text" onClick={() => setOpen(true)}>
          <Search sx={{ mr: 2 }} /> {t("Search in KVK")}
        </Button>
      )}

      {/* <ListItemButton onClick={() => setOpen(true)}>
        <ListItemIcon>
          <KvKIcon />
        </ListItemIcon>
        <ListItemText primary={t("Search company")} secondary={t("test")} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => setOpen(true)}>
            <Search />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton> */}

      <Dialog
        open={open}
        maxWidth={"md"}
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Search in the business register of The Netherlands")}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 350 }}>
          <DataGridCompanies searchText={searchText} onSelect={setSelected} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetAndClose()} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSaveClick()}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SearchCompanyDialog;
