import styled from "@emotion/styled";
import {
  ChevronLeft,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search,
  SendRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Card as MuiCard,
  Typography as MuiTypography,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  TypographyProps,
  useTheme,
} from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { flexbox, height, spacing, width } from "@mui/system";
import React from "react";
import { useState, useEffect } from "react";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import useBotChat from "../../hooks/useBotChat";
import OwlIcon from "../../icons/OwlIcon";
import OwlAvatarIcon from "../../icons/OwlAvatarIcon";
import { ChevronRight } from "react-feather";
import { IPrompt } from "../../types/prompt";
import {
  useGetPromptsQuery,
  useLazyGetChatBotHistoryQuery,
  useLazyGetPromptsQuery,
} from "../../redux/slices/indexApiSlice";
import ChatInput from "./ChatInputWidget";
import { IChatBotHistory, TabType } from "../../types/chatBot";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { position } from "polished";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 10px;
  // background-color: ${(props) => props.theme.palette.neutral.primary};
  // background-color: ${(props) => props.theme.palette.common.white};
  // background: ${(props) => props.theme.palette.primary.main};
  // background: ${(props) => props.theme.palette.primary.gradient};
  // background-image: url("/static/img/backgrounds/dark-raster.png");
  // background-position: top center;
  // background-size: initial;
`;
const TypographyTitle = styled(MuiTypography)`
  // color: ${(props) => props.theme.palette.common.white};
`;
const NewChatBoxButton = styled(Button)`
  height: 60px;
`;

// Main Component
type DefaultPromptProps = {
  maxHeight?: number;
  onPromptClick: (prompt: IPrompt) => void;
  view?: "listWithSearch" | "randomChips";
  max?: number;
  showMore?: boolean;
  disabled?: boolean;
};

const DefaultPrompts = ({ ...props }: DefaultPromptProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();
  const [promptsToShow, setPromptsToShow] = useState<number>(props.max || 3);
  const [promptsSearchText, setPromptsSearchText] = useState<string>("");
  const [promptsFiltered, setPromptsFiltered] = useState<IPrompt[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 3;
  const numPages = Math.ceil(promptsFiltered.length / pageSize);

  const [getChatHistory, { data: conversations, isSuccess, isLoading }] =
    useLazyGetChatBotHistoryQuery();

  useEffect(() => {
    getChatHistory();
  }, []);

  useEffect(() => {
    if (promptsSearchText === "") {
      setPromptsFiltered(prompts);
      setPromptsToShow(3);
    } else {
      const filteredPrompts = prompts.filter(
        (prompt: IPrompt) =>
          prompt.body
            ?.toLowerCase()
            .includes(promptsSearchText.toLowerCase()) ||
          prompt.category
            ?.toLowerCase()
            .includes(promptsSearchText.toLowerCase()) ||
          prompt.comment
            ?.toLowerCase()
            .includes(promptsSearchText.toLowerCase())
      );

      setPromptsFiltered(filteredPrompts);
    }
  }, [promptsSearchText, promptsLoaded, prompts]);

  const handlePromptClick = (prompt: IPrompt) => {
    props.onPromptClick(prompt);
  };

  const handleNext = async () => {
    if (currentPage === numPages - 1) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleBack = () => {
    if (currentPage === 0) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  return (
    <Box>
      {props.view === "listWithSearch" && (
        <Card
          my={4}
          sx={{
            boxShadow: 1,
            borderRadius: 3,
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              px: 2, // remove the padding from the last child
              "&:last-child": {
                paddingBottom: 0,
              },
              py: 0,
            }}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 500 }}
              gutterBottom
              pt={2}
              pl={2}
            >
              {t("Start with a prompt")}
            </Typography>

            <List disablePadding sx={{ pb: 2 }}>
              <strong className="no-cursor">
                <ListItem
                  disablePadding
                  secondaryAction={<Search />}
                  sx={{ pb: 1 }}
                >
                  <ListItemButton
                    role={undefined}
                    dense
                    sx={{
                      borderRadius: 2,
                    }}
                  >
                    <TextField
                      placeholder={t("Search in prompt library")!}
                      onChange={(e) => setPromptsSearchText(e.target.value)}
                      value={promptsSearchText}
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{
                        // no border
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </strong>

              <Box
                style={{
                  maxHeight: props.maxHeight || "100%",
                  overflow: "auto",
                }}
              >
                {promptsFiltered?.map((prompt: IPrompt, i: number) => {
                  if (i >= promptsToShow) {
                    return null;
                  }

                  return (
                    <ListItem
                      key={prompt?.id}
                      secondaryAction={<ChevronRight />}
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => handlePromptClick(prompt)}
                        dense
                        sx={{ borderRadius: 2 }}
                      >
                        {/* <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon> */}
                        <ListItemText primary={prompt.body} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Box>
            </List>

            {promptsFiltered?.length > promptsToShow && props.showMore && (
              <Box display="flex" justifyContent="center" pb={2}>
                <Button onClick={() => setPromptsToShow(promptsToShow + 3)}>
                  {t("Show more")}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {props.view === "randomChips" && (
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          px={3}
        >
          {currentPage > 0 && (
            <Box alignContent="center">
              <IconButton onClick={handleBack} disabled={currentPage === 0}>
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </IconButton>
            </Box>
          )}

          <Box display="flex" m={4} gap={2} width="100%">
            <Box
              sx={{
                paddingBottom: 2,
                flexWrap: "wrap",
                justifyContent: "end",
                display: "flex",
              }}
            >
              {promptsFiltered?.map((prompt: IPrompt, i: number) => {
                // if (i >= promptsToShow) {
                //   return null;
                // }

                if (
                  i >= currentPage * pageSize &&
                  i < (currentPage + 1) * pageSize
                ) {
                  return (
                    <Chip
                      key={prompt?.id}
                      label={prompt.body}
                      onClick={() => handlePromptClick(prompt)}
                      disabled={props.disabled}
                      sx={{
                        m: 0.5,
                        py: 2,
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                      }}
                      variant="outlined"
                      color={
                        prompt.category === "Leveringsvoorwaarden"
                          ? "success"
                          : prompt.category === "Borgstelling"
                          ? "warning"
                          : prompt.category === "Markttoetsing"
                          ? "primary"
                          : "info"
                      }
                    />
                  );
                }
              })}
            </Box>
          </Box>

          {currentPage < numPages - 1 && (
            <Box alignContent="center">
              <IconButton
                onClick={handleNext}
                disabled={currentPage === numPages - 1}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            </Box>
          )}
        </Stack>
      )}

      {/* {promptsFiltered?.length > promptsToShow && props.showMore && (
            <Box display="flex" justifyContent="center" pb={2}>
              <Button onClick={() => setPromptsToShow(promptsToShow + 3)}>
                {t("Show more")}
              </Button>
            </Box>
          )} */}
    </Box>
  );
};

export default DefaultPrompts;
