import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography as MuiTypography,
  CardActions,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  CardActionArea,
  useTheme,
} from "@mui/material";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { SpacingProps, alpha, spacing } from "@mui/system";

import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

type EnhancedDataGridProps = {
  title: string;
  rows: any;
  columns: any;
  handleRowDoubleClick: any;
  loading: boolean;
  deleting: boolean;
  compactHeader: boolean;
  noRowsOverlayLabel: string;
  view: "cards" | "table";
  setView?: any;
  deleteRow: any;
  onSelect: any;
  selected: any;
};

const EnhancedDataGrid = (props: EnhancedDataGridProps) => {
  const { t } = useTranslation();

  // const [view, setView] = useState(props.view || "cards");
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    props?.onSelect && props?.onSelect(rowSelectionModel);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  const handleToolbarDeleteClick = (event: any) => {
    console.log(rowSelectionModel);

    rowSelectionModel.forEach((row) => {
      props.deleteRow({ id: row });
    });
  };

  return (
    <Paper>
      <Box
        height={props.rows.length > 0 ? "auto" : 400} // 55vh
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiToolbar-root": {
            minHeight: props.compactHeader ? "unset" : "64px",
          },
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: props.rows?.length > 5 ? 10 : 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          rows={props.rows}
          columns={props.columns}
          autoHeight
          checkboxSelection={props.rows.length > 0}
          loading={props.loading}
          columnHeaderHeight={props.compactHeader ? 32 : 64}
          onRowSelectionModelChange={(newRowSelectionModel: any) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          slots={{
            toolbar: () => (
              <EnhancedDataGridToolbar
                rowSelectionModel={rowSelectionModel}
                handleDeleteClick={handleToolbarDeleteClick}
                view={props.view}
                setView={props.setView}
                title={props.compactHeader ? "" : props.title}
                isLoading={props.loading}
                deleting={props.deleting}
              />
            ),
            noRowsOverlay: () => (
              <CustomNoRowsOverlay label={props.noRowsOverlayLabel} />
            ),
          }} // GridToolbar    GridToolbarQuickFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
            // pagination: {
            //   labelRowsPerPage: t("Rows per page"),
            //   labelDisplayedRows(paginationInfo) {
            //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
            //   },
            // },
          }}
          sx={{
            "& .MuiDataGrid-cell": {
              alignContent: "center",
            },
          }}
          density="comfortable"
          onRowDoubleClick={props.handleRowDoubleClick}
          getRowHeight={() => "auto"}
        />
      </Box>
    </Paper>
  );
};

export default EnhancedDataGrid;
