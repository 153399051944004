import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { createTheme, spacing } from "@mui/system";
import {
  useAddVendorKPIMutation,
  useDeleteVendorKPIMutation,
  useUpdateVendorKPIMutation,
} from "../../redux/slices/vendorsApiSlice";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel,
} from "@mui/icons-material";
import { Add as AddIcon } from "@mui/icons-material";
import { IContract } from "../../types/contract";
import { IVendor } from "../../types/vendor";

import { IKPI, IVendorKPI } from "../../types/kpi";
import KPIList from "../../pages/main/kpi/KPIList";
import DataGridKPIs from "../datagrids/DataGridKPIs";
import KPIForm from "../forms/KPIForm";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import {
  useAddLibraryKPIMutation,
  useDeleteLibraryKPIMutation,
  useUpdateLibraryKPIMutation,
} from "../../redux/slices/indexApiSlice";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

type IKPIDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  contract?: IContract;
  vendor?: IVendor;
  kpi?: IKPI;
  refetch: () => void;
  initialValues?: any;
};

function KPIDialog(props: IKPIDialogProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [addKPI, { isSuccess: isAdded, reset: resetAdd }] =
    useAddLibraryKPIMutation();
  const [updateKPI, { isSuccess: isUpdated, reset: resetUpdate }] =
    useUpdateLibraryKPIMutation();
  const [
    deleteKPI,
    { isLoading: isDeleting, isSuccess: isDeleted, reset: resetDelete },
  ] = useDeleteLibraryKPIMutation();

  // const [addContractKPI, { isSuccess: contractKPIAdded, reset: resetContractKPIAdded }] = useAddContractKPIMutation();
  // const [updateContractKPI, { isSuccess: contractKPIUpdated, reset: resetContractKPIUpdated }] = useUpdateContractKPIMutation();
  // const [deleteContractKPI, { isLoading: contractKPIDeleting, isSuccess: contractKPIDeleted, reset: resetContractKPIDeleted }] =
  //   useDeleteContractKPIMutation();

  const [
    addVendorKPI,
    { isSuccess: vendorKPIAdded, reset: resetVendorKPIAdded },
  ] = useAddVendorKPIMutation();
  const [
    updateVendorKPI,
    { isSuccess: vendorKPIUpdated, reset: resetVendorKPIUpdated },
  ] = useUpdateVendorKPIMutation();
  const [
    deleteVendorKPI,
    {
      isLoading: vendorKPIDeleting,
      isSuccess: vendorKPIDeleted,
      reset: resetVendorKPIDeleted,
    },
  ] = useDeleteVendorKPIMutation();

  useEffect(() => {
    if (
      isAdded ||
      isUpdated ||
      isDeleted ||
      vendorKPIAdded ||
      vendorKPIUpdated ||
      vendorKPIDeleted
    ) {
      if (props.refetch) {
        props.refetch();
      }

      if (isAdded || vendorKPIAdded) {
        resetAdd();
        resetVendorKPIAdded();
      }

      if (isUpdated || vendorKPIUpdated) {
        resetUpdate();
        resetVendorKPIUpdated();
      }

      if (isDeleted || vendorKPIDeleted) {
        resetDelete();
        resetVendorKPIDeleted();
      }
    }
  }, [
    isAdded,
    isUpdated,
    isDeleted,
    vendorKPIAdded,
    vendorKPIUpdated,
    vendorKPIDeleted,
  ]);

  const resetAndClose = () => {
    setOpen(false);

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const handleAddKPI = async (kpi: any) => {
    if (kpi.vendorId) {
      await addVendorKPI(kpi);
    } else {
      await addKPI(kpi);
    }

    setSuccess(true);

    // close the dialog
    resetAndClose();
  };

  const handleUpdateKPI = async (kpi: any) => {
    if (kpi.vendorId) {
      await updateVendorKPI(kpi);
    } else {
      await updateKPI(kpi);
    }

    setSuccess(true);
    // close the dialog
    // setOpen(false);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if ((props.kpi as any)?.vendorId) {
      await deleteVendorKPI(props.kpi as IVendorKPI);
    } else {
      if (props.kpi) {
        await deleteKPI(props.kpi.id);
      }
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">Are you sure?</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}

          <Tooltip title="Delete">
            {props.iconOnly ? (
              <IconButton onClick={handleDeleteClick}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <Button color="primary" onClick={() => setOpen(true)}>
                {t("Delete KPI")}
              </Button>
            )}
          </Tooltip>
        </>
      )}

      {mode === DialogMode.Add && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          {t("New KPI")}
        </Button>
      )}

      {mode === DialogMode.Edit &&
        (props.iconOnly ? (
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <Button color="primary" onClick={handleEditClick}>
            {t("Edit KPI")}
          </Button>
        ))}

      <Dialog
        open={open}
        maxWidth={"lg"}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New KPI")
            : `${t("Edit")} ${props.kpi?.name}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mode === DialogMode.Add ? "Add a new KPI." : "Edit the KPI"}
          </DialogContentText>

          <KPIForm
            mode={mode}
            vendor={props.vendor}
            contract={props.contract}
            addKPI={handleAddKPI}
            updateKPI={handleUpdateKPI}
            kpi={props.kpi}
          />
        </DialogContent>
        <DialogActions>
          {success ? (
            <Button onClick={() => resetAndClose()} color="primary">
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button type="submit" form="kpi-form" color="primary">
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button onClick={handleConfirmDelete} color="primary">
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default KPIDialog;
