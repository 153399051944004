import styled from "@emotion/styled";
import { format } from "date-fns";
import { PDFDocument } from "pdf-lib";
import {
  AppBar,
  Box,
  Card,
  Chip as MuiChip,
  CircularProgress,
  Toolbar,
  Typography,
  CardContent,
  Button,
  useScrollTrigger,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import { ReactComponent as Logo } from "../../../../vendor/logo.svg";
import { ISignature, ISignee } from "../../../../types/signature";

import GoBeyondLogo from "../../../../icons/GoBeyondLogo";
import SignButton from "./SignButton";
import SigningReceipt from "./SigningReceipt";
import { IContract } from "../../../../types/contract";
import { useTranslation } from "react-i18next";
const mimeDb = require("mime-db");

// function getFileTypeName(mimeType: string) {
//   const mimeEntry = mimeDb[mimeType];
//   if (mimeEntry && mimeEntry.extensions) {
//     const fileExtension = mimeEntry.extensions[0];
//     return fileExtension.toUpperCase();
//   }
//   return "Unknown";
// }

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 128px;
  height: auto;
  margin-bottom: 24px;
  cursor: pointer;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  // color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const SigningAppBar = ({
  contractDetail,
  signable,
  signer,
  signature,
  handleSignButton,
  handleRejectButton,
  loading,
}: {
  contractDetail: IContract;
  signable: boolean;
  signer?: ISignee;
  signature?: ISignature; // the signature of the current logged in user
  handleSignButton: () => void;
  handleRejectButton: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const navigate = useNavigate();

  const isInternalUser = signature?.tenantId === contractDetail?.tenantId;

  return (
    <>
      <AppBar>
        <Toolbar disableGutters>
          <Grid
            container
            direction="column"
            display="flex"
            justifyContent="center"
            justifySelf="center"
            size={12}
          >
            <Grid justifyContent="center" display="flex" size={12}>
              <Grid
                container
                direction="row"
                maxWidth="lg"
                pt={2}
                alignItems="center"
              >
                <Grid
                  size={{ xs: 12, md: 4 }}
                  justifyContent="start"
                  display="flex"
                >
                  <GoBeyondLogo lightText />
                </Grid>

                <Grid
                  size={{ xs: 12, md: 4 }}
                  justifyContent="center"
                  display="flex"
                >
                  <Typography variant="h6" color="inherit" noWrap>
                    {contractDetail?.name}
                  </Typography>
                </Grid>
                {/* <Box>
            <Chip
              label={contractDetail?.status}
              // color="default"
              variant="outlined"
            />
          </Box> */}

                <Grid
                  size={{ xs: 12, md: 4 }}
                  justifyContent="end"
                  display="flex"
                >
                  <Typography variant="body1">
                    {t("Signing as")} {signer?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="space-between"
              maxWidth="100%"
              pt={2}
              alignItems="center"
              sx={{
                backgroundColor: (props) => props.palette.background.default,
                color: (props) => props.palette.text.primary,
              }}
            >
              {signable && (
                <Box justifyContent="center" display="flex" width="100%">
                  {loading ? (
                    <CircularProgress sx={{ margin: 6 }} />
                  ) : signer?.hasSigned ? (
                    <Grid
                      container
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      mb={3}
                    >
                      <Grid size={{ xs: 12 }}>
                        <SigningReceipt signer={signer} signature={signature} />
                      </Grid>

                      <Grid
                        size={{ xs: 12 }}
                        justifyContent="center"
                        display="flex"
                      >
                        <Button
                          variant="contained"
                          href={
                            isInternalUser
                              ? "/"
                              : "https://www.aicontractmanager.nl"
                          }
                        >
                          {t("Close")}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <SignButton
                      signable={signable}
                      signer={signer}
                      handleSign={handleSignButton}
                      handleReject={handleRejectButton}
                      signature={signature}
                    />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
      </AppBar>
      <Toolbar sx={{ height: signer?.hasSigned ? 350 : 180 }} />
    </>
  );
};

export default SigningAppBar;
