import React, { useEffect } from "react";

import {
  Box,
  Alert,
  AlertTitle,
  LinearProgress,
  CircularProgress,
  IconButton,
  Stack,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Tooltip,
  Popover,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useContract from "../hooks/useContract";
import {
  AIReadingStatus,
  AnalyzedContract,
  IContract,
} from "../types/contract";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { ChevronDown } from "react-feather";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { LinearProgressWithLabel } from "../pages/main/dashboards/Analytics/PromptsSideBar";

function AnalyzingContractsLoader({
  contract,
  variant,
}: {
  contract?: IContract;
  variant?: "circular" | "linear";
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [totalProgress, setTotalProgress] = React.useState(0);

  const { analyzedContracts, isAnalyzing, analyzingNumber, analyzingSuccess } =
    useContract();

  const [analyzingContract, setAnalyzingContract] =
    React.useState<AnalyzedContract>();
  const [analyzingContracts, setAnalyzingContracts] = React.useState<
    AnalyzedContract[]
  >([]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (
      analyzedContracts?.length > 0 ||
      (analyzingNumber && analyzingNumber > 0)
    ) {
      const analyzingContracts = analyzedContracts.filter(
        (c) => c.analyzeStatus === AIReadingStatus.ANALYZING
      );

      setAnalyzingContracts(analyzingContracts);

      if (contract) {
        const newAnalyzingContract = analyzingContracts.find(
          (c) => c.contractId === contract.id
        );

        setAnalyzingContract(newAnalyzingContract);
        setTotalProgress(newAnalyzingContract?.progress || 0);
      } else {
        const totalProgress = analyzingContracts.reduce(
          (acc, contract) => acc + (contract?.progress || 0),
          0
        );

        setTotalProgress(totalProgress / analyzingContracts.length);
      }
    }
  }, [
    analyzedContracts,
    isAnalyzing,
    analyzingNumber,
    analyzingSuccess,
    contract,
  ]);

  const body = (
    <Box>
      {isAnalyzing ? (
        <>
          {contract ? ( // this will be displayed on the contract detail page
            <>
              {analyzingContract && (
                <>
                  <Alert severity="info">
                    <AlertTitle>
                      {t("Reading", {
                        percentage: Math.round(totalProgress * 10) / 10,
                      })}
                    </AlertTitle>
                    {t("Reading document")}
                  </Alert>
                  <LinearProgress variant="determinate" value={totalProgress} />
                </>
              )}
            </>
          ) : (
            <Accordion sx={{ my: 0, marginBottom: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid container direction="column" size={12} gap={6}>
                  {/* {t("Reading", {
                        percentage: Math.round(totalProgress * 10) / 10,
                      })} */}

                  {t("Reading_contracts", {
                    result: analyzingNumber,
                  })}

                  <LinearProgressWithLabel
                    variant="determinate"
                    value={totalProgress}
                  />
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {analyzingContracts.map((contract) => (
                    <ListItem key={contract.contractId}>
                      <ListItemAvatar>
                        <CircularProgressWithLabel
                          variant={
                            !contract.progress ? "indeterminate" : "determinate"
                          }
                          value={contract.progress || 0}
                          size="2rem"
                          color="info"
                        />
                      </ListItemAvatar>
                      <ListItemText primary={contract.name}>
                        <Link
                          onClick={() =>
                            navigate(`/contract/${contract.contractId}`)
                          }
                        >
                          {t("View")}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      ) : analyzingSuccess ? (
        <Alert severity="success">
          <AlertTitle>{t("Success")}</AlertTitle>
          {t("Contracts have been read")}
        </Alert>
      ) : (
        <span />
      )}

      {/* {analyzeError && (
          <Alert severity="error">
            <AlertTitle>{t("An error occurred!")}</AlertTitle>
            {analyzeError?.data && JSON.stringify(analyzeError.data)}
            {!analyzeError?.data && JSON.stringify(analyzeError)}
          </Alert>
        )} */}
    </Box>
  );

  return (
    <>
      {analyzingContracts?.length > 0 && !isNaN(totalProgress) && (
        <>
          {variant === "circular" ? (
            <>
              <IconButton onClick={handleClick}>
                <CircularProgressWithLabel
                  variant="determinate"
                  value={totalProgress}
                  size="2rem"
                />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {body}
              </Popover>
            </>
          ) : (
            body
          )}
        </>
      )}
    </>
  );
}

export default AnalyzingContractsLoader;
