import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DocumentViewer from "./DocumentViewer";
import { IDocument } from "../../types/document";
import { useLazyGetOutlookMessageAttachmentContentQuery } from "../../redux/slices/graphApiSlice";
import PDFViewer from "./PDFViewer";
import { IDriveItem } from "../../types/driveItem";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";

interface IDriveItemViewerProps {
  outlookAttachment: IOutlookMessageAttachment;
  onSuccess?: () => void;
}
function OutlookAttachmentViewer({ ...props }: IDriveItemViewerProps) {
  const { outlookAttachment, onSuccess } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [documentContent, setDocumentContent] = useState<any>();
  const [selectedVersion, setSelectedVersion] = useState<any>(null);

  const [
    getOutlookAttachmentContent,
    {
      data: outlookAttachmentContent,
      isLoading: gettingOutlookAttachmentContent,
    },
  ] = useLazyGetOutlookMessageAttachmentContentQuery({});

  useEffect(() => {
    if (outlookAttachment) {
      getOutlookAttachmentContent(outlookAttachment);
    }
  }, [outlookAttachment]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isDownLg ? "column-reverse" : "row",
      }}
    >
      <Box mr={8}>
        {gettingOutlookAttachmentContent ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <PDFViewer
            {...props}
            arrayBuffer={outlookAttachmentContent}
            //arrayBuffer={documentContent}
            // base64string={outlookAttachment?.attachment?.contentBytes}
          />
        )}
      </Box>
    </Box>
  );
}

export default OutlookAttachmentViewer;
