import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";

import {
  DocumentLoadEvent,
  PageLayout,
  ScrollMode,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
} from "@react-pdf-viewer/full-screen";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";
import {
  pageNavigationPlugin,
  RenderGoToPageProps,
} from "@react-pdf-viewer/page-navigation";
import { printPlugin, RenderPrintProps } from "@react-pdf-viewer/print";
import {
  RenderShowSearchPopoverProps,
  searchPlugin,
} from "@react-pdf-viewer/search";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import {
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import disableScrollPlugin from "./disableScrollPlugin";
import {
  defaultLayoutPlugin,
  SidebarTab,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/default-layout";

import _ from "underscore";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

import type { RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import * as pdfjsLib from "pdfjs-dist";
import { TextItem } from "pdfjs-dist/types/src/display/api";

// ** MUI Imports
import {
  FileDownload,
  FirstPage,
  Fullscreen,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Print as PrintIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  backdropClasses,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Stack,
  styled,
  Toolbar,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme as muiTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { alpha } from "@mui/system";

import { Edit } from "react-feather";
import { useTranslation } from "react-i18next";

import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addFile,
  fileBase64Exists,
  fileExists,
} from "../../redux/slices/filesSlice";
import { RootState } from "../../redux/store";
import { IDocument } from "../../types/document";
import { ISignature, ISignee } from "../../types/signature";

import packageJson from "../../../package.json";
import AIDocumentSignature from "../../pages/main/contracts/components/AIDocumentSignature";
import {
  HighlightAreaExtend,
  ILocationInText,
  IPrompt,
} from "../../types/prompt";
import SearchSidebar from "./SearchSideBar";
import SignatureFields from "./SignatureFields";

import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";
import useReadingIndicatorPlugin from "./readingIndicatorPlugin";
import { border, borderColor, borderRadius } from "polished";
import { useGetPromptsQuery } from "../../redux/slices/indexApiSlice";

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const PromptTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    padding: 8,
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const elevation3 =
  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important";

const SIGN_AREA_WIDTH = 180;
const SIGN_AREA_HEIGHT = 75;

const StyledSignArea = styled(Box)`
  position: relative;
  width: ${SIGN_AREA_WIDTH}px;
  height: ${SIGN_AREA_HEIGHT}px;
  border: 1px dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
const SignIcon = styled(Box)`
  position: absolute;
  top: -10px;
  left: 10px;
`;

const SignatureImg = styled("img")`
  width: 150px;
  height: auto;
  opacity: 1;
  position: absolute;
  zindex: -1;
`;

const ElevatedPaper = styled(Paper)({
  boxShadow: elevation3,
  margin: 4,
});

const HoverBox = styled(Box)`
  // opacity: 0;
  transform: translate(-50%);
  transition: opacity ease-in-out 0.2s;
  z-index: 200;
  // background-color: ${(props) => props.theme.palette.background.default};

  // &:hover {
  //   opacity: 1;
  // }
`;

const SignArea: React.FC<{
  signer?: ISignee;
  signature: ISignature;
  signable?: boolean;
  onOpenSignaturePad: (email: string) => void;
  onMoveToFirstPage: (email: string) => void;
  onMoveToPrevPage: (email: string) => void;
  onMoveToNextPage: (email: string) => void;
  onMoveToLastPage: (email: string) => void;
  numPages: number;
}> = ({
  signer,
  signature: sig,
  signable,
  onOpenSignaturePad,
  onMoveToFirstPage,
  onMoveToNextPage,
  onMoveToPrevPage,
  onMoveToLastPage,
  numPages,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <StyledSignArea
        sx={{
          borderColor: sig.email === signer?.email ? "#22A121" : "#FDA018",
          pointerEvents:
            sig.email !== signer?.email && signable ? "none" : "all",
          color: "black",
          cursor:
            sig.draggable && !sig.isSigned
              ? "grab"
              : signable && sig.email === signer?.email && !sig.signedDate
              ? "pointer"
              : "default",
          "&:active": {
            cursor: sig.draggable && !sig.isSigned ? "grab" : "default",
          },
          "&:hover": {
            backgroundColor:
              sig.email === signer?.email ? grey[100] : "initial",
          },
        }}
        onClick={() =>
          signable && sig.email === signer?.email && !sig.signedDate
            ? onOpenSignaturePad(sig.email)
            : null
        }
      >
        <SignIcon
          sx={{
            color: sig.email === signer?.email ? "#22A121" : "#FDA018",
          }}
        >
          <Edit />
        </SignIcon>
        <Typography>{t("Sign for")}</Typography>
        <Typography fontWeight="bold">{sig.displayName}</Typography>
        {signable && sig.email === signer?.email && !sig.signedDate && (
          <Typography variant="caption">({t("click here")})</Typography>
        )}
      </StyledSignArea>
      {sig.draggable && !sig.isSigned && numPages > 1 && (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: -1.5 }}>
          <Tooltip title={t("Move to first page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToFirstPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <FirstPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to previous page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToPrevPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateBefore sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to next page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToNextPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateNext sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to last page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToLastPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <LastPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export interface IReactPDFViewerProps {
  file?: File;
  fileUrl?: string;
  document?: IDocument;
  arrayBuffer?: ArrayBuffer;
  defaultScale?: number;
  viewMode?: "withMenu" | "pageOnly";
  signable?: boolean;
  signer?: ISignee;
  signatures?: Array<ISignature>;
  onSaveSignature?: () => any;
  setSignatures?: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  setSignatureSizePercentValue?: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  toggleSignaturePad?: boolean;
  onSuccess?: () => void;
  paging?: boolean;
  locationInText?: ILocationInText[];
  prompt?: IPrompt | null;
  setHighlightAreaExtend?: React.Dispatch<
    React.SetStateAction<HighlightAreaExtend | undefined>
  >;
}
function ReactPDFViewer({ ...props }: IReactPDFViewerProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const colors = muiTheme();

  const { contractId } = useParams();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [paperHovered, setPaperHovered] = useState(false);
  const [ready, setReady] = useState(false);
  const [turningPage, setTurningPage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileContent, setFileContent] = useState<Uint8Array | string>(
    new Uint8Array([])
  );

  const [pdfPageHeight, setPdfPageHeight] = useState<number>(0);

  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  // check if the file is already in the store
  const fileContentExists = useSelector<RootState, boolean | undefined>(
    (state) =>
      props.document ? fileExists(props.document.id || "")(state) : undefined
  );

  const fileBuffer = useSelector<RootState, ArrayBuffer>(
    (state) => state.files.files[props.document?.id || ""] || "",
    _.isEqual
  );

  // const memoizedFileBuffer = useMemo(() => fileBuffer, [fileBuffer]);

  useEffect(() => {
    if (props.document?.id && !props.file) {
      // the id changed, reset the file content
      //   setFileContent(undefined);
      // setReady(false);
    }
  }, [props.document?.id]);

  useEffect(() => {
    if (props.document?.id && !props.file && !props.fileUrl && fileBuffer) {
      if ((fileBuffer as any)?.data) {
        setFileContent(new Uint8Array((fileBuffer as any).data));
      }
    } else {
      if (props.file) {
        let url = URL.createObjectURL(props.file);
        setFileContent(url);
      } else if (props.fileUrl) {
        setFileContent(props.fileUrl);
      }
    }
  }, [
    contractId,
    props.file,
    props.document?.id,
    fileContentExists,
    props.fileUrl,
  ]);

  // useEffect(() => {fileContentfileContent
  //   window.scrollTo(0, scrollPosition);

  //   setTurningPage(true);
  //   setTimeout(() => {
  //     setTurningPage(false);
  //   }, 1500);
  // }, [pageNumber]);

  const onDocumentLoadSuccess = (args: DocumentLoadEvent) => {
    console.log({ numPages: args?.doc?.numPages });
    setNumPages(args?.doc?.numPages);

    if (props.onSuccess) {
      props.onSuccess();
    }

    setReady(true);
    // setDocumentLoaded(true);
    // setPageNumber(pageNumber);
  };

  // signature----------------------------------------------------------------------------------
  const {
    signer,
    signable,
    signatures,
    setSignatures,
    setSignatureSizePercentValue,
    onSaveSignature,
    toggleSignaturePad,
    setHighlightAreaExtend,
  } = props;
  const [showSignaturePad, setSignaturePad] = useState(false);
  const [documentSize, setDocumentSize] = useState({ width: 0, height: 0 });
  const documentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof toggleSignaturePad === "boolean") {
      setSignaturePad((prev) => !prev);
    }
  }, [toggleSignaturePad]);
  useEffect(() => {
    if (documentRef.current) {
      setDocumentSize({
        width: documentRef.current.clientWidth,
        height: documentRef.current.clientHeight,
      });
    }
  }, [
    documentRef,
    documentRef.current,
    documentRef.current?.clientWidth,
    documentRef.current?.clientHeight,
  ]);

  const handleOpenSignaturePad = (email: string) => {
    setSignaturePad(true);
  };
  const handleCloseSignaturePad = () => {
    setSignaturePad(false);
  };

  const handleSave = (signatureUrl: string) => {
    if (setSignatures) {
      setSignatures((prev) => {
        const newSignatures = prev.map((sig) => {
          if (sig.email === signer?.email) {
            return {
              ...sig,
              isApproved: true,
              signatureUrl,
              signedDate: new Date().toISOString(),
            };
          } else {
            return { ...sig };
          }
        });

        return newSignatures;
      });
    }

    if (onSaveSignature) {
      onSaveSignature();
    }

    handleCloseSignaturePad();
  };

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file) => `${props.document?.name}.pdf`,
  });

  const { Download } = getFilePluginInstance;

  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut, ZoomPopover } = zoomPluginInstance;

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const {
    CurrentPageInput,
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
  } = pageNavigationPluginInstance;

  const searchPluginInstance = searchPlugin({
    // Highlight "" words initially
    keyword: [
      "",
      {
        keyword: "",
        matchCase: true,
      },
    ],
  });
  const { ShowSearchPopover } = searchPluginInstance;

  const disableScrollPluginInstance = disableScrollPlugin();

  const pageLayout: PageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + 30,
      width: size.width + 30,
    }),
  };

  // Highlights Blocks
  const [highlightAreas, setHighlightAreas] = useState<HighlightAreaExtend[]>(
    []
  );

  const findTextItem = (
    textItems: TextItem[],
    words: string
  ): TextItem | undefined => {
    // find the element in the textItems array
    let foundItem: TextItem | undefined = undefined;

    const pageContent = textItems.map((item) => item.str || "").join("");

    if (pageContent.includes(words)) {
      const textItem = textItems.find((item) => item.str.includes(words));
      // return textItem;

      // build an array with every character and text item index
      const textItemsWithIndex = textItems.reduce((acc: any, item, index) => {
        return [...acc, ...item.str.split("").map((char) => ({ char, index }))];
      }, []);

      // find the characters of the words
      const wordsChars = words.split("");

      textItemsWithIndex.forEach((item: any, i: number) => {
        if (foundItem) {
          return;
        }

        // check if the first character of the words is in the text
        if (item.char === wordsChars[0]) {
          // check if the next characters are in the text
          const found = wordsChars.every((char, j) => {
            return textItemsWithIndex[i + j]?.char === char;
          });

          if (found) {
            const textItem = textItems[textItemsWithIndex[i].index];

            foundItem = textItem;
          }
        }
      });
    }

    if (foundItem) {
      return foundItem;
    }

    const textItem = textItems.find((item, i) => {
      // check if the first two words are in the text
      if (item.str.includes(words)) {
        return item;
      }

      const parts = words.split(" ");

      // one word can also be in the previous item
      if (i > 0) {
        const prevItem = textItems[i - 1];
        if (prevItem.str.includes(parts[0]) && item.str.includes(parts[1])) {
          return prevItem;
        }
      }

      // one word can also be in the next item
      if (i < textItems.length - 1) {
        const nextItem = textItems[i + 1];
        if (item.str.includes(parts[0]) && nextItem.str.includes(parts[1])) {
          return nextItem;
        }
      }

      const parts2 = words.split(".");

      // one word can also be in the previous item
      if (i > 0) {
        const prevItem = textItems[i - 1];
        if (prevItem.str.includes(parts2[0]) && item.str.includes(parts2[1])) {
          return prevItem;
        }
      }

      // one word can also be in the next item
      if (i < textItems.length - 1) {
        const nextItem = textItems[i + 1];
        if (item.str.includes(parts2[0]) && nextItem.str.includes(parts2[1])) {
          return nextItem;
        }
      }

      return null;
    });

    return textItem;
  };

  const getPositionFromTransform = (
    textItem: TextItem
  ): {
    x: number;
    y: number;
  } => {
    const { transform } = textItem;
    if (!transform || transform.length !== 6) {
      throw new Error("Invalid transform format");
    }

    const [a, b, c, d, tx, ty] = transform;
    return { x: tx, y: ty };
  };

  const getArea = (
    itemStart: TextItem,
    itemEnd: TextItem,
    viewPortWidth: number,
    viewPortHeight: number,
    index: number,
    localText: ILocationInText
  ) => {
    const { x: x1, y: y1 } = getPositionFromTransform(itemStart);
    const { x: x2, y: y2 } = getPositionFromTransform(itemEnd);
    let data = {
      pageIndex: index - 1,
      top:
        ((viewPortHeight - y1 - Math.max(itemStart.height, itemEnd.height)) /
          viewPortHeight) *
        100,
      left: 0,
      height:
        ((Math.abs(y2 - y1) + Math.max(itemStart.height, itemEnd.height)) /
          viewPortHeight) *
        100,
      width: 100,
      key: localText.key,
    };

    // data.top -= 1;
    data.height += 2; // add a little margin

    return data;
  };

  const fetchTextAreas = useCallback(async () => {
    if (
      !fileContent ||
      !fileContent.length ||
      !fileBuffer ||
      !props.locationInText?.length
    ) {
      return;
    }

    const pdf = await pdfjsLib.getDocument(fileContent || fileBuffer).promise;
    const numPages = pdf.numPages;
    const areas: HighlightAreaExtend[] = [];

    const textItemsAllPages: TextItem[][] = [];

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();

      // const elements = document.querySelectorAll(
      //   ".rpv-core__text-layer-text"
      // );

      const textItemsCurrentPage = textContent.items.filter(
        (item) => "str" in item
      ) as TextItem[];

      textItemsAllPages.push(textItemsCurrentPage);
    }

    for (const localText of props.locationInText) {
      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const viewPort = page.getViewport({ scale: 1 });
        const textItems = textContent.items.filter(
          (item) => "str" in item
        ) as TextItem[];

        const firstTwoWords = localText.text?.split(" ").slice(0, 2).join(" ");
        const lastTwoWords = localText.text?.split(" ").slice(-2).join(" ");

        // find the element in the textItems array
        const startItem = findTextItem(textItems, firstTwoWords);
        let endItem = findTextItem(textItems, lastTwoWords);

        if (startItem && !endItem) {
          // check if the endItem is in the next page
          if (i < numPages) {
            // we are not on the last page
            const textItemsNextPage = textItemsAllPages[i]; // textItemAllPages starts from 0
            if (textItemsNextPage) {
              const endItemNextPage = findTextItem(
                textItemsNextPage,
                lastTwoWords
              );

              if (endItemNextPage) {
                // draw an area on the current page
                areas.push(
                  getArea(
                    startItem,
                    endItemNextPage,
                    viewPort.width,
                    viewPort.height,
                    i,
                    localText
                  )
                );

                // draw an area on the next page
                const nextPageNum = i + 1;
                const nextPage = await pdf.getPage(nextPageNum);
                const viewPortNextPage = nextPage.getViewport({ scale: 1 });

                // get the element that is top of the
                // find the element with the highest y value
                const topItemNextPage = textItemsNextPage.reduce(
                  (prev, current) =>
                    prev.transform[5] > current.transform[5] ? prev : current
                );

                areas.push(
                  getArea(
                    topItemNextPage,
                    endItemNextPage,
                    viewPortNextPage.width,
                    viewPortNextPage.height,
                    nextPageNum,
                    localText
                  )
                );
              } else {
                // no end item found, so only mark the start item
                areas.push(
                  getArea(
                    startItem,
                    startItem,
                    viewPort.width,
                    viewPort.height,
                    i,
                    localText
                  )
                );
              }
            }
          }
        }

        if (endItem && startItem) {
          areas.push(
            getArea(
              startItem,
              endItem,
              viewPort.width,
              viewPort.height,
              i,
              localText
            )
          );
          break;
        }
      }
    }

    setHighlightAreas(areas);
  }, [fileContent, ready]);

  useEffect(() => {
    if (props.locationInText && (fileContent || fileBuffer)) {
      fetchTextAreas();
    }
  }, [fileContent, fileBuffer, props.locationInText]);

  useEffect(() => {
    if (props.prompt) {
      const elements = document.querySelectorAll(
        '[id^="highlight-ref-' + props.prompt?.key + '"]'
      );
      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [props.prompt]);

  // Handle clicked highlight
  const handleClick = (area: HighlightAreaExtend) => {
    if (setHighlightAreaExtend) {
      setHighlightAreaExtend(area);
      setTimeout(() => {
        let panelElement = window.document.getElementById(
          `panel-${area.key}-block`
        );
        panelElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  };

  const renderHighlights = (renderHighlightsProps: RenderHighlightsProps) => (
    <div>
      {highlightAreas
        .filter((area) => area.pageIndex === renderHighlightsProps.pageIndex)
        .map((area, idx) => {
          const prompt = prompts?.find((p) => p.key === area.key);

          return (
            <PromptTooltip
              title={prompt?.category ? t(prompt.category) : area.key}
              placement="left-start"
              arrow
              // open
              color="primary"
            >
              <div
                key={idx}
                id={"highlight-ref-" + area.key + "-[" + idx + "]"}
                className="highlight-area"
                onClick={() => handleClick(area)}
                style={Object.assign(
                  {},
                  renderHighlightsProps.getCssProperties(
                    area,
                    renderHighlightsProps.rotation
                  ),
                  {
                    background: colors.palette.primary.main,
                    border: "2px solid",
                    borderColor: colors.palette.primary.main,
                    borderRadius: 12,
                    opacity: props.prompt?.key == area.key ? 0.8 : 0.2,
                    zIndex: 999999,
                    cursor: "pointer",
                    marginTop: -6,
                    padding: 15,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 30,
                  }
                )}
              />
            </PromptTooltip>
          );
        })}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.TextSelection,
  });

  const readingIndicatorPluginInstance = useReadingIndicatorPlugin();
  const { ReadingIndicator } = readingIndicatorPluginInstance;

  const renderToolbar = React.useCallback(
    (Toolbar: (toolbarProps: ToolbarProps) => React.ReactElement) => (
      <>
        <Toolbar />
        <div
          style={{
            bottom: "-0.25rem",
            position: "absolute",
            left: 0,
            // Take the full width of toolbar
            width: "100%",
          }}
        >
          <ReadingIndicator />
        </div>
      </>
    ),
    []
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    setInitialTab: (doc) => Promise.resolve(0),
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],

    // sidebarTabs: (defaultTabs: SidebarTab[]) => {
    //   const newSiteBarTab: SidebarTab = {
    //     icon: <FileDownload />,
    //     title: t("Download"),
    //     content: <div>{props.AISideBar}</div>,
    //   };

    //   return [...defaultTabs, newSiteBarTab];
    // },
  });

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
    >
      <div
        className="rpv-core__viewer"
        style={{
          // border: "1px solid rgba(0, 0, 0, 0.3)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          id="canvas-signature-box"
          style={{
            height: 900,
            position: "relative",
            // width: "auto !important",
            width: "100%",
          }}
          // className="[&_.rpv-core__page-layer]:w-full"
          ref={documentRef}
          sx={{
            "& .rpv-core__inner-page": {
              // backgroundColor: "transparent",
            },
            "& .rpv-core__inner-container": {
              // height: "50vh",
            },
            // set a variable
            "--rpv-default-layout__toolbar-background-color": (props) =>
              props.palette.background.default,
            "--rpv-default-layout__sidebar-headers-background-color": (props) =>
              props.palette.background.default,
          }}
        >
          {ready && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 3,
                inset: 0,
                pointerEvents: "none",
              }}
            >
              <SignatureFields
                signatures={signatures}
                documentRef={documentRef}
                documentSize={documentSize}
                handleOpenSignaturePad={handleOpenSignaturePad}
                numPages={numPages}
                setSignatures={setSignatures}
                signable={signable}
                signer={signer}
                onSaveSignature={onSaveSignature}
                setSignatureSizePercentValue={setSignatureSizePercentValue}
              />
            </Box>
          )}

          <Viewer
            fileUrl={fileContent}
            theme={theme === THEMES.DARK ? "dark" : "light"}
            plugins={[
              defaultLayoutPluginInstance,
              readingIndicatorPluginInstance,
              // getFilePluginInstance,
              // fullScreenPluginInstance,
              // pageNavigationPluginInstance,
              // printPluginInstance,
              // searchPluginInstance,
              highlightPluginInstance,
              // thumbnailPluginInstance,
            ]}
            defaultScale={SpecialZoomLevel.PageFit}
            pageLayout={pageLayout}
            enableSmoothScroll={true}
            // renderLoader={() => <CircularProgress />}
            renderLoader={(percentages: number) => (
              <div style={{ width: "240px" }}>
                <LinearProgress
                  variant="determinate"
                  value={Math.round(percentages)}
                />
              </div>
            )}
            scrollMode={ScrollMode.Vertical}
            onDocumentLoad={onDocumentLoadSuccess}

            // renderLoader={(percentages: number) => (
            //   <div style={{ width: "240px" }}>
            //   <ProgressBar progress={Math.round(percentages)} />
            //     <LinearProgress
            //       variant="determinate"
            //       value={Math.round(percentages)}
            //     />
            //   </div>
            // )}
          />
        </Box>

        <Dialog onClose={handleCloseSignaturePad} open={showSignaturePad}>
          <AIDocumentSignature
            onSign={handleSave}
            onCancel={handleCloseSignaturePad}
          />
        </Dialog>
      </div>
    </Worker>
  );
}

export default ReactPDFViewer;
