import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import useAppSelector from "../hooks/useAppSelector";
import { IDocument } from "../types/document";

const initialState: {
  botName: string;
  open: boolean;
  setOpen: (isOpen: boolean) => any;
  addedMessage: string;
  setAddedMessage: (message: string) => any;
} = {
  botName: "AI Assistent",
  open: false,
  setOpen: (isOpen: boolean) => {},
  addedMessage: "",
  setAddedMessage: (message: string) => {},
};
const BotChatContext = React.createContext(initialState);

type SetRequestedDocumentAnalysisType = {
  document: IDocument;
  analyze?: boolean;
  reAnalyze?: boolean;
};

type BotChatProviderProps = {
  children: React.ReactNode;
};

function BotChatProvider({ children }: BotChatProviderProps) {
  const { user, isAuthenticated } = useAuth();
  const tenantId = useAppSelector((state) => state.auth.tenantId);

  const [open, _setOpen] = React.useState<boolean>(initialState.open);
  const [addedMessage, _setAddedMessage] = useState<string>(
    initialState.addedMessage
  );

  const setOpen = (isOpen: boolean) => {
    _setOpen(isOpen);
  };

  const setAddedMessage = (message: string) => {
    _setOpen(true);
    if (!!message) {
      _setAddedMessage(message);
    } else {
      _setAddedMessage("");
    }
  };

  return (
    <BotChatContext.Provider
      value={{
        botName: initialState.botName,
        open,
        setOpen,
        addedMessage,
        setAddedMessage,
      }}
    >
      {children}
    </BotChatContext.Provider>
  );
}

export { BotChatContext, BotChatProvider };
