// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";
import { IAIResponse } from "../../types/airesponse";
import mockAIResponse from "./aiSlice-response";
import { IDocument } from "../../types/document";
import { IQuestion } from "../../types/question";
import { isValidDate } from "../../utils/string";
import { IVendor } from "../../types/vendor";
import { ICompanyProfileKVK } from "../../../../server/types/companyprofilekvk";
import { KVKSearchResponse, KVKSearchResponseItem } from "../../types/zoeken";

export const KVKApi: any = createApi({
  reducerPath: "KVKApi",

  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const accessToken = getState().auth.accessToken; // Retrieve the access token

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      } else {
        throw new Error("No access token available");
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    syncVendorWithKVK: builder.mutation<IVendor, IVendor>({
      query: ({ id }) => ({
        url: `/vendors/${id}/integrations/kvk/sync`,
        method: "PUT",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    searchCompany: builder.query<KVKSearchResponse, string>({
      query: (query) => ({
        url: `/integrations/kvk/search${query}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        const results: KVKSearchResponse = response.data;

        // const t: KVKSearchResponseItem;

        // return the results with a unique Id, since kvk number is not unique

        return {
          ...results,
          resultaten: results.resultaten?.map(
            (item: KVKSearchResponseItem, index: number) => {
              return {
                ...item,
                id: `${item.kvkNummer}-${item.vestigingsnummer}`,
              };
            }
          ),
        };
      },
    }),
  }),
});

export const { useSyncVendorWithKVKMutation, useLazySearchCompanyQuery } =
  KVKApi;
