import React, { useState } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";

// import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
// import { AuthProvider as MSALAuthProvider } from "./contexts/MSALContext";
import { useTranslation } from "react-i18next";
import { AuthProvider } from "./contexts/MainAuthContext";
import { BotChatProvider } from "./contexts/BotChatContext";
import { ContractProvider } from "./contexts/ContractContext";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  const { i18n } = useTranslation();
  initializeFileTypeIcons(/* optional base url */);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Go Beyond Procurement"
          defaultTitle="Go Beyond Procurement - Contract Management Application"
        />
        <Provider store={store}>
          {/* @ts-ignore */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme, i18n.language as any)}>
              <AuthProvider>
                <BotChatProvider>
                  <ContractProvider>{content}</ContractProvider>
                </BotChatProvider>
              </AuthProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
