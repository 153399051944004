import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Eye,
  Facebook,
  Home,
  Instagram,
  MapPin,
  PhoneCall,
  ShoppingBag,
  Twitter,
} from "react-feather";
import {
  Add as AddIcon,
  NoteAdd as NoteAddIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  EditNote as EditNoteIcon,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  Edit as EditIcon,
  Feed,
  Phone,
  ChevronLeft,
  ChevronRight,
  Mail,
  CrisisAlert,
  Fastfood,
  Event,
  CheckBox,
  NewReleases,
  Verified,
  Eject as EjectIcon,
  Launch as LaunchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Style,
  AutoFixHigh,
  MoreHoriz as MoreHorizIcon,
  HistoryToggleOff,
  History,
  Task,
  Download as DownloadIcon,
  AutoFixOff,
  Remove,
  Cancel,
  Close,
  CancelOutlined,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  Fade,
  CardMedia as MuiCardMedia,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Icon,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Tab,
  Tabs,
  AvatarGroup,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Popper,
  ClickAwayListener,
  Grow,
  StepContent,
  useMediaQuery,
  Theme,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
} from "@mui/material";

import { fontWeight, spacing, SpacingProps } from "@mui/system";

import { useDeleteDocumentMutation } from "../../../redux/slices/indexApiSlice";
import { IDocument } from "../../../types/document";

import FileIcon from "../../../icons/FileIcon";

import { useTranslation } from "react-i18next";

import { saveAs } from "file-saver";
import { formatBytes } from "../../../utils/string";
import {
  useGetDriveItemPreviewMutation,
  useLazyGetOutlookMessageAttachmentContentQuery,
  useLazyGetOutlookMessageAttachmentQuery,
} from "../../../redux/slices/graphApiSlice";
import { DialogMode } from "../../../types/dialogmode";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.8;
  text-transform: uppercase;
  display: block;
`;

type IFileAsListItemProps = {
  file: File | IDocument;
  onClick: () => void;
  onDelete?: () => void;
  uploading?: boolean;
  uploaded?: boolean;
  uploadPercentProgress?: number;
};

const FileAsListItem = (props: IFileAsListItemProps) => {
  const { t } = useTranslation();

  const {
    file,
    onClick,
    onDelete,
    uploading,
    uploaded,
    uploadPercentProgress,
  } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const [getDriveItemPreview, { data: driveItemPreview }] =
    useGetDriveItemPreviewMutation();

  const [getOutlookAttachment, { data: outlookAttachment }] =
    useLazyGetOutlookMessageAttachmentQuery();

  const [getOutlookAttachmentContent, { data: outlookAttachmentContent }] =
    useLazyGetOutlookMessageAttachmentContentQuery();

  const handleDeleteClick = () => {
    if (onDelete) {
      setIsDeleting(true);
      onDelete();
    }
  };

  const viewFile = async () => {
    if (file instanceof File) {
      // open the file content in a new window
      const fileURL = URL.createObjectURL(file);

      // open in a popup without any action and menu items
      window.open(fileURL, "_blank", "toolbar=no,location=no,menubar=no");
    } else {
      if (file.origin === "outlook") {
        // const res = await getOutlookAttachment(file);
        // const attachment = res.data;

        // const binaryString = window.atob(attachment.contentBytes);
        // const len = binaryString.length;
        // const bytes = new Uint8Array(len);
        // for (let i = 0; i < len; i++) {
        //   bytes[i] = binaryString.charCodeAt(i);
        // }
        // const blob = new Blob([bytes]);
        // const outlookFile = new File([blob], attachment.name, {
        //   type: attachment.contentType,
        // });

        // const contentUrl = URL.createObjectURL(outlookFile);
        // window.open(contentUrl, "_blank", "toolbar=no,location=no,menubar=no");

        const payload = await getOutlookAttachmentContent(file).unwrap();

        if (payload) {
          // open in a popup without any action and menu items
          window.open(payload, "_blank", "toolbar=no,location=no,menubar=no");
        }
      } else {
        const payload = await getDriveItemPreview(file).unwrap();
        if (payload) {
          // open in a popup without any action and menu items
          window.open(payload, "_blank", "toolbar=no,location=no,menubar=no");
        }
      }
    }
  };

  return (
    <>
      <ListItemButton
        onClick={onClick}
        color="secondary"
        sx={{ paddingRight: `${48 * 3}px` }}
        disabled={isDeleting}
      >
        {/* 48px for each button */}
        <ListItemIcon>
          <FileIcon filename={file?.name} />
        </ListItemIcon>
        <ListItemText>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={file?.name}
          >
            {file?.name}
          </Typography>

          {file?.size && <Typography>{formatBytes(file.size)}</Typography>}

          {(uploading || uploaded) && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={uploadPercentProgress}
                  color={uploadPercentProgress === 100 ? "success" : "primary"}
                />
              </Box>
              <Box
                sx={{
                  minWidth: 35,
                  padding: 0,
                  height: 28,
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {uploadPercentProgress === 100 ? (
                  <Check color="success" />
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      marginLeft: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >{`${Math.round(uploadPercentProgress!)}%`}</Typography>
                )}
              </Box>
            </Box>
          )}
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => viewFile()}
            title={t("Open")!}
            color="info"
            size="small"
          >
            <Eye />
          </IconButton>

          {file instanceof File && (
            <IconButton
              onClick={() => saveAs(file, file.name)}
              title={t("Download")!}
              color="info"
              size="small"
            >
              <DownloadIcon />
            </IconButton>
          )}

          {onDelete && (uploaded || uploadPercentProgress === 0) && (
            <IconButton
              onClick={() => handleDeleteClick()}
              title={t("Delete")!}
              color="info"
              size="small"
            >
              {/* <DeleteIcon /> */}
              <CancelOutlined />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItemButton>
    </>
  );
};

export default FileAsListItem;
