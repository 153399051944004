import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Tooltip,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  useAddConnectedDriveMutation,
  useDeleteConnectedDriveMutation,
} from "../../redux/slices/indexApiSlice";
import {
  AutoFixHigh,
  Delete,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  Cancel,
  PowerOff,
} from "@mui/icons-material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check, Power, Slash } from "react-feather";

import useAuth from "../../hooks/useAuth";

import { ISharePointConnection } from "../../types/sharepointconnection";
import SharePointConnectionForm from "../forms/SharePointConnectionForm";
import { IConnectedDrive } from "../../types/connecteddrive";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().optional(),
  endDate: Yup.date().optional(),
  value: Yup.number().optional(),
});

type IConnectedSharePointDriveDialogProps = {
  mode: DialogMode;
  newAIinfo?: boolean;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  connectedDrive?: IConnectedDrive;
  refetch: () => void;
  initialValues?: any;
  asMenuItem?: boolean; // renders the button as a menu item
};

function ConnectedSharePointDriveDialog(
  props: IConnectedSharePointDriveDialogProps
) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [site, setSite] = React.useState("");

  const [
    addConnectedDrive,
    { data: addedConnection, isSuccess: isAdded, reset: resetAdd },
  ] = useAddConnectedDriveMutation();
  // const [updateConnection, { isSuccess: isUpdated, reset: resetUpdate }] = useUpdateContractMutation();
  const [
    deleteConnectedDrive,
    { isLoading: isDeleting, isSuccess: isDeleted, reset: resetDelete },
  ] = useDeleteConnectedDriveMutation();

  const { user } = useAuth();

  const navigate = useNavigate();

  // const handleSiteChange = (event: SelectChangeEvent) => {
  //   setSite(event.target.value as string);
  // };

  // const handleDriveChange = (event: SelectChangeEvent) => {
  //   setDrive(event.target.value as string);
  // };

  const resetAndClose = () => {
    setOpen(false);

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    if (isDeleted) {
      // redirect to the connections page
      navigate("/admin/connected-drives", {
        state: { reload: true },
      });

      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (isAdded || isDeleted) {
      // || isUpdated
      setSuccess(true);

      if (props.refetch) {
        // re-get the message items if the add or delete mutation was successful
        props.refetch();
      }

      if (isAdded) {
        resetAdd();

        // // log activity
        // const activity: IActivity = {
        //   id: "",
        //   date: new Date().toISOString(),
        //   entity: "contract",
        //   contractId: addedContract.id,
        //   vendorId: addedContract.vendorId,
        //   event: "added",
        //   user: user?.id,
        // };

        // addActivity(activity);
      }

      // if (isUpdated) {
      //   resetUpdate();

      //   // // log activity
      //   // const activity: IActivity = {
      //   //   id: "",
      //   //   date: new Date().toISOString(),
      //   //   entity: "contract",
      //   //   contractId: props.contract ? props.contract.id : "",
      //   //   vendorId: props.contract ? props.contract.vendorId : "",
      //   //   event: "updated",
      //   //   user: user?.id,
      //   // };

      //   // addActivity(activity);
      // }

      if (isDeleted) {
        resetDelete();

        // // log activity
        // const activity: IActivity = {
        //   id: "",
        //   date: new Date().toISOString(),
        //   entity: "contract",
        //   contractId: props.connection ? props.contract.id : "",
        //   vendorId: props.contract ? props.contract.vendorId : "",
        //   event: "deleted",
        //   user: user?.id,
        // };

        // addActivity(activity);
      }
    }
  }, [isAdded, isDeleted]); // isUpdated

  const handleAddConnection = async (connection: ISharePointConnection) => {
    // const connection = {
    //   siteId: site,
    //   driveId: drive,
    //   webUrl: sites?.find((s: any) => s.id === site)?.webUrl,
    //   siteDisplayName: sites?.find((s: any) => s.id === site)?.displayName,
    //   driveName: drives?.find((d: any) => d.id === drive)?.name,
    // };

    await addConnectedDrive(connection as IConnectedDrive);

    setSuccess(true);

    // close the dialog
    resetAndClose();
  };

  // const handleUpdateContract = async (contract: IContract) => {
  //   await updateConnection(contract);

  //   setSuccess(true);
  //   // close the dialog
  //   // setOpen(false);
  // };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.connectedDrive?.id) {
      await deleteConnectedDrive(props.connectedDrive.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <PowerOff />
                </IconButton>
              ) : props.asMenuItem ? (
                <MenuItem onClick={() => setOpen(true)}>
                  <PowerOff />
                  {t("Disconnect")}
                </MenuItem>
              ) : (
                <Button color="primary" onClick={() => setOpen(true)}>
                  {t("Disconnect")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {mode === DialogMode.Add && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          {t("New connected drive")}
        </Button>
      )}

      {mode === DialogMode.Edit &&
        (props.iconOnly ? (
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <Button color="primary" onClick={handleEditClick}>
            {t("Edit connected drive")}
          </Button>
        ))}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        // maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New connected drive")
            : mode === DialogMode.Edit
            ? `${t("Edit")} ${props.connectedDrive?.driveName}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this connected drive?")
            : ""}
        </DialogTitle>
        {mode !== DialogMode.Delete && (
          <DialogContent>
            {/* <DialogContentText>{mode === "add" ? "Add a new contract." : "Edit the contract"}</DialogContentText> */}

            <SharePointConnectionForm
              mode={mode}
              addConnection={handleAddConnection}
              updateConnection={handleAddConnection}
            />
            {/* <ContractForm
              mode={mode}
              addContract={handleAddContract}
              updateContract={handleUpdateContract}
              contract={props.contract}
              initialValues={props.initialValues}
            /> */}
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button onClick={() => resetAndClose()} color="primary">
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="sharepoint-connection-form"
                  variant="contained"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Disconnect")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConnectedSharePointDriveDialog;
