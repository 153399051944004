import { AddCircleOutline } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider as MuiDivider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { ISignature, ISignee } from "../../../../types/signature";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { User, Users } from "react-feather";
import styled from "@emotion/styled";

import { spacing } from "@mui/system";

import { darken } from "polished";
import { red } from "@mui/material/colors";

const Divider = styled(MuiDivider)(spacing);

const Signee = styled(Grid)`
  width: 100%;
  padding: 4px;
  align-items: center;

  // background: ${(props) =>
    darken(0.03, props.theme.palette.background.default)};

  &:hover {
    // cursor: pointer;
    // background: ${(props) =>
      darken(0.03, props.theme.palette.background.default)};
  }
`;

const SigneeIcon = styled(User)`
  margin-right: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const SigneesIcon = styled(Users)`
  margin-right: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function SigneesTable({
  signees,
  signable,
  readOnly,
  onRemoveSignee,
  setShowSigneesDialog,
  disabled,
}: {
  signees: ISignee[];
  signable: boolean;
  readOnly: boolean;
  onRemoveSignee: (signee: ISignee) => void;
  setShowSigneesDialog: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) {
  const { t } = useTranslation();

  const isDownLg = useMediaQuery(useTheme().breakpoints.down("lg"));
  return (
    <Box width="100%" mt={2}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        {!signable && !readOnly && (
          <Box>
            <Button
              variant="text"
              sx={{
                alignSelf: isDownLg ? "flex-start" : "flex-end",
              }}
              onClick={() => setShowSigneesDialog(true)}
            >
              <AddCircleOutline sx={{ mr: 2 }} /> {t("Add_Signatories")}
            </Button>
          </Box>
        )}

        {signees?.length > 0 && (
          <Typography
            variant="body1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {signees?.length === 1 ? <SigneeIcon /> : <SigneesIcon />}
            {signees.length}{" "}
            {signees.length === 1 ? t("recipient") : t("recipients")}
          </Typography>
        )}
      </Box>

      {signees?.map((signee) => (
        <Signee
          container
          key={signee.email}
          // onClick={() => handleAddSignature(signee.email)}
        >
          <Grid size={2}>
            <Avatar src="" />
          </Grid>
          <Grid size={6} overflow="hidden">
            <Typography
              variant="body1"
              fontWeight="bold"
              noWrap
              display="block"
            >
              {signee.displayName}
            </Typography>
            <Typography
              variant="caption"
              noWrap
              display="inline-block"
              width="100%"
            >
              {signee.email}
            </Typography>
          </Grid>
          <Grid size={4} textAlign="right">
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                justifyContent: "right",
              }}
            >
              <Typography
                sx={{
                  px: 2,
                  borderRadius: "9999px",
                  border: "1px solid",
                  color: signee.hasSigned
                    ? signee.isApproved
                      ? "#1F931E"
                      : red[400]
                    : "#E69216",
                  borderColor: signee.hasSigned
                    ? signee.isApproved
                      ? "#1F931E"
                      : red[400]
                    : "#E69216",
                }}
                align="center"
              >
                {signee.hasSigned
                  ? signee.isApproved
                    ? t("Signed")
                    : t("Rejected")
                  : t("Not_sign")}
              </Typography>
              {!signable && !readOnly && !signee.hasSigned && (
                <Button
                  size="small"
                  onClick={() => onRemoveSignee(signee)}
                  disabled={disabled}
                >
                  {t("Remove")}
                </Button>
              )}
            </Box>
          </Grid>
        </Signee>
      ))}
    </Box>
  );
}

export default SigneesTable;
