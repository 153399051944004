import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface AuthState {
  accessToken: string | null;
  tenantId: string | null;
  accessTokens: { [key: string]: string };
}

const initialState: AuthState = {
  accessToken: null,
  tenantId: null,
  accessTokens: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setTenantId: (state, action: PayloadAction<string | null>) => {
      state.tenantId = action.payload;
    },
    setAccessTokenForTenant: (
      state,
      action: PayloadAction<{ tenantId: string; accessToken: string }>
    ) => {
      state.accessTokens[action.payload.tenantId] = action.payload.accessToken;
    },
  },
});

export const hasAccessToken = (state: RootState) => !!state.auth.accessToken;
export const getAccessToken = (state: RootState) => state.auth.accessToken;
export const getTenantId = (state: RootState) => state.auth.tenantId;
export const getAccessTokenForTenant = (state: RootState, tenantId: string) => {
  // get the access token for the tenant
  const token = state.auth.accessTokens[tenantId];

  return token;
};

export const { setAccessToken, setTenantId, setAccessTokenForTenant } =
  authSlice.actions;

export default authSlice.reducer;
