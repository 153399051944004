// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";
import { ContractStatus, IContract } from "../../types/contract";
import { IFeedback } from "../../types/feedback";
import { ISharePointConnection } from "../../types/sharepointconnection";
import { IDocument } from "../../types/document";
import { IVendor } from "../../types/vendor";
import { IMessage } from "../../types/message";
import { INote } from "../../types/note";
import { IUser } from "../../types/user";
import { IKPI, IVendorKPI } from "../../types/kpi";
import { IActivity } from "../../types/activity";
import { INotification } from "../../types/notification";
import {
  ITrustedPartnerInvite,
  ITrustedPartnerInviteResponse,
  ITrustedPartnerTrust,
} from "../../types/trustedpartner";
import { IAIResponse } from "../../types/airesponse";
import { IPromptResponse } from "../../types/promptresponse";
import { IPrompt } from "../../types/prompt";
import { ISettings } from "@fluentui/react";
import {
  Author,
  ChatMessageType,
  ChatType,
  IChatBotHistory,
} from "../../types/chatBot";
import { IQuestion } from "../../../../server/types/question";
import i18next from "i18next";
import { an, ep } from "@fullcalendar/core/internal-common";
import { IConnectedDrive } from "../../types/connecteddrive";
import { IDriveItem } from "../../types/driveItem";
import { getAccessTokenForTenant } from "./authSlice";
import { ISignature } from "../../types/signature";
import { parse } from "date-fns";

export const VendorsApi = createApi({
  reducerPath: "VendorsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers: any, { getState, endpoint }: any) => {
      const accessToken = getState().auth.accessToken; // Retrieve the access token

      // get the clientId part from the url
      const clientId = window.location.pathname.match(
        /contracts-under-management\/([^\/]+)/
      )?.[1];

      const useTrustedPartnerToken =
        clientId &&
        (endpoint == "getContracts" ||
          endpoint === "getContract" ||
          endpoint === "getDocuments" ||
          endpoint === "getDocument");

      if (useTrustedPartnerToken) {
        const accessTokenForTenant = getAccessTokenForTenant(
          getState(),
          clientId
        );

        if (!accessTokenForTenant) {
          // throw new Error("No access token for client available");
        } else {
          headers.set("Authorization", `Bearer ${accessTokenForTenant}`);
        }
      } else {
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
          throw new Error("No access token available");
        }
      }

      return headers;
    },
  }),
  tagTypes: ["Vendor"],
  endpoints: (builder) => ({
    getContractsForVendor: builder.query<IContract[], string>({
      query: (id) => ({
        url: `/vendors/${id}/contracts`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        const sortedContracts = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedContracts.map((item: any) => {
          return {
            ...item,
            budget: {
              amount: parseFloat(item.budget?.amount) || 0,
              currency: item.budget?.currency || "",
            },
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getVendors: builder.query<IVendor[], void>({
      query: () => ({
        url: "/vendors",
        method: "GET",
      }),
      // providesTags: ["Vendor"],
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Vendor" as const, id })),
              "Vendor",
            ]
          : ["Vendor"],
      transformResponse: (response: any) => {
        // Sort the vendors by their creation date in descending order
        const sortedVendors = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return b._tsc - a._tsc;
        });

        const items = sortedVendors.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items;
      },
    }),
    getVendor: builder.query<IVendor, string>({
      query: (id) => ({
        url: `/vendors/${id}`,
        method: "GET",
      }),
      // providesTags: ["Vendor"],
      providesTags: (result, error, arg) => [{ type: "Vendor", id: arg }],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    deleteVendor: builder.mutation<any, string>({
      query: (id) => ({
        url: `/vendors/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Vendor"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    addVendor: builder.mutation<IVendor, Omit<IVendor, "id">>({
      query: (vendor) => ({
        url: "/vendors",
        method: "POST",
        body: vendor,
      }),
      invalidatesTags: ["Vendor"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    updateVendor: builder.mutation<
      IVendor,
      Partial<IVendor> & Pick<IVendor, "id">
    >({
      query: (vendor) => ({
        url: `/vendors/${vendor.id}`,
        method: "PUT",
        body: vendor,
      }),
      // invalidatesTags: ["Vendor"],
      invalidatesTags: (result, error, arg) => [{ type: "Vendor", id: arg.id }],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    getNotesForVendor: builder.query<INote[], string>({
      query: (id) => ({
        url: `/vendors/${id}/notes`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedNotes = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedNotes.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getMessagesForVendor: builder.query<IMessage[], IVendor>({
      query: ({ id }) => ({
        url: `/vendors/${id}/messages`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedMessages = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedMessages.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getVendorKPIs: builder.query<IKPI[], string>({
      query: (vendorId) => ({
        url: `/vendors/${vendorId}/kpis`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getVendorKPI: builder.query<IVendorKPI[], IVendorKPI>({
      query: ({ vendorId, id }) => ({
        url: `/vendors/${vendorId}/kpis/${id}`,
        method: "GET",
      }),
    }),
    deleteVendorKPI: builder.mutation<any, IVendorKPI>({
      query: ({ vendorId, id }) => ({
        url: `/vendors/${vendorId}/kpis/${id}`,
        method: "DELETE",
      }),
    }),
    addVendorKPI: builder.mutation<IVendorKPI, IVendorKPI>({
      query: (kpi) => ({
        url: `/vendors/${kpi.vendorId}/kpis`,
        method: "POST",
        body: kpi,
      }),
    }),
    updateVendorKPI: builder.mutation<IVendorKPI, IVendorKPI>({
      query: (kpi) => ({
        url: `/vendors/${kpi.vendorId}/kpis/${kpi.id}`,
        method: "PUT",
        body: kpi,
      }),
    }),
    getActivitiesForVendor: builder.query<IActivity[], IVendor>({
      query: ({ id }) => ({
        url: `/vendors/${id}/activities`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the activities by their 'createdDate' property in ascending order
        const sortedActivities = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedActivities.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
  }),
});

export const {
  useGetContractsForVendorQuery,
  useLazyGetContractsForVendorQuery,
  useGetVendorsQuery,
  useGetVendorQuery,
  useDeleteVendorMutation,
  useAddVendorMutation,
  useUpdateVendorMutation,
  useGetMessagesForVendorQuery,
  useGetNotesForVendorQuery,
  useGetVendorKPIsQuery,
  useGetVendorKPIQuery,
  useDeleteVendorKPIMutation,
  useAddVendorKPIMutation,
  useUpdateVendorKPIMutation,
  useGetActivitiesForVendorQuery,
} = VendorsApi;
