import { SvgIcon } from "@mui/material";

const MicrosoftIcon = (props: any) => (
  <SvgIcon {...props}>
    {/* Include the path or content of your SVG icon */}
    <path fill="#f3f3f3" d="M0 0h23v23H0z" />
    <path fill="#f35325" d="M1 1h10v10H1z" />
    <path fill="#81bc06" d="M12 1h10v10H12z" />
    <path fill="#05a6f0" d="M1 12h10v10H1z" />
    <path fill="#ffba08" d="M12 12h10v10H12z" />
  </SvgIcon>
);

export default MicrosoftIcon;
