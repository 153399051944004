import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Box,
  Avatar,
  Typography,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  CardActionArea,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useGetContractQuery } from "../../redux/slices/indexApiSlice";
import { spacing, SpacingProps } from "@mui/system";
import { PinDrop } from "@mui/icons-material";
import { PhoneCall } from "react-feather";
import CustomNoRowsOverlay from "../datagrids/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import { IContract } from "../../types/contract";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Card = styled(MuiCard)(spacing);

const CardMedia = styled(MuiCardMedia)`
  height: 120px;
`;

const SquareAvatar = styled(Avatar)`
  border-radius: 0;

  & .MuiAvatar-img {
    object-fit: contain;
  }
`;

// Specify the type of data
interface QueryResponse {
  data: IContract; // Here, CompanyProfile is the type of your company profile object
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any; // Change `any` to the specific error type if available
  refetch: () => void; // Define the type of the refetch function
}

function ContractAvatar({
  ...props
}: {
  contractId?: string;
  size?: number;
  big?: boolean;
  small?: boolean;
  refetch?: boolean;
  handleCardClick?: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: contract,
    isLoading,
    isSuccess,
    isError,
    refetch: getContract,
  } = useGetContractQuery(props.contractId || "", {
    skip: !props.contractId,
  });

  useEffect(() => {
    if (props.contractId || props.refetch) {
      getContract();
    }
  }, [props.contractId, props.refetch]);

  if (!props.contractId && props.small) {
    return <span />;
  }

  const handleOnClick = () => {
    if (props.handleCardClick) {
      //   setSelected(!isSelected);
      props.handleCardClick();
    } else if (contract?.id) {
      /// navigate to a new window
      navigate(`/contract/${contract?.id}`);
    }
  };

  return isSuccess && contract?.id ? (
    <React.Fragment>
      {props.big ? (
        <Card onClick={handleOnClick}>
          <CardActionArea onClick={handleOnClick}>
            {!contract ? (
              <CustomNoRowsOverlay label={t("No contract")} />
            ) : (
              <>
                {contract?.name && (
                  <CardMedia
                    sx={{
                      backgroundSize: "50%",
                      backgroundPosition: "center",
                    }}
                    title={contract?.name}
                    // image={contract?.pr}
                  />
                )}

                <CardContent sx={{ paddingBottom: "0 !important" }}>
                  <Typography variant="h6" gutterBottom>
                    {contract?.name}
                  </Typography>

                  {contract?.type && (
                    <Grid container direction="row" alignItems="center" mb={2}>
                      <Grid alignItems="start">
                        <AboutIcon>
                          <PinDrop />
                        </AboutIcon>
                      </Grid>
                      <Grid>{contract.type}</Grid>
                    </Grid>
                  )}

                  {/* {vendor?.phone && (
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid>
                    <AboutIcon>
                      <PhoneCall />
                    </AboutIcon>
                  </Grid>
                  <Grid>{vendor.phone}</Grid>
                </Grid>
              )} */}
                </CardContent>
              </>
            )}
          </CardActionArea>
        </Card>
      ) : props.small ? (
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          title={contract?.name}
        >
          {contract?.name}
        </Typography>
      ) : (
        <SquareAvatar
          sx={{
            display: "inline-block",
            width: props.size,
            height: props.size,
          }}
          alt={contract?.name}
          title={contract?.name}
          // src={contract?.logo}
        />
      )}
    </React.Fragment>
  ) : (
    <span />
  );
}

export default ContractAvatar;
