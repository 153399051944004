import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  Fade,
  CardMedia as MuiCardMedia,
  TextField as MuiTextField,
  Alert as MuiAlert,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import { useGetContractsForVendorQuery } from "../../../../redux/slices/vendorsApiSlice";
import { ContractStatus, IContract } from "../../../../types/contract";
import { useTranslation } from "react-i18next";
import { IVendor } from "../../../../types/vendor";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const timeOut = (time: number) => new Promise((res) => setTimeout(res, time));

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

interface IVendorContractsProps {
  vendor: IVendor;
}
function VendorContracts({ ...props }: IVendorContractsProps) {
  const { t } = useTranslation();

  const {
    data: contracts = [],
    isLoading: contractsLoading,
    isSuccess: contractsLoaded,
    refetch: getContracts,
  } = useGetContractsForVendorQuery(props.vendor.id, {
    skip: !props.vendor.id,
  });

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box width="100%">
        <Typography variant="h6" mb={2}>
          {t("Contracts")}
        </Typography>

        {contractsLoaded && (
          <TableContainer component={Container}>
            <Table size="small" padding="none">
              {/* <TableHead>
              <TableRow>
                <TableCell>{t("Title")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell>{t("Value")}</TableCell>
              </TableRow>
            </TableHead> */}
              <TableBody>
                {Object.keys(ContractStatus).map((status) => {
                  const contractsForStatus = contracts.filter(
                    (c: IContract) => c.status === status
                  );

                  // group per currency
                  const currencyGroups = contractsForStatus.reduce(
                    (acc: any, contract: IContract) => {
                      if (
                        contract.budget?.currency &&
                        typeof contract.budget?.currency === "string" &&
                        contract.budget?.currency
                          ?.toLocaleUpperCase()
                          .match(/^[A-Z]$/) // do not allow any other characters than A-Z
                      ) {
                        if (!acc[contract.budget.currency]) {
                          acc[contract.budget.currency] = 0;
                        }

                        acc[contract.budget.currency] += contract.budget.amount;
                      }

                      return acc;
                    },
                    {}
                  );

                  return (
                    contractsForStatus?.length > 0 && (
                      <TableRow
                        key={status}
                        sx={{ "td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell>{t(status)}</TableCell>
                        <TableCell>{contractsForStatus?.length}</TableCell>
                        <TableCell align="right">
                          {Object.keys(currencyGroups)?.map(
                            (currency: string) => (
                              <Typography key={currency}>
                                {new Intl.NumberFormat(navigator.language, {
                                  style: "currency",
                                  currency: currency,
                                  compactDisplay: "long",
                                  maximumFractionDigits: 0,
                                }).format(currencyGroups[currency])}
                              </Typography>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

export default VendorContracts;
