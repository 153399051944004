import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { IConnectedDrive } from "../../types/connecteddrive";
import {
  useGetDriveItemsQuery,
  useGetDriveQuery,
  useGetSiteQuery,
  useLazyGetDriveItemChildrenByPathQuery,
  useLazyGetDriveItemChildrenQuery,
  useLazyGetDriveItemsQuery,
} from "../../redux/slices/graphApiSlice";
import {
  Alert,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import SharePointIcon from "../../icons/SharePointIcon";
import { OpenInNew, Sync, MoreVert as MoreVertIcon } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import ConnectedSharePointDriveDialog from "../dialogs/ConnectedSharePointDriveDialog";
import { DialogMode } from "../../types/dialogmode";
import { IDriveItem } from "../../types/driveItem";
import {
  useGetContractsQuery,
  useSyncConnectedDriveMutation,
} from "../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import SharePointFilesDialog from "../dialogs/SharePointFilesDialog";
import StyledMenu from "../../theme/StyledMenu";
import useContract from "../../hooks/useContract";
import { AnalyzeStatus } from "../../../../server/types/contract";
import { AIReadingStatus, ContractStatus } from "../../types/contract";

type ConnectedDriveCardProps = {
  connectedDrive: IConnectedDrive;
};

const ConnectedDriveCard = ({ ...props }: ConnectedDriveCardProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { requestDocumentAnalysis } = useContract();

  const { data: site } = useGetSiteQuery(props.connectedDrive?.siteId);
  const { data: contracts } = useGetContractsQuery(ContractStatus.ALL);
  const [driveItems, setDriveItems] = useState<IDriveItem[]>([]);

  const {
    data: drive,
    isLoading,
    isSuccess,
  } = useGetDriveQuery(props.connectedDrive?.driveId);

  const {
    data: driveItemsInRoot,
    isLoading: driveItemsLoading,
    isSuccess: driveItemsLoaded,
    isError: driveItemsError,
    error: driveItemsErrorObject,
  } = useGetDriveItemsQuery(props.connectedDrive?.driveId);

  const [getDriveItemChildren] = useLazyGetDriveItemChildrenQuery();
  const [getDriveItemChildrenByPath] = useLazyGetDriveItemChildrenByPathQuery();

  const [
    sync,
    { data: syncedDocuments, isLoading: syncing, isSuccess: synced },
  ] = useSyncConnectedDriveMutation();

  useEffect(() => {
    if (driveItemsLoaded) {
      const rootFiles = driveItemsInRoot?.filter((item) => item.file);
      const rootFolders = driveItemsInRoot?.filter((item) => item.folder);

      // get the children of any folder in the root
      const foldersToCheck = rootFolders?.filter(
        (item) => item.folder && item.folder.childCount > 0
      );

      getAllDriveItems(foldersToCheck).then((moreDriveItems) => {
        setDriveItems([...rootFiles, ...moreDriveItems]);
      });
    }
  }, [driveItemsLoaded]);

  const getAllDriveItems = async (
    foldersToCheck: IDriveItem[]
  ): Promise<IDriveItem[]> => {
    const promises: Promise<void>[] = [];

    const moreDriveItems: IDriveItem[] = [];
    foldersToCheck?.forEach((folder) => {
      // getDriveItemChildren({
      //   driveId: folder.parentReference.driveId,
      //   driveItemId: folder.id,
      // })
      //   .unwrap()
      //   .then((children) => {
      //     console.log(children);
      //   });

      if (folder?.name) {
        promises.push(
          getDriveItemChildrenByPath({
            driveId: folder.parentReference.driveId,
            path: folder.name,
          })
            .unwrap()
            .then((children) => {
              moreDriveItems.push(...children.filter((item) => !item.folder));
            })
        );
      }
    });

    await Promise.all(promises);

    return moreDriveItems;
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const startSync = async () => {
    // send the drive items to the backend so they can be synced
    sync({
      driveItems: driveItems,
      connectedDrive: drive,
    });
  };

  useEffect(() => {
    if (synced) {
      // request an analysis for every document that was added
      console.log(syncedDocuments);

      syncedDocuments?.forEach((document) => {
        // only request analysis for documents that are not already being analyzed
        const contract = contracts?.find((c) => c.id === document.contractId);

        if (!contract?.analyzeStatus) {
          requestDocumentAnalysis(document);
        }
      });
    }
  }, [synced, syncing]);

  return (
    <Card
      key={drive?.id}
      // onClick={() => handleDriveClick(drive)}
      sx={{ flexGrow: 1, width: "100%" }}
    >
      {isLoading ? (
        <Skeleton variant="rounded" height={190} width={290} />
      ) : (
        <>
          <CardHeader
            // avatar={<SharePointIcon />}
            avatar={
              site && (
                <Avatar
                  variant="square"
                  src={`${site?.webUrl}/_api/siteiconmanager/getsitelogo?type='1'`}
                  imgProps={{
                    loading: "lazy",
                    style: { objectFit: "contain" },
                  }}
                />
              )
            }
            action={
              drive?.webUrl && (
                <IconButton
                  aria-label="open in new window"
                  href={drive.webUrl}
                  target="_blank"
                  sx={{ marginLeft: 6 }}
                >
                  <OpenInNew />
                </IconButton>
              )
            }
            title={drive?.name}
            subheader={site?.displayName}
          />

          <CardContent>
            {driveItems && <SharePointFilesDialog driveItems={driveItems} />}
            {synced && (
              <Alert severity="success">
                {t("Successfully synchronized", {
                  count: syncedDocuments?.length,
                })}
              </Alert>
            )}
          </CardContent>
          <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
            <LoadingButton
              loading={driveItemsLoading || syncing}
              onClick={() => startSync()}
              variant="contained"
              startIcon={<Sync />}
            >
              {t("Sync now")}
            </LoadingButton>

            <IconButton
              aria-label="more"
              id={`drive-menubutton-${drive?.id}`}
              aria-controls={open ? `drive-menu-${drive?.id}` : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              // sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id={`drive-menu-${drive?.id}`}
              MenuListProps={{
                "aria-labelledby": `drive-menubutton-${drive?.id}`,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* <Divider sx={{ my: 0.5 }} /> */}

              <ConnectedSharePointDriveDialog
                mode={DialogMode.Delete}
                refetch={() => {}}
                connectedDrive={props.connectedDrive}
                asMenuItem
                // iconOnly
              />
            </StyledMenu>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default ConnectedDriveCard;
