import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  TextField as MuiTextField,
  Alert as MuiAlert,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { IVendor } from "../../../../types/vendor";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const timeOut = (time: number) => new Promise((res) => setTimeout(res, time));

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

interface IFraudDetectionProps {
  vendor: IVendor;
  refetch: () => void;
  setErrors: React.Dispatch<React.SetStateAction<any[]>>;
}

function FraudDetection({ ...props }: IFraudDetectionProps) {
  const { t } = useTranslation();
  const { vendor, refetch } = props;

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box width="100%">
        <Typography variant="h6" mb={2}>
          {t("Fraud detection")}
        </Typography>
      </Box>
    </Box>
  );
}

export default FraudDetection;
