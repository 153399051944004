export const english = {
  all_user: "All users",
  date: {
    relative: "{{ date, relative }}",
    dateOnly: "{{ date, PPPP }}", // dateOnly
    dateTime: "{{ date, PPPPp }}", // dateTime
    dateTimeAndTimeZone: "{{ date, PPPPp zzzz }}",
    ago: "{{ date, ago }}",
  },
  expires_on: "expires on",
  notification_period_date_text:
    "The notice period is {{value}} {{unit}} so please take action before {{beforeDate}}.",
  notification_no_period_date_text: "Please take action before {{endDate}}.",
  Search: " Search…",
  "Welcome back": "Welcome back.",
  "We've missed you": "We've missed you",
  "Sign in to your account to continue": "Sign in to your account to continue",
  reminder_date_text_me: "You will receive a reminder on {{ date, PPPP }}.",
  reminder_date_text_single:
    "{{displayNames}} will receive a reminder on {{ date, PPPP }}.",
  reminder_date_text_multi:
    "{{displayNames}} will receive a reminder on {{ date, PPPP }}.",
  reminder_date_text_past_due_me: "Your reminder was due on {{ date, PPPP }}.",
  reminder_date_text_past_due_single:
    "The reminder for {{displayNames}} was due on {{ date, PPPP }}.",
  reminder_date_text_past_due_multi:
    "The reminder for {{displayNames}} was due on {{ date, PPPP }}.",
  and: "and",
  You: "You",
  you: "you",
  pages: "Page {{current}} of {{total}}",

  Reminders: "Reminders",
  "New reminder": "New reminder",
  "Edit reminder": "Edit reminder",
  "Delete reminder": "Delete reminder",

  "Go back": "Go back",
  "Sign in": "Sign in",
  "Sign out": "Sign out",
  "Forgot password": "Forgot password",
  "Email Address": "Email Address",
  Password: "Password",
  "Email is required": "Email is required",
  "Password is required": "Password is required",
  "Remember me": "Remember me",
  "Continue with Microsoft 365 Account": "Continue with Microsoft 365",
  "Continue with Google Account": "Continue with Google",
  "Continue with email": "Continue with email",
  "Don't have an account?": "Don't have an account?",
  "Sign up": "Sign up",

  New: "New",
  "Coming soon": "Coming soon",

  "Home page": "Home",
  Dashboard: "Dashboard",
  "Connecting to server": "Connecting to server...",

  "Switch to dark mode": "Switch to dark mode",
  "Switch to light mode": "Switch to light mode",

  // DataGrid
  selected: "{{count}} selected",
  "Switch to card view": "Switch to card view",
  "Switch to table view": "Switch to table view",
  of: "of",
  "Are you sure?": "Are you sure?",
  DeleteSingular: "Delete",
  item: "item",
  items: "items",

  // Timeline
  Timeline: "Timeline (in development)",

  "Analyzing document using Go Beyond Procurement AI":
    "Go Beyond Procurement is analyzing the document",
  Analyzing: "Analyzing",
  Success: "Success!",
  "Document was analyzed successfully!": "Document was analyzed successfully!",

  "Reading document": "Reading the document",
  Reading: "Reading ({{percentage}}%)",
  "Reading success": "Document was read successfully!",
  "Reading done": "Reading done",
  "Reading not done": "Contract has not been read",
  Stop: "Stop",
  Stopped: "Stopped",
  "Analysis stopped": "Analysis stopped.",
  "Reading failed": "Reading failed.",
  Read: "Read",

  // Forms
  "Your data has been submitted successfully!":
    "Your data has been submitted successfully!",
  "An error occurred!": "An error occurred!",
  "Select People": "Select People",
  Submit: "Submit",
  "Required field": "Required field",
  Required: "This is a required field",
  Add: "Add",
  "Notice period must be a positive number":
    "Notice period must be a positive number",
  "Price must be a positive number": "Price must be a positive number",
  "End date must be after the start date":
    "End date must be after the start date",

  Contracts: "Contracts",
  "X contracts include this clause":
    "{{count}} contract(s) mention something regarding this clause",
  "1 contracts includes this clause":
    "1 contract mentions something regarding this clause",
  "1 contract needs your attention": "1 contract needs your attention",
  "X contracts need your attention": "{{count}} contracts need your attention",

  "Contract Information": "Contract Information",
  "Managed Contracts": "Managed Contracts",
  "Use AI to create a new contract": "Use AI to create a new contract",
  "New contract": "New contract",
  Create: "Create",
  "a new contract": "a new contract",
  Start: "Start",
  "with existing contract": "with existing contract",
  "Upload or import a contract": "Upload or import existing contracts",
  "Create contract with AI": "Create a new contract with AI",
  "Edit contract": "Edit contract",
  "Delete contract": "Delete contract",
  "No contracts": "No contracts",
  "Contract Owner": "Contract Owner",
  Summary: "Summary",
  remaining: "remaining",
  "Are you sure you want to delete this contract?":
    "Are you sure you want to delete this contract?",
  "Show more": "Show more",
  "Show less": "Show less",
  "assigned the contract to": "assigned the contract to",
  "Contract updated by": "Contract updated by",
  "updated the contract": "updated the contract",
  "was updated to": "was updated to",
  Organization: "Organization",
  "Notice period": "Notice period",
  "Latest termination date": "Latest termination date",
  Renew: "Renew",
  "before latest termination date is reached":
    "before latest termination date is reached",
  Terminate: "Terminate",
  "Contract will be securely saved": "Contract will be securely saved",
  "No end date": "No end date",
  "No notice period": "No notice period",
  "When does the contract expire?": "When does the contract expire?",
  Total: "Total",
  Percentage: "Percentage",
  "Country of jurisdiction": "Country of jurisdiction",

  Documents: "Documents",
  "New document": "New document",
  "Delete document": "Delete document",
  "Upload a new document": "Upload a new document.",
  "a new document": "a new document",
  "from Microsoft 365": "from Microsoft 365",
  "Select a file": "Select a file",
  "Select an image": "Select an image",
  "Artificial Intelligence": "Artificial Intelligence",
  File: "File",
  Versions: "Versions",
  Version: "Version",
  "Document information": "Document information",
  Origin: "Origin",
  Location: "Location",
  "Stored in Azure": "Stored in Azure",
  "Stored in SharePoint": "Stored in SharePoint",
  Size: "Size",
  "Document was uploaded manually": "Document was uploaded manually",
  "File name": "File name",
  Modified: "Modified",
  "View in SharePoint": "View in SharePoint",

  "You are viewing and older version of this document.":
    "You are viewing and older version of this document.",
  "You are viewing the latest version of this document.":
    "You are viewing the latest version of this document.",
  Changes: "Changes",
  "The AI service has found the following information":
    "The AI service has found the following information.",
  "Does this information look correct?": "Does this information look correct?",
  "Yes, save": "Yes, save",
  "No, remove response": "No, remove response",
  "When requesting a change we will kindly ask you to provide more details about the contract so we can send this to the AI.":
    "When requesting a change we will kindly ask you to provide more details about the contract so we can send this to the AI.",

  // Assignees
  Assignees: "Assignees",
  "Contract has not been assigned": "Contract has not been assigned yet",
  "Edit Assignee(s)": "Edit contract assignee(s)",
  "Select the assignee(s) of the contract. Assignees will receive an email notification.":
    "Select the assignee(s) of the contract. Assignees will receive an email notification.",
  "Contract has no owner": "Contract has no owner yet",

  Name: "Name",
  Vendor: "Vendor",
  Purchaser: "Purchaser",
  Customer: "Customer",
  Category: "Category",
  "Start date": "Start date",
  "End date": "End date",
  Value: "Contract value",
  Administrator: "Administrator",
  Owner: "Owner",
  Manager: "Manager",
  Action: "Action",
  Subject: "Subject",
  Parties: "Parties",
  Financials: "Financials",
  "Received from": "Received from",

  "sales agreement": "Sales agreement",
  "service agreement": "Service agreement",
  "employment contract": "Employment contract",
  "rental agreement": "Rental agreement",
  "loan agreement": "Loan agreement",
  other: "Other",

  Roles: "Roles",
  Users: "Users",
  "Edit administrator": "Edit administrator",
  "Edit owner": "Edit owner",
  "Edit manager": "Edit manager",
  "Assign administrator": "Assign administrator",
  "Assign owner": "Assign owner",
  "Assign manager": "Assign manager",
  "First name": "First name",
  "Last name": "Last name",
  "Job title": "Job title",
  Email: "Email",

  "Add user": "Add user",
  "Contact persons": "Contact persons",

  Vendors: "Vendors",
  "Vendor Information": "Vendor information",
  "New vendor": "New vendor",
  "Edit vendor": "Edit vendor",
  "Delete vendor": "Delete vendor",
  "No vendors": "No vendors",
  "No vendor": "No vendor",
  "Select Vendor": "Select vendor",
  "Select the vendor of the contract": "Select the vendor of the contract.",
  "Select the owner of the contract. The owner is the person who is responsible for the contract":
    "Select the owner of the contract. The owner is the person who is responsible for the contract.",
  "Potential Savings": "Potential Savings",
  "Active Contracts": "Active Contracts",
  "Total Contracts Value": "Total Contracts Value",
  Logo: "Logo",
  Address: "Address",
  Street: "Street",
  "House number": "House number",
  "Postal code": "Postal code",
  City: "City",
  Country: "Country",
  Phone: "Phone",
  Website: "Website",
  "Import from Excel": "Import from Excel",
  "Import vendors from XLSX or CSV file":
    "Import vendors from XLSX or CSV file",
  "Download template": "Download template",
  record: "record",
  records: "records",
  "Synchronize company details with KVK":
    "Synchronize company details with KVK",
  "Are you sure you want to delete this vendor?":
    "Are you sure you want to delete this vendor?",
  Create1Vendor: "Add 1 vendor",
  CreateXVendors: "Add {{count}} vendors",

  Messages: "Messages",
  Message: "Message",
  "New message": "New message",
  "Edit message": "Edit message",
  "Delete message": "Delete message",
  "No messages": "No messages",
  "Messages are visible to everyone": "Messages are visible to everyone",
  "Are you sure you want to delete this message?":
    "Are you sure you want to delete this message?",

  Notes: "Tasks",
  Note: "Task",
  "New note": "New task",
  "Add note": "Add task",
  "Edit note": "Edit task",
  "Delete note": "Delete task",
  "No notes": "No tasks",
  "Notes are visible to everyone": "Tasks are visible to everyone",
  "Are you sure you want to delete this task?":
    "Are you sure you want to delete this task?",
  "Are you sure you want to delete this reminder?":
    "Are you sure you want to delete this reminder?",

  // KPI's
  "KPI's": "KPI's",
  "New KPI": "New KPI",
  "Edit KPI": "Edit KPI",
  "Delete KPI": "Delete KPI",
  "No KPI's": "No KPI's",
  Score: "Score",
  Comment: "Comments",
  "Choose from library": "Choose from library",

  // Prompts
  "Add prompt": "Add prompt",
  "Edit prompt": "Edit prompt",
  "Delete prompt": "Delete prompt",
  Prompts: "Prompts",
  "Are you sure you want to delete this prompt?":
    "Are you sure you want to delete this prompt?",
  "Search in prompt library": "Search in library",
  "Unique key": "Unique key",

  // Feedback
  Risks: "Risks",
  "New risk": "New risk",
  "No risks": "No risks",
  Description: "Description",
  Categories: "Categories",
  "Select a category": "Select a category",
  Risk: "Risk",
  Goal: "Goal",
  Legal: "Legal",
  Recommendation: "Recommendation",
  Layout: "Layout",
  Recommendations: "Recommendations",

  // Connections
  "New connection": "New connection",
  "Are you sure you want to delete this connection?":
    "Are you sure you want to delete this connection?",
  "Select Site": "Select site",
  "Select Drive": "Select location",
  "View files": "View {{count}} file(s)",
  Import: "Import",
  Import_applications: "Teams, SharePoint, OneDrive and Outlook",
  "View all": "View all",
  "messages with attachments found":
    "{{count}} messages with attachments found.",

  // Calendar
  Calendar: "Calendar",
  expires: "expires",
  expired: "expired",
  "Already expired": "Already expired",
  "expires in": "expires in",
  day: "day",
  days: "days",
  Days: "Days",
  week: "week",
  weeks: "weeks",
  Weeks: "Weeks",
  month: "month",
  months: "months",
  Months: "Months",
  quarter: "quarter",
  year: "year",
  Units: "Units",
  on: "on",

  Pages: "Pages",
  Libraries: "Libraries",
  Configuration: "Configuration",
  Connections: "Connections",

  Back: "Back",
  Edit: "Edit",
  Cancel: "Cancel",
  Canceled: "Canceled",
  Save: "Save",
  Close: "Close",
  Delete: "Delete",
  Previous: "Previous",
  Next: "Next",
  Clear: "Clear",
  "Next year": "Next year",
  "Previous year": "Previous year",

  // AI
  "AI functions": "AI functions",
  "Analyse document": "Analyse document",
  "The AI service has found new information!":
    "The AI service has found new information!",

  // Notifications
  Notifications: "Notifications",
  Notification: "Notification",
  "New notification": "New notification",
  "New notifications": "New notifications",
  "No new notifications": "No new notifications",
  "Show all notifications": "Show all notifications",
  "Contract Expiry": "Contract Expiry",
  "Most Recent": "Most Recent",
  "A notification will be sent when the contract is about to expire":
    "A notification will be sent when the contract is about to expire",

  // Settings
  Settings: "Settings",
  General: "General",
  "Choose default language": "Choose default language",
  "Notification period": "Notification period",
  "Notification period description":
    "Indicate how long in advance you want to receive a notification before a contract expires.",
  Language: "Language",

  // Dashboard
  "Latest contracts": "Latest contracts",
  "Upcoming expirations": "Contract expirations",
  "Top vendors": "Top vendors",
  Unknown: "Unknown",
  Others: "Others",
  "Artificial intelligence recommendations":
    "Artificial intelligence recommendations",
  Today: "Today",
  Tomorrow: "Tomorrow",
  this_week: "this week",
  this_month: "this month",
  upcoming_months: "upcoming months",
  this_year: "this year",
  upcoming_months_detailed: "upcoming months (detailed)",
  "Potential analysis": "Potential analysis",
  "Needs attention": "Needs attention",
  "Missing contract value": "Missing contract value",
  "Unmanaged contracts": "Unmanaged contracts",
  "not analyzed by AI": "not analyzed by AI",
  "contracts expiring within 3 months": "Contracts expiring within 3 months",
  "Contracts expiring in the next 12 months":
    "Contracts expiring in the next 12 months",
  "contracts have an unknown value": "Contracts with an unknown value",
  "contracts without owner": "Contracts without owner",
  "This page is only visible by authenticated users.":
    "This page is only visible by authenticated users.",
  "Ask a question about your data":
    "I'm your AI Assistant. Ask a question about your data...",
  "Ask a question about your contracts":
    "I'm your AI Assistant. Ask a question about your contracts...",
  "Ask a question about your vendors":
    "I'm your AI Assistant. Ask a question about your vendors...",
  "Ask a question about your events":
    "I'm your AI Assistant. Ask a question about your events...",
  "Ask a question about this contract":
    "I'm your AI Assistant. Ask a question about this contract...",
  "Ask a question about this vendor":
    "I'm your AI Assistant. Ask a question about this vendor...",
  "Ask a question about prompting":
    "I'm your AI Assistant. Ask a question about prompting...",
  "Ask a question": "Ask a question...",
  "Need help?": "Need help?",
  "Drag me": "Drag to move me",
  "I don't have an answer to that yet": "I don't have an answer to that yet.",
  "Start with a prompt": "Start with a prompt",
  "Chat with": "Chat with {{botName}}",
  Conversations: "Conversations",
  "Current conversations": "All conversations",
  "Recent conversations": "Recent conversations",
  "Contracts without": "Contracts without",

  "Risk analysis": "AI Risk analysis",
  Analyze: "Analyze",
  Analyzed: "Analyzed",
  "contract with Artificial Intelligence":
    "contract with Artificial Intelligence",
  "Start a risk analysis for this contract":
    "Start a risk analysis for this contract",
  "Contract has not been analyzed": "Contract has not been analyzed",
  "The AI service will analyze the contract and extract the following information":
    "The AI service will analyze the contract and extract the following information",
  "Start AI analysis": "Start AI analysis",
  "Continue without AI analysis": "Continue without AI analysis",
  "Analysis requested": "Analysis is starting",

  // Admin
  Admin: "Admin",
  "Trusted Partners": "Trusted Partners",
  "Partner ID": "Partner ID",
  "Enter the tenant id of the trusted partner":
    "Enter the tenant id of the trusted partner",
  "New trusted partner": "New trusted partner",
  "Name of the organization requesting contract management support":
    "Name of the organization requesting contract management support",
  "Name of the Trusted Partner": "Name of the Trusted Partner",
  "Tenant ID of the Trusted Partner": "Tenant ID of the Trusted Partner",
  "App ID": "App ID",
  "App Secret": "App Secret",
  "App ID of your app registration with Sites.Read.All permission":
    "App ID of your app registration with Sites.Read.All permission",
  "App Secret of your app registration with Sites.Read.All permission":
    "App Secret of your app registration with Sites.Read.All permission",
  "Invitation sent to": "Invitation sent to {{name}}",
  "Invitation sent on": "Invitation sent by {{name}} on {{date, PPPPp}}",
  Revoke: "Revoke",
  "Invitation received from": "Invitation received from {{name}}",
  "Invitation received on":
    "Invitation received from {{name}} on {{date, PPPPp}}",
  Accept: "Accept",
  Decline: "Decline",
  accepted: "Accepted",
  declined: "Declined",

  // Data Location
  "Data Location": "Data Location",
  Default: "Default",

  // Connected Drives
  "Connected Drives": "Connected Drives",
  "New connected drive": "New connected drive",
  "Connect your own cloud hosting service":
    "Connect your own cloud hosting service",
  "Are you sure you want to delete this connected drive?":
    "Are you sure you want to disconnect this connected drive?",
  Disconnect: "Disconnect",

  "SharePoint Connections": "SharePoint Connections",

  // Activities
  Activities: "Activities",
  "Activity Log": "Activity Log",
  "No activities": "No activities",
  Event: "Event",
  Date: "Date",
  Payload: "Payload",
  User: "User",
  Created: "Created",
  Updated: "Updated",
  Deleted: "Deleted",
  Contract_deleted_activity: "{{userName}} deleted contract {{contractId}}",
  by: "by",

  // Chat bot
  Hi: "Hi",
  Home: "Home",
  Hello: "Hello",
  How_can_we_help: "How can we help?",
  Send_us_a_message: "Start a new conversation",
  We_typically_reply_in_under_minutes:
    "We typically reply in under {{time}} minutes",
  "Bot welcome message in popup": "How can I help you today?",
  "Initiate chat": "Click here to start",
  "Initiate chat subtitle": "",
  //  "We typically reply in under minutes":   "We typically reply in under {{time}} minutes",
  "Welcome message body":
    "I am {{botName}}, your personal assistant. I have learned everything about your contracts and beyond! Ask me anything.",
  Initiate_Document: "Initiate Document",
  AI_Analysis: "AI Analysis",
  Add_Signatories: "Add Signatories",
  Finish_Document: "Finish Document",
  Upload_contract: "Upload contract",
  Upload_contracts: "Upload contracts",
  Click_to_upload: "Click to upload",
  or_drag_and_drop: "or drag and drop",
  Maximum_file_size: "Maximum file size {{size}}MB",
  Import_from_Microsoft_resources: "Import from Microsoft 365",
  Import_from_Microsoft_resources_subtitle:
    "OneDrive, SharePoint, Teams and Outlook",
  Upload_or_import_to_see_here: "Upload or import to see here.",
  No_files_found: "No files found",
  Maximum_files_reached: "Maximum files reached",
  Maximum_files_reached_description:
    "You can only select {{count}} files at once.",
  "Ask your question": "Ask your question",
  "Connected to": "Connected to",
  "Shared with me": "Shared with me",
  botName: "AI Assistent",
  "Conversations regarding this contract":
    "Conversations regarding this contract",
  "No conversations regarding this contract":
    "No conversations regarding this contract",
  "Conversations regarding this vendor": "Conversations regarding this vendor",
  "No conversations regarding this vendor":
    "No conversations regarding this vendor",
  "Other conversations": "Other conversations",

  // AI Contract
  DRAFT: "Draft",
  PUBLISHED: "Published",
  SIGNING: "Out for signing",
  SIGNED: "Signed",
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  EXPIRED: "Expired",
  ALL: "All",
  Signing: "Out for signing",
  "Signees who signed": "Signatures",
  "Awaiting your signature": "Awaiting your signature",
  Requested: "Requested",

  Attachments: "Attachments",
  "Add attachment": "Add attachment",
  "No attachments": "No attachments",
  published: "Published",
  draft: "Draft",
  drafts: "Drafts",
  "My draft contracts": "My draft contracts",
  Published: "Published",
  contracts: "contracts",
  "Recent contracts": "Recent contracts",

  "Start date must be before end date": "Start date must be before end date",
  "End date must be after start date": "End date must be after start date",
  "End date must be after today": "End date must be after today",

  "Re-analysis": "Re-analysis",
  "Ok to process": "Ok to process",
  "GoBeyondProcurement is analyzing the document":
    "GoBeyondProcurement is analyzing the document.",
  "The document is analyzed successfully":
    "The document is analyzed successfully!",
  Start_AI_analysis: "Start AI analysis",
  Contract_has_been_analyzed: "Contract has been analyzed",
  Contract_has_not_been_analyzed: "Contract has not been analyzed",
  "Please check the necessary contract information in the form below and update accordingly. When requesting a change we will kindly ask you to provide more details about the contract so we can send this to the AI.":
    "Please check the necessary contract information and update if needed.",
  "Please check the necessary contract information on the left section":
    "Please check the necessary contract information on the left section.",

  "Contract details": "Information",
  "Contract management": "Management",
  "Basic details": "Basic details",
  "Fields found": "Fields found",
  "Fields not found": "Fields not found",
  "Strength matrix": "Strength matrix",
  "View in contract": "View in contract",
  "Fields performance": "Fields performance",
  "Contract performance": "Contract performance",
  "Fields performance description":
    "Are all relevant clauses present in the contract?",
  "All relevant contracts mention something about":
    "All relevant contracts contain a clause related to {{subject}}.",
  "None of the relevant contracts mention something about":
    "None of the relevant contracts contain a clause related to {{subject}}.",
  "Some of the relevant contracts mention something about":
    "{{count}} contracts do not contain a clause related to {{subject}}.",
  "1 contract does not mention something about":
    "{{count}} contract does not contain a clause related to {{subject}}.",

  "Please review the contract carefully before you sign.":
    "Please review the contract carefully before you sign.",
  "This action cannot be undone.": "This action cannot be undone.",
  Sign: "Sign",
  Signatures: "Signatures",
  Formatting: "Formatting",
  Reject: "Reject",
  Invitees: "Invitees",
  "List of signees to provide signatures for the contract":
    "List of signees to provide signatures for the contract.",
  "There are no signees.": "There are no signees.",
  "Click on “Add signatories” to request digital signatures.":
    "Click on 'Add signatories' to request digital signatures.",
  "Edit the contract to add signatories.":
    "Edit the contract to add signatories.",
  "Please check the information of the contract before you sign. This action is irreversible.":
    "Please check the information of the contract before you sign. This action is irreversible.",
  "Add myself": "Add myself",
  "Manager needs to sign": "Manager needs to sign",
  "Owner needs to sign": "Owner needs to sign",
  "You have signed this contract.": "You have signed this contract.",
  "You have rejected this contract.": "You have rejected this contract.",
  Receipt: "Receipt",
  "Signing as": "Signing as:",
  "Signatures were updated": "Signatures were updated",
  "Send for signing": "Send for signing",
  "Check the signature fields on the contract before you send the invitation. Drag them to the correct position if needed.":
    "Check the position of the signature fields on the contract before you send the invitation. Drag them to the correct position if needed.",
  "Move to first page": "Move to first page",
  "Move to last page": "Move to last page",
  "Move to previous page": "Move to previous page",
  "Move to next page": "Move to next page",

  Manage: "Manage",
  Signed: "Signed",
  Not_sign: "Not signed",
  Rejected: "Rejected",
  Remove: "Remove",
  Signees: "Signees",
  Invite_Signees: "Invite Signees",
  recipient: "recipient",
  recipients: "recipients",
  "Invite the users you want to sign this contract":
    "Invite the users you want to sign this contract.",
  "Contract owner needs to signs": "Contract owner needs to signs",
  Signee_existed: "Signee already exists",
  Email_address: "Email address",
  Invalid_email_address: "Invalid email address",
  Continue: "Continue",
  Reject_to_Sign: "Reject to Sign",
  "Are you sure you want to reject this invitation? This action cannot be undone.":
    "Are you sure you want to reject this invitation? This action cannot be undone.",
  Reason: "Reason",
  Document_Signature: "Document Signature",
  Draw: "Draw",
  Type: "Type",
  Upload: "Upload",
  "Draw your signature in the blank box below":
    "Draw your signature in the blank box below.",
  "Draw your signature file in the blank box below":
    "Draw your signature file in the blank box below.",
  Signature: "Signature",
  Type_here: "Type here",
  "Click on 'Next' to proceed to the next step":
    "Click on 'Next' to proceed to the next step.",
  "Document has been uploaded": "Document has been uploaded",
  Check: "Check",
  Send: "Send",
  "Next step": "Next step",
  "Previous step": "Previous step",
  Finish: "Finish",
  "Sign for": "Signature for",
  "click here": "click here",
  "A signature request has been sent to the following persons:":
    "A signature request has been sent to the following persons: ",
  "Drag and drop the signature fields": "Drag and drop the signature fields",
  "Click on the button below to send the signature request to the signees.":
    "Click on the button below to send the signature request to the invitees.",
  "The invitees will receive an email with a link to the contract.":
    "The invitees will receive an email with a link to the signing page.",

  // error pages
  "Access Denied": "Access Denied",
  "Link Expired": "Link Expired",
  "You don’t have permission to access this page. Please contact an administrator to get permission or go to the home page and browse other pages.":
    "You don’t have permission to access this page. Please contact an administrator to get permission or go to the home page and browse other pages.",
  "The link you are accessing might have been expired or is temporarily unavailable. Click to contact us for support or send a new request to contract owner for new access.":
    "The link you are accessing might have been expired or is temporarily unavailable. Click to contact us for support or send a new request to contract owner for new access.",
  "Contact us": "Contact us",
  "Go to home": "Go to home",
  Request: "Request",

  // Analyze dialog
  contract_analysis_tracking: "Contract analysis tracking ({{quantity}})",
  "Wasn't analyzed": "Wasn't analyzed",

  "Save as Draft": "Save as Draft",
  "Quick edit": "Quick edit",

  "There are no published contracts to display":
    "There are no published contracts to display",
  Import1File: "Add 1 file",
  ImportXFiles: "Add {{count}} files",
  Import1Contract: "Add 1 contract",
  ImportXContracts: "Add {{count}} contracts",
  Analyzing_contracts: "Analyzing contract(s) ({{result}} remaining)",
  "Contracts have been analyzed": "Contracts have been analyzed",

  Reading_contracts: "Reading contract(s) ({{result}} remaining)",
  "Contracts have been read": "Contracts have been read",
  "1 contract read": "1 contract read",
  "X contracts read": "{{count}} contracts read",

  Syncing_vendors: "Syncing {{count}} vendor(s) with KVK",
  "Vendors have been synced with KVK": "Vendors have been synced with KVK",

  KVK: "Chamber of Commerce (KVK)",
  "Registration number": "Registration number",
  "Fraud detection": "Fraud detection",
  "Retrieve company profile": "Retrieve company profile",
  "Founded date": "Founded date",
  "Founded year": "Year of establishment",
  Founded: "Founded",
  "KVK number": "KVK-number",
  "Company profile": "Company profile",
  "Search in KVK": "Search in kvk.nl",
  Companies: "Companies",
  "Press enter to search": "Press enter to search",
  "Search in the business register of The Netherlands":
    "Search in the business register of The Netherlands (kvk.nl)",
  "Enter manually": "Enter manually",
  "Use the web form to enter the company details manually":
    "Use the web form to enter the company details manually",
  "Add vendors": "Add vendors",
  "No results": "No results",
  "Vendor page": "Vendor page",

  // level: "Level {{level}}",
  "level 0": "Model-P3",
  "level 1": "Model-P1",
  "level 2": "Model-P2",

  "Estimate costs": "Estimate costs",
  Estimated_costs_result: "Estimates costs: {{cost}}",
  go_to_contract: "Go to contract.",

  // custom fields
  Leveringsvoorwaarden: "Terms and conditions",
  Borgstelling: "Deposit",
  Transitieproces: "Transition process",
  Stakeholders: "Stakeholders",
  "Collectieve inkoop": "Collective purchasing",
  Markttoetsing: "Market testing",
  "Afspraken over duurzaamheid": "Sustainability agreements",
  "Vergoedingen en tarieven": "Fees and rates",
  "Onrechtmatige beëindiging": "Unlawful termination",
  Misverstand: "Misunderstanding",
  "Ingebrekestelling / Niet-nakoming": "Notice of default / Non-performance",
  "Contractuele boetes / boeteclausule":
    "Contractual penalties / penalty clause",
  Afnameverplichting: "Purchase obligation",
  Contractduur: "Contract duration",
  Indexatie: "Indexation",
  "Overdracht van rechten en plichten": "Transfer of rights and obligations",
  Prestaties: "Performance",
  "Toepasselijk recht": "Applicable law",
  Verlengingsclausule: "Extension clause",
  Opschortingsrecht: "Suspension right",
  "Non-concurrentiebeding of Exclusiviteitsclausule":
    "Non-competition clause or Exclusivity clause",
  "Force majeure": "Force majeure",
  Garanties: "Guarantees",
  Betalingsvoorwaarden: "Payment terms",
  "Intellectuele eigendomsrechten": "Intellectual property rights",
  "Beëindiging en opzegging": "Termination and cancellation",
  Geschillenoplossing: "Dispute resolution",
  Vertrouwelijkheid: "Confidentiality",
  Schadeloosstelling: "Indemnification",
  Aansprakelijkheid: "Liability",
  Bonusafspraken: "Bonus agreements",
  Partijen: "Parties",
  Beëindiging: "Termination",
  "Bonus afspraken": "Bonus agreements",
  Duurzaamheid: "Sustainability",
  Geschillen: "Disputes",
  "Non-concurrentiebeding": "Non-competition clause",

  "Successfully synchronized":
    "Successfully synchronized {{count}} contract(s)",
  Complete: "Complete",
  "View details": "View details",
  "View contract": "View contract",
  "Not synced": "Not synced",
  "Contract not found": "Contract not found.",
  subscriptionInfo: "Subscription: {{contractsLimit}} contracts",
  hidden_prompts: "Hidden prompts: {{count}}",
  Prompt_visible: "Prompt is visible. Click to hide.",
  Prompt_hidden: "Prompt is hidden. Click to activate.",
};
