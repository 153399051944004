import styled from "@emotion/styled";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, spacing } from "@mui/system";

const MenuItemAsLabel = styled((props: MenuItemProps) => <MenuItem sx={{ justifyContent: "center" }} {...props} />)(({ theme }) => ({
  "& .MuiMenuItem-root": {
    color: theme.typography.body2.color,
    opacity: "initial !important",
  },
  "& .Mui-disabled": {
    color: `${theme.typography.body2.color} !important`,
    opacity: "initial !important",
  },
  "&:hover": {
    backgroundColor: "transparent !important",
    cursor: "default",
  },
  "&:active": {
    backgroundColor: "transparent !important",
  },
  "&:focus": {
    backgroundColor: "transparent !important",
  },
}));

export default MenuItemAsLabel;
