import React, { useEffect, useState } from "react";
import { IVendor } from "../../types/vendor";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Autocomplete,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { ITrustedPartnerInvite } from "../../types/trustedpartner";
import { useAddTrustedPartnerInviteMutation } from "../../redux/slices/indexApiSlice";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchema = Yup.object().shape({
  partnerId: Yup.string().required("Required"),
});

interface ITrustedPartnerInviteFormProps {
  invite?: ITrustedPartnerInvite;
  mode: DialogMode;
}

function TrustedPartnerInviteForm({
  ...props
}: ITrustedPartnerInviteFormProps) {
  const { t } = useTranslation();

  const [
    addTrustedPartnerInvite,
    {
      isSuccess: isInvited,
      reset: resetInvited,
      isError: inviteError,
      error: inviteErrorObject,
    },
  ] = useAddTrustedPartnerInviteMutation();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === DialogMode.Add) {
        await addTrustedPartnerInvite(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const initialValues: ITrustedPartnerInvite = props.invite || {
    requestorName: "",
    tenantId: "",
    partnerName: "",
    partnerId: "",
    appRegistration: {
      tenantId: "",
      appId: "",
      appSecret: "",
    },
    type: "invite",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Card mb={6}>
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                {t("Your data has been submitted successfully!")}
              </Alert>
            )}

            {errors && !isValid && !status?.sent && (
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            )}

            <Form id="trusted-partner-form" onSubmit={handleSubmit}>
              <Grid container gap={3}>
                <Grid size={12}>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <TextField
                      name="requestorName"
                      label={t("Name")}
                      value={values.requestorName}
                      error={Boolean(
                        touched.requestorName && errors.requestorName
                      )}
                      fullWidth
                      // helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      helperText={t(
                        "Name of the organization requesting contract management support"
                      )}
                    />
                  </Grid>

                  <Grid size={12}>
                    <TextField
                      name="partnerName"
                      label={t("Name")}
                      value={values.partnerName}
                      error={Boolean(touched.partnerName && errors.partnerName)}
                      fullWidth
                      // helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      helperText={t("Name of the Trusted Partner")}
                    />
                  </Grid>

                  <Grid size={12}>
                    <TextField
                      name="partnerId"
                      label={t("Partner ID")}
                      value={values.partnerId}
                      error={Boolean(touched.partnerId && errors.partnerId)}
                      fullWidth
                      // helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      helperText={t("Tenant ID of the Trusted Partner")}
                    />
                  </Grid>

                  {/* <Grid xs={12} md={8}>
                    <TextField
                      name="appRegistration.tenantId"
                      label={t("Tenant ID")}
                      value={values.appRegistration?.tenantId}
                      error={Boolean(
                        touched.appRegistration?.tenantId &&
                          errors.appRegistration?.tenantId
                      )}
                      fullWidth
                      // helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      helperText="Tenant ID of the Requestor's App Registration"
                    />
                  </Grid> */}

                  <Grid size={12}>
                    <TextField
                      name="appRegistration.appId"
                      label={t("App ID")}
                      value={values.appRegistration?.appId}
                      error={Boolean(
                        touched.appRegistration?.appId &&
                          errors.appRegistration?.appId
                      )}
                      fullWidth
                      // helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      helperText={t(
                        "App ID of your app registration with Sites.Read.All permission"
                      )}
                    />
                  </Grid>

                  <Grid size={12}>
                    <TextField
                      name="appRegistration.appSecret"
                      label={t("App Secret")}
                      value={values.appRegistration?.appSecret}
                      error={Boolean(
                        touched.appRegistration?.appSecret &&
                          errors.appRegistration?.appSecret
                      )}
                      fullWidth
                      // helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      helperText={t(
                        "App Secret of your app registration with Sites.Read.All permission"
                      )} // replace by certificate??
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default TrustedPartnerInviteForm;
