export type ISignature = {
  id?: string; // the id of the signature tracking item in the database
  displayName: string;
  email: string;
  signedDate: string | null;
  reason?: string;
  signatureUrl: string;
  signatureSize?: { width: number; height: number };
  positionPercentValue: { x: number; y: number };
  draggable?: boolean;
  isSigned?: boolean;
  isApproved?: boolean;
  contractId?: string;
  tenantId?: string;
  isDragged?: boolean;
};

export type ISignee = {
  displayName: string;
  email: string;
  hasSigned?: boolean;
  signatureId?: string; // the id of the signee's signature tracking item in the database
  isApproved?: boolean;
};

export enum ISignType {
  DRAW = "draw",
  TYPE = "type",
  UPLOAD = "upload",
}

export enum SigningOutcome {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
