export const createFileFromArrayBuffer = (
  arrayBuffer: ArrayBuffer,
  fileName: string,
  fileType: string
) => {
  const pdfBytes = new Uint8Array(arrayBuffer);
  const blob = new Blob([pdfBytes], { type: fileType });
  const file = new File([blob], fileName, {
    type: fileType,
  });

  return file;
};

type CreateFileFromUrl = (
  url: string,
  fileName: string,
  fileType: string
) => Promise<File>;

export const createFileFromUrl: CreateFileFromUrl = (
  url: string,
  fileName: string,
  fileType: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: fileType }); // fileType

      resolve(file);
    } catch (error) {
      console.log("create file from url error", error);
      reject(error);
    }
  });
};

export const createFileFromBinaryString = (
  binaryString: string,
  fileName: string,
  fileType: string
) => {
  const binary = window.atob(binaryString);
  const len = binary.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([bytes]);
  const file = new File([blob], fileName, {
    type: fileType,
  });

  return file;
};
