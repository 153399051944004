import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing, SpacingProps } from "@mui/system";
import { Box, Button as MuiButton, Typography } from "@mui/material";
import ExpiredIcon from "../../icons/ExpiredIcon";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { useTranslation } from "react-i18next";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Wrapper = styled.div`
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  width: 800px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BrandIcon = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 120px;
  height: auto;
  margin-bottom: 20px;
`;

function Page410() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet title="403 Error" />

      <BrandIcon />

      <Typography variant="h4" align="center" mb={6}>
        {t("Link Expired")}
      </Typography>
      <Box display="inline-block" px={20}>
        <Typography variant="body1" align="center" mb={10}>
          {t(
            "The link you are accessing might have been expired or is temporarily unavailable. Click to contact us for support or send a new request to contract owner for new access."
          )}
        </Typography>
      </Box>

      <Box>
        <ExpiredIcon />
      </Box>

      <Box display="inline-flex" gap={4} mt={10}>
        <Button component={Link} to="/" variant="outlined" color="secondary">
          {t("Contact us")}
        </Button>

        <Button component={Link} to="/" variant="contained" color="secondary">
          {t("Request")}
        </Button>
      </Box>
    </Wrapper>
  );
}

export default Page410;
