import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { IPrompt } from "../../../../types/prompt";
import Markdown from "react-markdown";

interface IContractPromptResponseProps {
  contract?: IContract;
  prompt: IPrompt;
  setSearchForText?: (text: string) => void;
}
function ContractPromptResponse({ ...props }: IContractPromptResponseProps) {
  const { contract, prompt } = props;
  const { t } = useTranslation();

  const response = contract?.extraContext?.find(
    (c) => prompt.key && c.property === prompt.key
  );

  return (
    <Box pb={2}>
      <Typography variant="body2">
        {/* color="textSecondary" */}
        <Markdown
          components={{
            p: ({ node, ...props }) => (
              <p
                style={{
                  marginBlockStart: "0.5rem",
                  marginBlockEnd: "0.5rem",
                }}
                {...props}
              />
            ),
            code: ({ node, ...props }) => (
              <code
                style={{
                  fontFamily: "Inter",
                  whiteSpace: "pre-wrap",
                }}
                {...props}
              />
            ),
          }}
        >
          {response?.details}
        </Markdown>

        {/* {response?.locationInText && (
          <Button
            onClick={() => {
              // const location = JSON.parse(response.locationInText);
              const location = "Inflatiecorrectie";
              props.setSearchForText && props.setSearchForText(location);
            }}
          >
            {response?.locationInText}
          </Button>
        )} */}
      </Typography>
    </Box>
  );
}

export default ContractPromptResponse;
