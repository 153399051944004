import { useContext } from "react";

// import { AuthContext as JWTAuthContext } from "../contexts/JWTContext";
import { AuthContext as AuthContextFirebase } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";
 import { AuthContext as AuthContextMSAL } from "../contexts/MSALContext";
import { AuthContext } from "../contexts/MainAuthContext";

const useAuth = () => {
  const context = useContext(AuthContext);


  if (!context) throw new Error("AuthContext must be placed within AuthProvider");

  return context;
};

export default useAuth;
