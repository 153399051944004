import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  useTheme,
  Typography,
  useMediaQuery,
  Divider as MuiDivider,
} from "@mui/material";
import { DialogMode } from "../../../../types/dialogmode";

import ContractAttachments from "../../contracts/components/ContractAttachments";
import ContractNotes from "../../contracts/components/ContractNotes";
import AskMeAnything from "../../../components/AskMeAnything";
import { IVendor } from "../../../../types/vendor";
import VendorKVK from "./VendorKVK";
import FraudDetection from "./FraudDetection";
import ActiveContracts from "./VendorContracts";

const Divider = styled(MuiDivider)(spacing);

type IVendorDetailSidebarProps = {
  mode: DialogMode;
  vendor: IVendor;
  refetch: () => void;
  setErrors: React.Dispatch<React.SetStateAction<any[]>>;
};

const VendorDetailSidebar: React.FC<IVendorDetailSidebarProps> = ({
  mode,
  vendor,
  refetch,
  setErrors,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        width: "100%",
        mb: 6,
        py: 6,
        px: 3,
        backgroundColor: "background.default",
      }}
    >
      {vendor.logo && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url(${vendor.logo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: 100,
            width: "100%",
          }}
        />
      )}

      <Divider my={4} />
      <AskMeAnything
        showLabel
        placeholder={"Ask a question about this vendor"}
      />
      <Divider my={4} />
      <ActiveContracts vendor={vendor} />
      <Divider my={4} />
      <VendorKVK vendor={vendor} refetch={refetch} setErrors={setErrors} />
      <Divider my={4} />
      <FraudDetection vendor={vendor} refetch={refetch} setErrors={setErrors} />
      <Divider my={4} />
      {/* <ContractAttachments mode={DialogMode.Edit} vendor={vendor} /> */}
      <Divider my={4} />
      <ContractNotes vendor={vendor} />
      <Divider my={4} />
    </Box>
  );
};

export default VendorDetailSidebar;
