import React, { useEffect, useState, useTransition } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Typography,
  Autocomplete,
  Box,
  Tooltip,
  IconButton,
  Fade,
  CardActions,
  CardActionArea,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useGetTrustedPartnerInvitesQuery } from "../../../redux/slices/indexApiSlice";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ISettings } from "../../../types/settings";
import { ITrustedPartnerInvite } from "../../../types/trustedpartner";
import { DialogMode } from "../../../types/dialogmode";
import { Check } from "react-feather";
import DateTimeTranslate from "../../../components/DateTimeTranslate";
import TrustedPartnerInviteDialog from "../../../components/dialogs/TrustedPartnerInviteDialog";
import Invite from "./Invite";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function TrustedPartners() {
  const { t } = useTranslation();

  const { data: invites = [], refetch } = useGetTrustedPartnerInvitesQuery();

  return (
    <React.Fragment>
      <Helmet title={t("Trusted Partners")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Trusted Partners")}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <TrustedPartnerInviteDialog mode={DialogMode.Add} refetch={refetch} />
        </Grid>

        <Grid>
          <Grid container spacing={6}>
            {invites?.map((invite: ITrustedPartnerInvite) => {
              return (
                <Grid size={4}>
                  <Invite
                    key={`trusted-partner-invite-${invite.id}`}
                    invite={invite}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TrustedPartners;
