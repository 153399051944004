// ** React Imports
import { Document, Page } from "react-pdf";
import React, { ReactEventHandler, useEffect, useRef, useState } from "react";

// https://www.npmjs.com/package/react-pdf
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// ** MUI Imports
import {
  Box,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Tooltip,
  Typography,
  Zoom,
  styled,
} from "@mui/material";
import { display, spacing } from "@mui/system";

import {
  Delete as DeleteIcon,
  DoubleArrow,
  FirstPage,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Verified,
} from "@mui/icons-material";

import { Edit, Sidebar } from "react-feather";
import { useTranslation } from "react-i18next";

import Draggable from "react-draggable";

import { ISignature, ISignee } from "../../types/signature";
import { grey } from "@mui/material/colors";

const elevation2 =
  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important";
const elevation3 =
  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important";

const SIGN_AREA_WIDTH = 180;
const SIGN_AREA_HEIGHT = 75;

const StyledSignArea = styled(Box)`
  position: relative;
  width: ${SIGN_AREA_WIDTH}px;
  height: ${SIGN_AREA_HEIGHT}px;
  border: 1px dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
const SignIcon = styled(Box)`
  position: absolute;
  top: -10px;
  left: 10px;
`;

const SignatureImg = styled("img")`
  width: 150px;
  height: auto;
  opacity: 1;
  position: absolute;
  zindex: -1;
`;

const ElevatedPaper = styled(Paper)({
  boxShadow: elevation3,
  margin: 4,
});

const HoverBox = styled(Box)`
  // opacity: 0;
  transform: translate(-50%);
  transition: opacity ease-in-out 0.2s;
  z-index: 200;
  // background-color: ${(props) => props.theme.palette.background.default};

  // &:hover {
  //   opacity: 1;
  // }
`;

const SignArea: React.FC<{
  signer?: ISignee;
  signature: ISignature;
  signable?: boolean;
  onOpenSignaturePad: (email: string) => void;
  onMoveToFirstPage: (email: string) => void;
  onMoveToPrevPage: (email: string) => void;
  onMoveToNextPage: (email: string) => void;
  onMoveToLastPage: (email: string) => void;
  numPages: number;
}> = ({
  signer,
  signature: sig,
  signable,
  onOpenSignaturePad,
  onMoveToFirstPage,
  onMoveToNextPage,
  onMoveToPrevPage,
  onMoveToLastPage,
  numPages,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <StyledSignArea
        sx={{
          borderColor: sig.email === signer?.email ? "#22A121" : "#FDA018",
          pointerEvents:
            sig.email !== signer?.email && signable ? "none" : "all",
          color: "black",
          cursor:
            sig.draggable && !sig.isSigned
              ? "grab"
              : signable && sig.email === signer?.email && !sig.signedDate
              ? "pointer"
              : "default",
          "&:active": {
            cursor: sig.draggable && !sig.isSigned ? "grab" : "default",
          },
          "&:hover": {
            backgroundColor:
              sig.email === signer?.email ? grey[100] : "initial",
          },
        }}
        onClick={() =>
          signable &&
          sig.email === signer?.email &&
          !sig.signedDate &&
          !sig.isSigned
            ? onOpenSignaturePad(sig.email)
            : null
        }
      >
        <SignIcon
          sx={{
            color: sig.email === signer?.email ? "#22A121" : "#FDA018",
          }}
        >
          <Edit />
        </SignIcon>
        <Typography>{t("Sign for")}</Typography>
        <Typography fontWeight="bold">{sig.displayName}</Typography>
        {signable && sig.email === signer?.email && !sig.signedDate && (
          <Typography variant="caption">({t("click here")})</Typography>
        )}
      </StyledSignArea>
      {sig.draggable && !sig.isSigned && numPages > 1 && (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: -1.5 }}>
          <Tooltip title={t("Move to first page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToFirstPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <FirstPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to previous page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToPrevPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateBefore sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to next page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToNextPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateNext sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to last page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToLastPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <LastPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export interface ISignaturFieldsProps {
  documentRef: React.RefObject<HTMLDivElement>;
  documentSize: { width: number; height: number };
  signable?: boolean;
  signer?: ISignee;
  signatures?: Array<ISignature>;
  onSaveSignature?: () => any;
  setSignatures?: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  setSignatureSizePercentValue?: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  numPages: number;
  handleOpenSignaturePad: (email: string) => void;
}
function SignatureFields({ ...props }: ISignaturFieldsProps) {
  const { t } = useTranslation();

  // signature----------------------------------------------------------------------------------
  const {
    signer,
    signable,
    signatures,
    setSignatures,
    setSignatureSizePercentValue,
  } = props;

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    const imgElement = e.target as HTMLImageElement;
    if (imgElement && setSignatureSizePercentValue && props.numPages) {
      console.log({
        imgElementclientWidth: imgElement.clientWidth,
        imgElementclientHeight: imgElement.clientHeight,
        clientHeight: props.documentRef.current?.clientHeight,
        clientWidth: props.documentRef.current?.clientWidth,
      });
      setSignatureSizePercentValue({
        width:
          imgElement.clientWidth /
          (props.documentRef.current?.clientWidth || 1),
        height:
          imgElement.clientHeight /
          ((props.documentRef.current?.clientHeight || 0) / props.numPages),
      });
    }
    console.log({ number: props.numPages, imgElement });
  };

  const handleStop = (dragElement: any, email: string) => {
    if (setSignatures) {
      setSignatures((prev) => {
        const newSignatures = prev.map((sig) => {
          if (sig.email === email && props.documentRef.current) {
            console.log("dragElement", dragElement);
            console.log("props.documentSize", props.documentSize);
            console.log(
              "props.documentSize",
              props.documentRef.current.clientHeight
            );
            return {
              ...sig,
              isDragged: true,
              positionPercentValue: {
                x: dragElement.x / props.documentRef.current.clientWidth,
                y: dragElement.y / props.documentRef.current.clientHeight,
              },
            };
          } else {
            return { ...sig };
          }
        });
        return newSignatures;
      });
    }
  };

  const moveSignature = (offset: number, email: string) => {
    const pageHeight =
      (props.documentRef.current?.clientHeight || 0) / props.numPages;
    const moveOffset = pageHeight * Math.abs(offset);
    let signatureElement: HTMLElement | null = null;
    if (setSignatures) {
      setSignatures((prevSigs) => {
        const updatedSigs = prevSigs.map((sig, index) => {
          const sigY =
            sig.positionPercentValue.y *
              (props.documentRef.current?.clientHeight || 0) +
            SIGN_AREA_HEIGHT;

          if (sig.email === email) {
            // check if possible to move offset page
            if (offset < 0 && sigY > moveOffset) {
              sig.positionPercentValue.y -=
                moveOffset / (props.documentRef.current?.clientHeight || 1);
              signatureElement = window.document.getElementById(
                `signature-area-${index}`
              );
            } else if (
              offset > 0 &&
              sigY + moveOffset < (props.documentRef.current?.clientHeight || 0)
            ) {
              sig.positionPercentValue.y +=
                moveOffset / (props.documentRef.current?.clientHeight || 1);
              signatureElement = window.document.getElementById(
                `signature-area-${index}`
              );
            }
          }

          return { ...sig };
        });

        return updatedSigs;
      });

      // use setTimeout to ensure that position of signature is updated before croll it into view
      setTimeout(() => {
        if (signatureElement) {
          signatureElement.scrollIntoView({ block: "center" });
        }
      }, 0);
    }
  };

  const calcSignatureOffset = (positionY: number) => {
    const pageHeight =
      (props.documentRef.current?.clientHeight || 0) / props.numPages;
    const pageHeightPercentValue =
      pageHeight / (props.documentRef.current?.clientHeight || 1);
    return Math.floor(positionY / pageHeightPercentValue);
  };

  const moveSignatureToFirstPage = (email: string) => {
    const signature = signatures?.find((sig) => sig.email === email);
    if (signature) {
      const offset = calcSignatureOffset(signature.positionPercentValue.y);
      moveSignature(-offset, email);
    }
  };
  const moveSignatureToPrevPage = (email: string) => {
    moveSignature(-1, email);
  };
  const moveSignatureToNextPage = (email: string) => {
    moveSignature(1, email);
  };
  const moveSignatureToLastPage = (email: string) => {
    const signature = signatures?.find((sig) => sig.email === email);
    if (signature) {
      const offset = calcSignatureOffset(signature.positionPercentValue.y);
      moveSignature(props.numPages - offset - 1, email);
    }
  };

  if (!signatures) return <span />;

  return (
    <>
      {signatures?.map((sig, index) => (
        <Draggable
          key={index}
          bounds="parent"
          disabled={!sig.draggable || sig.isSigned} // disable dragging for signed signatures
          position={{
            x:
              sig.positionPercentValue.x *
              (props.documentRef.current?.clientWidth || 0),
            y:
              sig.positionPercentValue.y *
              (props.documentRef.current?.clientHeight || 0),
          }}
          onStart={sig.draggable && !signable ? undefined : () => false}
          onStop={(e: any, dragEmelent: any) => {
            if (!sig.draggable || sig.isSigned) return; // do not allow dragging for signed signatures

            handleStop(dragEmelent, sig.email);
          }}
        >
          <Box
            id={`signature-area-${index}`}
            display="inline-block"
            sx={{ opacity: sig.signedDate ? 0 : 1, pointerEvents: "all" }}
          >
            {sig.email === signer?.email &&
              sig.signedDate !== null &&
              props.numPages && (
                <SignatureImg onLoad={handleImageLoad} src={sig.signatureUrl} />
              )}
            <SignArea
              signer={signer}
              signature={sig}
              signable={signable}
              onOpenSignaturePad={props.handleOpenSignaturePad}
              onMoveToFirstPage={moveSignatureToFirstPage}
              onMoveToPrevPage={moveSignatureToPrevPage}
              onMoveToNextPage={moveSignatureToNextPage}
              onMoveToLastPage={moveSignatureToLastPage}
              numPages={props.numPages}
            />
          </Box>
        </Draggable>
      ))}
    </>
  );
}

export default React.memo(SignatureFields);
// export default DocumentViewer;
