import { WebPubSubClient } from "@azure/web-pubsub-client";
import React, { useEffect, useState } from "react";
import useAppSelector from "../hooks/useAppSelector";
import useAuth from "../hooks/useAuth";
import {
  useAddAnalysisTaskMutation,
  useLazyGetContractAnalysisStatusQuery,
  useStopAnalysisTaskMutation,
} from "../redux/slices/aiSlice";
import {
  useLazyGetDriveItemContentAsPDFQuery,
  useLazyGetDriveItemContentQuery,
} from "../redux/slices/graphApiSlice";
import {
  useGetContractsQuery,
  useGetWebPubSubQuery,
  useLazyGetContractsQuery,
} from "../redux/slices/indexApiSlice";
import {
  AIReadingStatus,
  AnalyzedContract,
  ContractStatus,
  IContract,
} from "../types/contract";
import { IDocument } from "../types/document";
import { AnalysisStatus } from "../types/analysisStatus";

const initialState: {
  openedContract: AnalyzedContract | null;
  analyzedContracts: AnalyzedContract[];
  setOpenedContract: (c: AnalyzedContract | null) => any;
  requestDocumentAnalysis: (info: IDocument) => any;
  requestedDocumentAnalysis: IDocument | null;
  stopDocumentAnalysis: (info: IDocument) => any;
  setAnalyzedContracts: React.Dispatch<
    React.SetStateAction<AnalyzedContract[]>
  >;
  analyzedContract: IContract | null;
  analyzingNumber: number | null | undefined;
  isAnalyzing?: boolean;
  analyzingSuccess?: boolean;
} = {
  requestedDocumentAnalysis: null,

  openedContract: null,
  analyzedContracts: [],
  setOpenedContract: (c: AnalyzedContract | null) => {},
  setAnalyzedContracts: () => {},
  requestDocumentAnalysis: (info: IDocument) => {},
  stopDocumentAnalysis: (info: IDocument) => {},
  analyzedContract: null,
  analyzingNumber: 0,
  isAnalyzing: false,
  analyzingSuccess: false,
};

type StatusResponse = {
  contractId: string;
  status: string;
  progress: number;
  taskId: string;
};

const ContractContext = React.createContext(initialState);

type ContractProvider = {
  children: React.ReactNode;
};

function ContractProvider({ children }: ContractProvider) {
  const { user, isAuthenticated } = useAuth();
  const tenantId = useAppSelector((state) => state.auth.tenantId);

  const [
    analyzeContract,
    {
      data: analysisRequestResponse,
      isSuccess: analyzeRequestSent,
      isLoading,
      isError,
      error,
    },
  ] = useAddAnalysisTaskMutation();

  const [stopAnalysisTask] = useStopAnalysisTaskMutation();

  const [
    getContracts,
    {
      data: contracts = [],
      isSuccess: contractsLoaded,
      isLoading: contractsLoading,
      isError: contractsError,
    },
  ] = useLazyGetContractsQuery();

  const [
    getDriveItemContent,
    {
      data: driveItemContent,
      isLoading: driveItemContentLoading,
      isSuccess: driveItemContentLoaded,
    },
  ] = useLazyGetDriveItemContentQuery();

  const [
    getDriveItemContentAsPDF,
    {
      data: driveItemContentAsPDF,
      isLoading: driveItemContentAsPDFLoading,
      isSuccess: driveItemContentAsPDFIsLoaded,
    },
  ] = useLazyGetDriveItemContentAsPDFQuery();

  const [getContractAnalysisStatus, { data: contractAnalysisTaskStatus }] =
    useLazyGetContractAnalysisStatusQuery();

  const {
    data: dataPubSub,
    refetch: refetchPubSub,
    isSuccess: isSuccessPubSub,
  } = useGetWebPubSubQuery(isAuthenticated, { skip: !isAuthenticated });

  const [requestedDocumentAnalysis, _setRequestedDocumentAnalysis] =
    useState<IDocument | null>(null);

  const [analyzedContracts, setAnalyzedContracts] = useState<
    AnalyzedContract[]
  >([]);

  const [openedContract, _setOpenedContract] =
    useState<AnalyzedContract | null>(initialState.openedContract);

  const [analyzedContract, setAnalyzedContract] = useState<IContract | null>(
    initialState.analyzedContract
  );

  const [analyzingNumber, setAnalyzingNumber] = useState<number | null>();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analyzingSuccess, setAnalyzingSuccess] = useState(false);
  const [statusResponses, setStatusResponses] = useState<StatusResponse[]>([]);
  const [intervals, setIntervals] = useState<any[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      refetchPubSub();

      getContracts(ContractStatus.ALL);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (analysisRequestResponse && analyzeRequestSent) {
      console.log("Analysis request sent:", analysisRequestResponse);

      // refresh the contracts
      getContracts(ContractStatus.ALL);
    }
  }, [analysisRequestResponse, analyzeRequestSent]);

  async function connect(dataPubSub: string) {
    console.log("connect pub-sub");

    const client = new WebPubSubClient({
      getClientAccessUrl: async () => dataPubSub,
    });

    client.on("group-message", (e: any) => {
      const res = e.message?.data;
      if (res.type == "contractAnalyze") {
        const data: IContract = res.data;
        if (
          res.data?.analyzeStatus === AIReadingStatus.COMPLETED ||
          res.data?.analyzeStatus === AIReadingStatus.ANALYZING
        ) {
          setAnalyzedContract({
            ...data,
            startDate: data?.startDate
              ? new Date(data.startDate).toISOString()
              : undefined,
            endDate: data?.endDate
              ? new Date(data.endDate).toISOString()
              : undefined,
          });
        }
      }
    });
    await client.start();
    let group = `contract-tenant-${tenantId}`;
    await client.joinGroup(group);
  }

  useEffect(() => {
    if (isSuccessPubSub && dataPubSub && tenantId) {
      // connect(dataPubSub.token);
    }
  }, [isSuccessPubSub, tenantId]);

  useEffect(() => {
    if (contracts?.length > 0) {
      const newContracts = contracts
        .filter(
          (c: IContract) =>
            !analyzedContracts.some((ac) => ac.contractId === c.id)
        )
        .map((c: IContract) => {
          const ac: AnalyzedContract = {
            name: c.name,
            contractId: c.id,
            analyzeStatus: c.analyzeStatus,
            progress: undefined,
            analyzeTaskId: c.analyzeTaskId,
            status: c.status,
          };
          return ac;
        });

      const newAnalyzedContracts = [...analyzedContracts, ...newContracts];

      setAnalyzedContracts(newAnalyzedContracts.reverse());
    }
  }, [contracts?.length]);

  useEffect(() => {
    console.log(analyzedContracts?.length);
  }, [analyzedContracts]);

  useEffect(() => {
    // add the latest status response to the analyzedContracts

    const newAnalyzedContracts = analyzedContracts.map((ac) => {
      if (ac.analyzeStatus === AIReadingStatus.COMPLETED) {
        return ac;
      }

      // find the latest status response for the contract
      const payload = (statusResponses as any)?.findLast(
        (r: StatusResponse) => r.contractId === ac.contractId
      );

      if (!payload) {
        return ac;
      }

      // only update the progress if it's higher than the current one
      return {
        ...ac,
        progress:
          payload?.status?.toLowerCase() === "done"
            ? 100
            : payload?.status?.toLowerCase() === "pending" &&
              payload.progress >= 0 &&
              payload.progress * 100 > (ac?.progress || 0)
            ? payload.progress * 100
            : ac.progress,
        analyzeStatus:
          payload?.status?.toLowerCase() === "pending"
            ? AIReadingStatus.ANALYZING
            : payload?.status?.toLowerCase() === "done"
            ? AIReadingStatus.COMPLETED
            : ac.analyzeStatus,
        analyzeTaskId: payload?.taskId || ac.analyzeTaskId,
      };
    });

    setAnalyzedContracts(newAnalyzedContracts);
  }, [statusResponses]);

  useEffect(() => {
    const newAnalyzingNumber = getAnalyzingNumber();
    if (newAnalyzingNumber !== analyzingNumber) {
      setAnalyzingNumber(newAnalyzingNumber);
    }

    if (contracts?.length > 0) {
      const analyzing = contracts.filter(
        (c) =>
          c.analyzeTaskId &&
          (c.analyzeStatus === AIReadingStatus.ANALYZING || !c.analyzeStatus)
      );

      if (analyzing?.length > 0) {
        const secondRun = analyzedContracts.some(
          (c) => c?.progress && c.progress >= 0
        );

        for (const c of analyzing) {
          startInterval(c);
        }

        // setTimeout(() => {
        //   setAnalyzedContracts((prev) => {
        //     const updatedFiles = prev.map((c) => {
        //       let progress = c.progress;
        //       if (
        //         c.analyzeStatus === AIReadingStatus.ANALYZING &&
        //         progress < 99
        //       ) {
        //         progress += 3;
        //       }
        //       return { ...c, progress };
        //     });

        //     return updatedFiles;
        //   });
        // }, Math.random() * (5000 - 500) + 500);
      }
    }
  }, [analyzedContracts, contracts]);

  const startInterval = (c: IContract) => {
    // check if there is alreacy an interval for the contract
    const existingInterval = intervals.find((i) => i.id === c.id);

    if (existingInterval) {
      if (c.analyzeStatus === AIReadingStatus.COMPLETED) {
        // cancel the interval
        clearInterval(existingInterval?.interval);
      }

      return;
    }

    // start the interval for the contract
    const interval = setInterval(() => {
      checkAnalysisStatus(c.id);
    }, 20000);

    setIntervals((prev) => {
      const newIntervals = [...prev, { id: c.id, interval }];
      return newIntervals;
    });
  };

  // useEffect(() => {
  //   if (analyzedContract) {
  //     const newAnalyzedContracts = analyzedContracts.map((c) => {
  //       const contract = { ...c };
  //       if (
  //         contract.contractId === analyzedContract.id &&
  //         analyzedContract.analyzeStatus === AIReadingStatus.COMPLETED
  //       ) {
  //         return {
  //           ...contract,
  //           progress: 100,
  //           analyzeStatus: AIReadingStatus.COMPLETED,
  //         };
  //       } else {
  //         return contract;
  //       }
  //     });

  //     setAnalyzedContracts(newAnalyzedContracts);
  //   }
  // }, [analyzedContract]);

  useEffect(() => {
    if (!isAnalyzing && analyzingNumber! > 0) {
      setIsAnalyzing(true);
    }

    if (isAnalyzing && analyzingNumber === 0) {
      setIsAnalyzing(false);
      setAnalyzingSuccess(true);
    }
  }, [analyzingNumber, isAnalyzing]);

  const getAnalyzingNumber = (): number => {
    return analyzedContracts?.filter(
      (c: AnalyzedContract) =>
        c.analyzeStatus && c.analyzeStatus == AIReadingStatus.ANALYZING
    ).length;
  };

  const addDocumentToAnalyzed = (d: IDocument) => {
    if (!analyzedContracts.some((c) => c.contractId === d.contractId)) {
      const contractToAdd: AnalyzedContract = {
        name: d.name,
        contractId: d.contractId || "",
      };
      const newAnalyzedContracts = [...analyzedContracts, contractToAdd];

      setAnalyzedContracts(newAnalyzedContracts);
    }
  };

  const updateAnalyzedContractTaskId = (
    d: IDocument,
    analyzeTaskId?: string
  ) => {
    const newAnalyzedContracts = analyzedContracts.map((c) => {
      if (c.contractId === d.contractId) {
        return {
          ...c,
          analyzeStatus: analyzeTaskId ? AIReadingStatus.ANALYZING : undefined,
          analyzeTaskId,
        };
      }

      return c;
    });

    setAnalyzedContracts(newAnalyzedContracts);
  };

  const requestDocumentAnalysis = async (d: IDocument) => {
    if (d) {
      let analysisResponse: any;
      setIsAnalyzing(true);

      addDocumentToAnalyzed(d);

      if (d.location === "sharepoint") {
        // we need to pass a downloadUrl

        let response: any;
        if (d?.name?.toLowerCase().endsWith(".pdf")) {
          response = await getDriveItemContent(d);
        } else {
          response = await getDriveItemContentAsPDF(d);
        }

        if (response.data) {
          const downloadUrl = response.data;
          analysisResponse = await analyzeContract({ ...d, downloadUrl });
        }
      } else {
        analysisResponse = await analyzeContract(d);
      }

      if (analysisResponse?.data?.data) {
        updateAnalyzedContractTaskId(d, analysisResponse?.data?.data);
      }

      if (analysisResponse?.error) {
        // TODO: handle error to show on the screen
        console.error("Error analyzing contract:", analysisResponse?.error);
      }
    }
  };

  const stopDocumentAnalysis = async (d: IDocument) => {
    await stopAnalysisTask(d);
  };

  const setOpenedContract = (c: AnalyzedContract | null) => {
    _setOpenedContract(c);
  };

  const checkAnalysisStatus = async (contractId: string) => {
    if (!contractId) {
      return;
    }

    await getContractAnalysisStatus({ id: contractId })
      .unwrap()
      .then((payload: AnalysisStatus) => {
        // check the status of the contract

        console.log(payload);

        if (!payload) {
          return;
        }

        // add the response to the statusResponses
        setStatusResponses((prev) => {
          const newResponses = [
            ...prev,
            {
              ...payload,
              contractId,
            },
          ];
          return newResponses;
        });

        // c.progress = payload.progress * 100;

        // setAnalyzeProgress(payload.progress * 100);

        // if the status is not analyzing, we should update the number
        // if the status is analyzing, we should wait for the next iteration
        // if the status is success
      })
      .catch((error: any) => {
        console.error("Error getting analysis status:", error);

        // add the response to the statusResponses
        setStatusResponses((prev) => {
          const newResponses = [
            ...prev,
            {
              status: "error",
              progress: 0,
              contractId,
              taskId: "",
            },
          ];
          return newResponses;
        });
      });
  };

  return (
    <ContractContext.Provider
      value={{
        openedContract,
        analyzedContracts,
        setOpenedContract,
        setAnalyzedContracts,
        requestDocumentAnalysis,
        stopDocumentAnalysis,
        analyzedContract,
        requestedDocumentAnalysis,
        analyzingNumber,
        isAnalyzing,
        analyzingSuccess,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
}

export { ContractContext, ContractProvider };
