import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { MoreHoriz as MoreHorizIcon, Person } from "@mui/icons-material";

import { IContract } from "../../../../types/contract";

interface IContractContactPersonsProps {
  contract?: IContract;
  hideTitle?: boolean;
}
function ContractContactPersons({ ...props }: IContractContactPersonsProps) {
  const { contract } = props;
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Contact persons")}
        </Typography>
      )}

      {contract?.buyer && contract.buyer.contactPersons?.length > 0 && (
        <Box mb={3}>
          <Grid container spacing={3} pt={2}>
            {contract.buyer.contactPersons.map((person, i) => {
              return (
                <Grid key={`contactPerson-${i}-${person.email}`}>
                  <Alert severity="info" icon={<Person />} variant="outlined">
                    <Typography variant="body1">{person.name}</Typography>
                    <Typography variant="body1">{person.email}</Typography>
                    <Typography variant="body1">{person.phone}</Typography>
                    <Typography variant="body1">{person.role}</Typography>
                  </Alert>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {contract?.seller && contract.seller.contactPersons?.length > 0 && (
        <Box mb={3}>
          <Grid container spacing={3} pt={2}>
            {contract.seller.contactPersons.map((person, i) => {
              return (
                <Grid key={`contactPerson-${i}-${person.email}`}>
                  <Alert severity="info" icon={<Person />} variant="outlined">
                    <Typography variant="body1">{person.name}</Typography>
                    <Typography variant="body1">{person.email}</Typography>
                    <Typography variant="body1">{person.phone}</Typography>
                    <Typography variant="body1">{person.role}</Typography>
                  </Alert>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default ContractContactPersons;
