import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISignature, ISignee } from "../../../../types/signature";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function SignButton({
  signable,
  signer,
  signature,
  handleSign,
  handleReject,
}: {
  signable: boolean;
  signer?: ISignee;
  signature?: ISignature;
  handleSign: () => void;
  handleReject: () => void;
}) {
  const { t } = useTranslation();

  return signer && signable ? (
    <Box
      mt={4}
      mb={2}
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      {!signer.hasSigned && (
        <>
          <Typography variant="h6" mb={4}>
            {t("Please review the contract carefully before you sign.")}{" "}
            {t("This action cannot be undone.")}
          </Typography>

          <Box mb={3} justifyContent="center" display="flex">
            <Button
              variant="outlined"
              disabled={signer?.hasSigned}
              onClick={handleReject}
              sx={{ marginRight: 3 }}
            >
              {t("Reject")}
            </Button>

            <Button
              variant="contained"
              disabled={signer?.hasSigned}
              onClick={handleSign}
            >
              {t("Sign")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  ) : (
    <span />
  );
}

export default SignButton;
