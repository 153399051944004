import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { spacing, SpacingProps } from "@mui/system";
import { Box, Button as MuiButton, Typography } from "@mui/material";
import Number404Icon from "../../icons/Number404Icon";
import { ReactComponent as Logo } from "../../vendor/logo.svg";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Wrapper = styled.div`
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  width: 800px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BrandIcon = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 120px;
  height: auto;
  margin-bottom: 20px;
`;

function AIContractSignaturePage403() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Helmet title="403 Error" />

      <BrandIcon />

      <Typography variant="h4" align="center" mb={6}>
        {t("Access Denied")}
      </Typography>
      <Box display="inline-block" px={20}>
        <Typography variant="body1" align="center" mb={10}>
          {t(
            "You don’t have permission to access this page. Please contact an administrator to get permission or go to the home page and browse other pages."
          )}
        </Typography>
      </Box>

      <Box>
        <Number404Icon />
      </Box>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        mt={10}
      >
        {t("Go to home")}
      </Button>
    </Wrapper>
  );
}

export default AIContractSignaturePage403;
