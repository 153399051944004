// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";

// Placeholder function to retrieve the access token from your authentication provider
// const getAccessToken = () => {
//   // Logic to retrieve the access token from your authentication provider
//   // Return the retrieved access token
//   return "123";
// };

import { IGraphUser } from "../../types/user";
import axios, { AxiosError } from "axios";
import { ISharePointConnection } from "../../types/sharepointconnection";
import { IDocument } from "../../types/document";
import { getAccessTokenForTenant } from "./authSlice";
import { IDriveItem } from "../../types/driveItem";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";
import { IOutlookMessage } from "../../types/outlookmessage";
import { IDrive } from "../../types/drive";
import { IOrganizationBranding } from "../../types/organizationbranding";

export const GraphApi = createApi({
  reducerPath: "GraphApi",

  baseQuery: fetchBaseQuery({
    baseUrl: `${config.GRAPH_BASE_URL}/${config.GRAPH_VERSION}`,
    prepareHeaders: (headers: any, { getState, endpoint }: any) => {
      let accessToken = getState().auth.accessToken; // Retrieve the access token

      // if we are on a contract under management page, we need to use the access token from the trust

      // get the clientId part from the url
      const clientId = window.location.pathname.match(
        /contracts-under-management\/([^\/]+)/
      )?.[1];

      const useTrustedPartnerToken =
        clientId &&
        (endpoint === "getUserProfileForUser" ||
          endpoint === "getUserPhotoForUser" ||
          endpoint === "getDriveItems" ||
          endpoint === "getDriveItemChildren" ||
          endpoint === "getDriveItemContent" ||
          endpoint === "getDriveItemContentAsPDF" ||
          endpoint === "getDriveItemVersions" ||
          endpoint === "getDriveItemVersionContent" ||
          endpoint === "getDriveItemVersionContentAsPDF" ||
          endpoint === "getDriveItem" ||
          endpoint === "getDriveItemThumbnails" ||
          endpoint === "getDriveItemPreview" ||
          endpoint === "getUsers");

      if (useTrustedPartnerToken) {
        const accessTokenForTenant = getAccessTokenForTenant(
          getState(),
          clientId
        );

        if (!accessTokenForTenant) {
          throw new Error("No access token for client available");
        } else {
          headers.set("Authorization", `Bearer ${accessTokenForTenant}`);
        }
      } else {
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
          if (
            endpoint != "getContractAISignature" &&
            endpoint != "addContractAISignature" &&
            endpoint != "getSignatureDocumentContent" &&
            endpoint != "addSignatureDocumentContent" &&
            endpoint != "getReSignContract" &&
            endpoint != "getDocumentContent"
          ) {
            throw new Error("No access token available");
          }
        }
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrganization: builder.query<any, string>({
      query: (tenantId) => ({
        url: `/organization/${tenantId}`,
        method: "GET",
      }),
    }),
    getOrganizationBranding: builder.query<IOrganizationBranding, string>({
      query: (tenantId) => ({
        url: `/organization/${tenantId}/branding/localizations`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        if (response?.value?.length > 0) {
          return response?.value?.map((r: any) => ({
            ...r,
            headerLogoRelativeUrl: `https://${r.cdnList[0]}/${r.headerLogoRelativeUrl}`,
            bannerLogoRelativeUrl: `https://${r.cdnList[0]}/${r.bannerLogoRelativeUrl}`,
            squareLogoDarkRelativeUrl: `https://${r.cdnList[0]}/${r.squareLogoDarkRelativeUrl}`,
            squareLogoRelativeUrl: `https://${r.cdnList[0]}/${r.squareLogoRelativeUrl}`,
          }))[0];
        }

        return null;
      },
    }),
    getUserProfile: builder.query<any, void>({
      query: () => ({
        url: "/me",
        method: "GET",
      }),
    }),
    getUserProfileForUser: builder.query<any, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
    }),
    getUserPhoto: builder.mutation<string, void>({
      query: () => ({
        url: "/me/photo/$value",
        method: "GET",
        responseHandler: (response: any) =>
          response.blob().then((blob: any) => URL.createObjectURL(blob)), // https://github.com/gfortaine/rtk-query-nextjs-example/blob/responseHandler/blob/src/pages/index.tsx
      }),
    }),
    getUserPhotoForUser: builder.mutation<string, string>({
      query: (id) => ({
        url: `/users/${id}/photo/$value`,
        method: "GET",
        responseHandler: (response: any) =>
          response.blob().then((blob: any) => URL.createObjectURL(blob)), // https://github.com/gfortaine/rtk-query-nextjs-example/blob/responseHandler/blob/src/pages/index.tsx
      }),
    }),
    getUsers: builder.query<any, { nextPage?: string }>({
      query: ({ nextPage }) => ({
        url: nextPage || `/users?$top=999`, // ?$filter=userType eq 'Member' or userType eq 'Guest'&$sortBy=displayName", // for this we need user.read.all instead of user.readbasic.all
        method: "GET",
      }),
      // transformResponse: (response: any) => {
      //   const users = response.value;
      //   // this.fetchNextPage(response["@odata.nextLink"]);
      //   // sort the users by displayName
      //   users.sort((a: any, b: any) =>
      //     a.displayName.localeCompare(b.displayName)
      //   );

      //   if (response["@odata.nextLink"]) {
      //   }

      //   return users;
      // },
    }),
    getSites: builder.query<any[], void>({
      query: () => ({
        url: "/sites",
        method: "GET",
      }),
      // order the response by displayName
      transformResponse: (response: any) =>
        response.value.sort((a: any, b: any) =>
          a.displayName.localeCompare(b.displayName)
        ),
    }),
    getFollowedSites: builder.query<any[], void>({
      query: () => ({
        url: "/me/followedSites",
        method: "GET",
      }),
      // order the response by displayName
      transformResponse: (response: any) =>
        response.value.sort((a: any, b: any) =>
          a.displayName.localeCompare(b.displayName)
        ),
    }),
    getDrives: builder.query<any[], string>({
      query: (siteId) => ({
        url: `/sites/${siteId}/drives`,
        method: "GET",
      }),
      // order the response by name
      transformResponse: (response: any) =>
        response.value.sort((a: any, b: any) => a.name.localeCompare(b.name)),
    }),
    getDrive: builder.query<IDrive, string>({
      query: (driveId) => ({
        url: `/drives/${driveId}`,
        method: "GET",
      }),
    }),
    getDriveItems: builder.query<IDriveItem[], string>({
      query: (driveId) => ({
        url: `/drives/${driveId}/root/children`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // do not return folders
        return response?.value;
      },
    }),
    getDriveItemChildren: builder.query<IDriveItem[], any>({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/children`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value;
      },
    }),
    getDriveItemChildrenByPath: builder.query<IDriveItem[], any>({
      query: ({ driveId, path }) => ({
        url: `/drives/${driveId}/root:/${path}:/children`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value;
      },
    }),
    // getDriveItemContent: builder.query({
    //   query: ({ driveId, itemId }) => ({
    //     url: `/drives/${driveId}/items/${itemId}/content`,
    //     method: "GET",
    //     // responseHandler: (response: any) => response.blob().then((blob: any) => blob),
    //   }),
    // }),
    getDriveItemThumbnails: builder.query({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/thumbnails`,
        method: "GET",
      }),
    }),
    getDriveItem: builder.query({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}`,
        method: "GET",
      }),
    }),
    getDriveItemContent: builder.query<any, Partial<IDocument>>({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/content`,
        method: "GET",
        responseHandler: (response: any) => {
          // return the Location header if the response is a redirect 302
          if (response.redirected && response.ok) {
            // return response.headers.location;
            return response.url;
          }

          return null;
        },
      }),
    }),
    getDriveItemContentAsPDF: builder.query<any, Partial<IDocument>>({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/content?format=pdf`,
        method: "GET",
        responseHandler: (response: any) => {
          // return the Location header if the response is a redirect 302
          if (response.redirected && response.ok) {
            // return response.headers.location;
            return response.url;
          }

          return null;
        },
        // responseHandler: (response: any) => response.blob().then((blob: any) => URL.createObjectURL(blob)),
      }),
    }),
    getDriveItemVersions: builder.query({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/versions`,
        method: "GET",
      }),
      transformResponse: (response: any) => response?.value,
    }),
    getDriveItemVersionContent: builder.query({
      query: ({ driveId, driveItemId, versionId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/versions/${versionId}/content`,
        method: "GET",
        responseHandler: (response: any) => {
          // return the Location header if the response is a redirect 302
          if (response.redirected && response.ok) {
            // return response.headers.location;
            return response.url;
          }

          return null;
        },
      }),
    }),
    getDriveItemVersionContentAsPDF: builder.query({
      query: ({ driveId, driveItemId, versionId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/versions/${versionId}/content?format=pdf`,
        method: "GET",
        responseHandler: (response: any) => {
          // return the Location header if the response is a redirect 302
          if (response.redirected && response.ok) {
            // return response.headers.location;
            return response.url;
          }

          return null;
        },
      }),
    }),
    getDriveItemPreview: builder.mutation({
      query: ({ driveId, driveItemId }) => ({
        url: `/drives/${driveId}/items/${driveItemId}/preview`,
        method: "POST",
      }),
      transformResponse: (response: any) => response?.getUrl,
    }),
    getRecentOneDriveFiles: builder.query<IDriveItem[], void>({
      query: () => ({
        url: "/me/drive/recent",
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value?.map((item: any) => {
          let modified = null;
          if (item.lastModifiedDateTime) {
            modified = item.lastModifiedDateTime; // "2023-12-12T13:01:52Z";
          }

          const sizeFriendly = item.size
            ? item.size < 1024
              ? `${item.size} bytes`
              : item.size < 1048576
              ? `${Math.round(item.size / 1024)} KB`
              : `${Math.round(item.size / 1048576)} MB`
            : "";

          return { ...item, modified: modified, sizeFriendly: sizeFriendly };
        });
      },
    }),
    getSharedWithMeFiles: builder.query<IDriveItem[], void>({
      query: () => ({
        url: "/me/drive/sharedWithMe",
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value?.map((item: any) => {
          let modified = null;
          if (item.lastModifiedDateTime) {
            const date = item.lastModifiedDateTime; // "2023-12-12T13:01:52Z";
            modified = new Date(date);
          }

          const sizeFriendly = item.size
            ? item.size < 1024
              ? `${item.size} bytes`
              : item.size < 1048576
              ? `${Math.round(item.size / 1024)} KB`
              : `${Math.round(item.size / 1048576)} MB`
            : "";

          return { ...item, modified: modified, sizeFriendly: sizeFriendly };
        });
      },
    }),
    getSite: builder.query<any, string>({
      query: (siteId) => ({
        url: `/sites/${siteId}`,
        method: "GET",
      }),
    }),
    getSiteLogoUrl: builder.query<string, string>({
      query: (siteId) => ({
        url: `/sites/${siteId}/drive/root/webUrl`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response.webUrl.replace(
          "/SitePages/Home.aspx",
          "/SiteAssets/logo.png"
        );
      },
    }),
    sendMessageToQueue: builder.mutation<any, any>({
      queryFn: async (
        args,
        { signal, dispatch, getState },
        extraOptions,
        baseQuery
      ) => {
        try {
          const logicAppUrl =
            "https://prod-01.eastus.logic.azure.com:443/workflows/a8e37f0a1ec043f49b7bce15e10897f6/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-rUxTNUl7tQy3U7p4xTm7ljd-T0r1n221S5A4QbaE7g";
          const method = "POST";
          const data = args;
          const params = {};

          const result = await axios({
            url: logicAppUrl,
            method,
            data,
            params,
          });
          return { data: result.data } as any;
        } catch (axiosError) {
          let err = axiosError as AxiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          } as any;
        }
      },
    }),
    getOutlookMessagesCount: builder.query<number, void>({
      query: () => ({
        url: "/me/messages/$count?$filter=hasAttachments eq true",
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getOutlookMessages: builder.query<IOutlookMessage[], void>({
      query: () => ({
        url: `/me/messages?$filter=receivedDateTime le ${encodeURI(
          new Date().toISOString()
        )} and hasAttachments eq true&$orderby=receivedDateTime desc&$top=500`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value;
      },
    }),
    getOutlookMessageAttachments: builder.query<
      IOutlookMessageAttachment[],
      string
    >({
      query: (messageId) => ({
        url: `/me/messages/${messageId}/attachments`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value?.map((item: any) => {
          const sizeFriendly = item.size
            ? item.size < 1024
              ? `${item.size} bytes`
              : item.size < 1048576
              ? `${Math.round(item.size / 1024)} KB`
              : `${Math.round(item.size / 1048576)} MB`
            : "";

          return { ...item, sizeFriendly: sizeFriendly };
        });
      },
    }),
    getOutlookMessageAttachment: builder.query({
      query: ({ messageId, attachmentId }) => ({
        url: `/me/messages/${messageId}/attachments/${attachmentId}`,
        method: "GET",
      }),
    }),
    getOutlookMessageAttachmentContent: builder.query({
      query: ({ messageId, attachmentId }) => ({
        url: `/me/messages/${messageId}/attachments/${attachmentId}/$value`,
        method: "GET",
        responseHandler: (response: any) => {
          console.log(response);

          const blob = response.blob();

          // const url = URL.createObjectURL(blob);

          return blob; // https://github.com/gfortaine/rtk-query-nextjs-example/blob/responseHandler/blob/src/pages/index.tsx
        },
      }),
    }),
    searchOutlookMessages: builder.query({
      query: ({ query }) => ({
        url: `/me/messages?$search="${query}"`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.value;
      },
    }),
    getJoinedTeams: builder.query<any, void>({
      query: () => ({
        url: "/me/joinedTeams",
        method: "GET",
      }),
      transformResponse: (response: any) => response?.value,
    }),
    getTeamDriveItems: builder.query<any[], string>({
      query: (teamId) => ({
        url: `/groups/${teamId}/drive/items/root/children`,
        method: "GET",
      }),
      transformResponse: (response: any) => response?.value,
    }),
    getTeamDriveItemChildren: builder.query<
      IDriveItem[],
      { teamId: string; driveItemId: string }
    >({
      query: ({ teamId, driveItemId }) => ({
        url: `/groups/${teamId}/drive/items/${driveItemId}/children`,
        method: "GET",
      }),
      transformResponse: (response: any) => response?.value,
    }),
  }),
});

export const {
  useGetOrganizationQuery,
  useGetOrganizationBrandingQuery,
  useGetUserProfileQuery,
  useGetUserPhotoMutation,
  useGetUserPhotoForUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserProfileForUserQuery,
  useGetSitesQuery,
  useLazyGetSitesQuery,

  useGetFollowedSitesQuery,
  useLazyGetFollowedSitesQuery,
  useGetDrivesQuery,
  useLazyGetDrivesQuery,
  useGetDriveItemsQuery,
  useLazyGetDriveItemsQuery,
  useGetDriveItemThumbnailsQuery,
  useLazyGetDriveItemThumbnailsQuery,
  useGetDriveItemChildrenQuery,
  useLazyGetDriveItemChildrenQuery,
  useLazyGetDriveItemChildrenByPathQuery,
  useGetDriveItemQuery,
  useLazyGetDriveItemQuery,
  useGetDriveItemContentQuery,
  useLazyGetDriveItemContentQuery,
  useGetDriveItemContentAsPDFQuery,
  useLazyGetDriveItemContentAsPDFQuery,
  useGetDriveItemVersionsQuery,
  useGetDriveItemVersionContentQuery,
  useGetDriveItemVersionContentAsPDFQuery,
  useGetDriveItemPreviewMutation,
  useGetSiteQuery,
  useLazyGetSiteQuery,
  useGetDriveQuery,
  useSendMessageToQueueMutation,
  useLazyGetOutlookMessagesCountQuery,
  useGetOutlookMessagesQuery,
  useLazyGetOutlookMessagesQuery,
  useGetOutlookMessageAttachmentsQuery,
  useLazyGetOutlookMessageAttachmentsQuery,
  useLazyGetOutlookMessageAttachmentQuery,
  useGetOutlookMessageAttachmentContentQuery,
  useLazyGetOutlookMessageAttachmentContentQuery,
  useLazySearchOutlookMessagesQuery,
  useLazyGetRecentOneDriveFilesQuery,
  useLazyGetSharedWithMeFilesQuery,

  useLazyGetJoinedTeamsQuery,
  useLazyGetTeamDriveItemsQuery,
  useLazyGetTeamDriveItemChildrenQuery,
} = GraphApi;
