import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ReactNode } from "react";

// Define the type for your button component
type ButtonComponent = React.ReactNode;

// Define the initial state type
interface FooterState {
  buttonsLeft: ButtonComponent[];
  buttonsCenter: ButtonComponent[];
  buttonsRight: ButtonComponent[];
  infoText: JSX.Element | undefined;
}

// Define the initial state
const initialState: FooterState = {
  buttonsLeft: [],
  buttonsCenter: [],
  buttonsRight: [],
  infoText: undefined,
};

// Create a slice for managing the footer buttons state
export const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    // Action to update the footer buttons
    updateFooterButtonsLeft: (
      state,
      action: PayloadAction<ButtonComponent[]>
    ) => {
      state.buttonsLeft = action.payload;
    },
    updateFooterButtonsCenter: (
      state,
      action: PayloadAction<ButtonComponent[]>
    ) => {
      state.buttonsCenter = action.payload;
    },
    updateFooterButtonsRight: (
      state,
      action: PayloadAction<ButtonComponent[]>
    ) => {
      state.buttonsRight = action.payload;
    },
    updateFooterInfoText: (state, action: PayloadAction<JSX.Element>) => {
      state.infoText = action.payload;
    },
  },
});

// Export the actions
export const {
  updateFooterButtonsLeft,
  updateFooterButtonsCenter,
  updateFooterButtonsRight,
  updateFooterInfoText,
} = footerSlice.actions;

// Selectors to access footer state
export const selectFooterButtonsLeft = (state: RootState) =>
  state.footer.buttonsLeft;
export const selectFooterButtonsCenter = (state: RootState) =>
  state.footer.buttonsCenter;
export const selectFooterButtonsRight = (state: RootState) =>
  state.footer.buttonsRight;
export const selectFooterInfoText = (state: RootState) => state.footer.infoText;

// Export the reducer
export default footerSlice.reducer;
