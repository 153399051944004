import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Cancel,
  Edit as EditIcon,
} from "@mui/icons-material";

import {
  DataGrid,
  GridColDef,
  useGridApiRef,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { IGraphUser } from "../../types/user";
import AzureADAvatar from "../avatars/AzureADAvatar";
import { useGetContractsQuery } from "../../redux/slices/indexApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import { format } from "date-fns";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import ContractDialog from "../dialogs/ContractDialog";
import { DialogMode } from "../../types/dialogmode";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../types/contract";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 32px;
  border-radius: 16px;
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface IDataGridContractsUnderManagementProps {
  managed?: boolean;
  selected?: string[];
  onSelect?: (model: GridRowSelectionModel) => void;
  view?: "cards" | "table";
  clientId: string;
}

function DataGridContractsUnderManagement({
  ...props
}: IDataGridContractsUnderManagementProps) {
  const { t } = useTranslation();
  const { clientId } = props;

  const {
    data: contractsUnderManagement = [],
    isLoading,
    isSuccess,
    isUninitialized,
  } = useGetContractsQuery(ContractStatus.ALL);

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const [title, setTitle] = useState<string>("");

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "name",
      headerName: t("Name"),
      disableColumnMenu: true,
      cellClassName: "name-column--cell",
      renderCell: (cellValues: any) => {
        return (
          <Tooltip title={cellValues.row?.name} placement="top">
            <Typography
              variant="body2"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {/* {cellValues.row?.analyzeStatus === ReadingStatus.READING && (
                <Chip
                  sx={{ mr: 2 }}
                  label={t("Analyzing")}
                  color="primary"
                  variant="outlined"
                />
              )} */}

              {cellValues.row?.analyzeStatus === AIReadingStatus.ANALYZING && (
                <Chip
                  sx={{ mr: 2 }}
                  label={t("Reading")}
                  color="primary"
                  variant="outlined"
                />
              )}

              {cellValues?.row?.name}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      flex: 1,
      field: "vendorName",
      headerName: t("Vendor"),
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   return (
      // cellValues?.row?.vendorId && (
      //   <VendorAvatar vendorId={cellValues?.row?.vendorId} small />
      // )
      //   );
      // },
    },
    {
      flex: 1,
      field: "Category",
      headerName: t("Category"),
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "summary",
      headerName: t("Summary"),
      disableColumnMenu: true,
      // multi line
      renderCell: (cellValues: any) => {
        return (
          <Tooltip title={cellValues.row?.summary} placement="bottom">
            <Typography
              variant="body2"
              fontSize={11}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textWrap: "wrap",
              }}
            >
              {cellValues?.row?.summary}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "startDate",
      headerName: t("Start date")!,
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   return cellValues.row?.startDate && format(new Date(cellValues.row?.startDate), "P");
      // },
      valueFormatter: (params: any) => {
        return params?.value && format(new Date(params?.value), "P");
      },
    },
    {
      field: "endDate",
      headerName: t("End date")!,
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   return cellValues.row?.endDate && format(new Date(cellValues.row?.endDate), "P");
      // },
      valueFormatter: (params: any) => {
        return params?.value && format(new Date(params?.value), "P");
      },
    },
    {
      field: "budget",
      headerName: t("Value")!,
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   const formattedCurrency = new Intl.NumberFormat(navigator.language, {
      //     style: "currency",
      //     currency: "EUR", // Replace with your desired currency code
      //     compactDisplay: "long",
      //     maximumFractionDigits: 0,
      //   }).format(cellValues.row?.value); // Format the number as currency

      //   return cellValues.row?.value && formattedCurrency;
      // },
      valueFormatter: (params: any) => {
        if (params?.value?.amount) {
          const formattedCurrency = new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: params?.value?.currency || "EUR",
            compactDisplay: "long",
            maximumFractionDigits: 0,
          }).format(params?.value?.amount); // Format the number as currency

          return formattedCurrency;
        }

        return "";
      },
    },
    {
      field: "adminIds",
      headerName: t("Administrator")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <AvatarGroup
            max={3}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                },
              },
            }}
          >
            {cellValues.row?.adminIds?.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azuread-avatar-${userId}`}
                  userId={userId}
                  pictureOnly
                />
              );
            })}
          </AvatarGroup>
        );
      },
    },
    {
      field: "managerIds",
      headerName: t("Manager")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <AvatarGroup
            max={3}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                },
              },
            }}
          >
            {cellValues.row?.managerIds?.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azuread-avatar-${userId}`}
                  userId={userId}
                  pictureOnly
                />
              );
            })}
          </AvatarGroup>
        );
      },
    },
    {
      field: "ownerIds",
      headerName: t("Owner")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <AvatarGroup
            max={3}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                },
              },
            }}
          >
            {cellValues.row?.ownerIds?.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azuread-avatar-${userId}`}
                  userId={userId}
                  pictureOnly
                />
              );
            })}
          </AvatarGroup>
        );
      },
    },
    // {
    //   flex: 1,
    //   field: "assignees",
    //   headerName: t("Assignees")!,
    //   disableColumnMenu: true,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       cellValues?.row?.assigneeIds?.length > 0 && (
    //         <AvatarGroup total={cellValues.row.assigneeIds.length}>
    //           {cellValues.row.assigneeIds?.map((assigneeId: string) => {
    //             return <AzureADAvatar key={`azure-ad-avatar-${assigneeId}`} userId={assigneeId} small={cellValues.row.assigneeIds.length === 1} />;
    //           })}
    //         </AvatarGroup>
    //       )
    //     );
    //   },
    // },
    {
      field: "tenantId",
      headerName: t("Organization")!,
      disableColumnMenu: true,
    },
    {
      field: "tenantId",
      headerName: t("Organization")!,
      disableColumnMenu: true,
    },
    {
      // flex: 1,
      field: "status",
      headerName: t("Status")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <Chip
            label={t(cellValues.row?.status)}
            color={
              cellValues.row?.status === ContractStatus.PUBLISHED
                ? "success"
                : "info"
            }
          />
        );
      },
    },
    {
      headerName: t("Action")!,
      minWidth: 140,
      field: "action",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues: any) => {
        return (
          <Grid container gap={1} direction="row" wrap="nowrap">
            <Grid>
              <IconButton
                size="small"
                aria-label="view"
                onClick={(event) => {
                  handleViewDetails(event, cellValues);
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </Grid>

            {/* <Grid>
              <IconButton
                size="small"
                aria-label="view"
                onClick={(event) => {
                  handleEditDetails(event, cellValues);
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid> */}

            {!props.managed && (
              <Grid>
                <ContractDialog
                  contract={cellValues.row}
                  mode={DialogMode.Delete}
                  iconOnly
                />
              </Grid>
            )}
          </Grid>
        );
      },
    },
  ].filter((column: GridColDef) => {
    if (!props.managed && column.field === "tenantId") {
      return false;
    }

    return true;
  });

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      const p = contractsUnderManagement?.filter(
        (c: IContract) => c.status === ContractStatus.PUBLISHED
      );

      const d = contractsUnderManagement?.filter(
        (c: IContract) => c.status !== ContractStatus.PUBLISHED
      );

      const pText = p.length === 1 ? t("published") : t("published");
      const draftsText = d.length === 1 ? t("draft") : t("drafts");

      const els = [];
      if (p.length > 0) {
        els.push(`${p?.length} ${pText}`);
      }

      if (d.length > 0) {
        els.push(`${d?.length} ${draftsText}`);
      }

      const newTitle = els.join(", ").toLowerCase();
      setTitle(newTitle);
    }
  }, [contractsUnderManagement, isSuccess, isLoading]);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    props?.onSelect && props?.onSelect(rowSelectionModel);
  }, [rowSelectionModel]);

  const navigate = useNavigate();

  const handleRowDoubleClick = (params: any) => {
    navigate(
      `/contracts-under-management/${clientId}/contracts/${params.row.id}`,
      { state: { reload: true } }
    );
  };

  const handleViewDetails = (event: any, cellValues: any) => {
    // stop the row selection event from triggering
    event.stopPropagation();

    navigate(
      `/contracts-under-management/${clientId}/contracts/${cellValues.row.id}`,
      { state: { reload: true } }
    );
  };

  const handleEditDetails = (event: any, cellValues: any) => {
    // stop the row selection event from triggering
    event.stopPropagation();

    navigate(
      `/contracts-under-management/${clientId}/contracts/${cellValues.row.id}/edit`,
      { state: { reload: true } }
    );
  };

  return (
    <Paper mb={16}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        {isLoading && <CircularProgress sx={{ margin: 4 }} />}

        {isSuccess && contractsUnderManagement?.length === 0 && (
          <Box p={6}>
            <CustomNoRowsOverlay label={t("No contracts")} />
          </Box>
        )}

        {!isLoading && isSuccess && contractsUnderManagement?.length > 0 && (
          <DataGrid
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 25 } },
            }}
            apiRef={apiRef}
            pageSizeOptions={[5, 10, 25]}
            rows={contractsUnderManagement}
            columns={columns}
            autoHeight
            checkboxSelection
            loading={isLoading}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: () => (
                <EnhancedDataGridToolbar
                  view="table"
                  // setView={setView}
                  rowSelectionModel={rowSelectionModel}
                  title={props.managed ? t("Managed Contracts") : title}
                  isLoading={isLoading}
                />
              ),
              noRowsOverlay: () => (
                <CustomNoRowsOverlay label={t("No contracts")} />
              ),
            }} // GridToolbar    GridToolbarQuickFilter
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
              // pagination: {
              //   labelRowsPerPage: t("Rows per page"),
              //   labelDisplayedRows(paginationInfo) {
              //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
              //   },
              // },
            }}
            sx={{
              "& .MuiDataGrid-cell": {
                alignContent: "center",
              },
            }}
            density="comfortable"
            onRowDoubleClick={handleRowDoubleClick}
          />
        )}
      </Box>
    </Paper>
  );
}

export default DataGridContractsUnderManagement;
