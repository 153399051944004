// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";
import { Author, ChatType, IChatBotHistory } from "../../types/chatBot";
import { IQuestion } from "../../types/question";
import i18next from "i18next";

export const ChatBotApi: any = createApi({
  reducerPath: "ChatBotApi",

  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers: any, { getState, endpoint }: any) => {
      const accessToken = getState().auth.accessToken; // Retrieve the access token

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      } else {
        if (
          endpoint != "getContractAISignature" &&
          endpoint != "addContractAISignature" &&
          endpoint != "getSignatureDocumentContent" &&
          endpoint != "addSignatureDocumentContent" &&
          endpoint != "getReSignContract" &&
          endpoint != "getDocumentContent"
        ) {
          throw new Error("No access token available");
        }
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getChatBotHistory: builder.query<IChatBotHistory, IChatBotHistory>({
      query: () => ({
        url: `/chat-bot`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data ?? [];
      },
    }),
    getChatBox: builder.query<any, any>({
      query: ({ id }) => ({
        url: `/chat-bot/${id}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data ?? [];
      },
    }),
    addChatBot: builder.mutation<any, IQuestion>({
      query: ({ question, document, type, contractId, vendorId }) => {
        return {
          url: `/chat-bot`,
          method: "POST",
          body: {
            Q: question,
            type,
            document,
            Locale: i18next.language,
            contractID: contractId,
            vendorID: vendorId,
          },
        };
      },
      transformResponse: (response: any) => {
        return { id: response.data.id, aiRes: response.data.aiRes };
      },
    }),
    updateChatBot: builder.mutation<any, IQuestion>({
      query: ({ id, question, document, type, contractId, vendorId }) => {
        return {
          url: `/chat-bot/${id}`,
          method: "PUT",
          body: {
            Q: question,
            type,
            document,
            Locale: i18next.language,
            contractID: contractId,
            vendorID: vendorId,
          },
        };
      },
      transformResponse: (response: any) => {
        return { id: response.data.id, aiRes: response.data.aiRes };
      },
    }),
  }),
});

export const {
  useGetChatBoxQuery,
  useGetChatBotHistoryQuery,
  useUpdateChatBotMutation,
  useAddChatBotMutation,
} = ChatBotApi;
