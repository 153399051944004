import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { AuthState } from "../types/auth";
import { firebaseConfig } from "../config";
import { useDispatch } from "react-redux";
import { useGetUserPhotoForUserMutation } from "../redux/slices/graphApiSlice";
import { setAccessToken, setTenantId } from "../redux/slices/authSlice";
import {
  login as firebaseAuth,
  initialize as firebaseInit,
  signOut as firebaseSignOut,
  signInWithEmailAndPassword,
} from "../components/auth/firebaseAuth";
import {
  login as msalAuth,
  initialize as msalInit,
  signOut as msalSignOut,
} from "../components/auth/msalAuth";
import {
  useUpdateLibraryKPIMutation,
  useUpdateUserMutation,
} from "../redux/slices/indexApiSlice";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state: AuthState, action: any) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SIGN_IN) {
    const { user, tenantId } = action.payload;
    return { ...state, isAuthenticated: true, user, tenantId };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<any | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<
    firebase.firestore.DocumentData | undefined
  >();
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchToStore = useDispatch(); // Get the dispatch function from react-redux
  const [
    getUserPhoto,
    { data: userPhoto, isLoading: photoLoading, isSuccess: photoSuccess },
  ] = useGetUserPhotoForUserMutation(
    state.user?.id ?? { skipToken: !state.user?.id }
  );
  const [updateUser, { isSuccess: isUpdatedUser, isLoading: isUserLoading }] =
    useUpdateUserMutation();

  useEffect(() => {
    switch (localStorage.getItem("loginType")) {
      case "MSAL":
        initializeMSAL();
        break;
      case "Google":
        initializeFirebase();
        break;
      case "password":
        initializeFirebase();
        break;
      default:
        initializeMSAL();
        break;
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      state.isAuthenticated &&
      userPhoto &&
      localStorage.getItem("loginType") == "MSAL"
    ) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: state.isAuthenticated,
          user: { ...state?.user, avatar: userPhoto },
        },
      });
    }
  }, [state.isAuthenticated, userPhoto]);

  const initializeFirebase = async () => {
    firebaseInit().onAuthStateChanged((user) => {
      if (user) {
        if (user.photoURL) {
          setProfile({ ...profile, avatar: user.photoURL });
        }

        user.getIdToken().then((token) => {
          dispatchToStore(setAccessToken(token)); // Dispatch the action with the access token
        });
        const userData = {
          id: user?.uid,
          avatar: user?.photoURL,
          email: user?.email,
          displayName: user?.displayName,
          role: "user",
        };
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: true, user: userData },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    });
  };

  const initializeMSAL = async () => {
    msalInit().then(async (authenticationResult) => {
      const isAuthenticated = !!(
        authenticationResult && authenticationResult.accessToken !== null
      );
      let user = null;

      if (authenticationResult && isAuthenticated) {
        dispatchToStore(setAccessToken(authenticationResult.accessToken)); // Dispatch the action with the access token
        dispatchToStore(setTenantId(authenticationResult.tenantId)); // Dispatch the action with the access token

        // get the profile picture from the Microsoft Graph API
        await getUserPhoto(authenticationResult.uniqueId);

        user = {
          id: authenticationResult.uniqueId,
          avatar: photoSuccess ? userPhoto : null, // state?.user?.picture,
          email: authenticationResult.account?.username,
          displayName: authenticationResult.account?.name,
          role: "user",
        };
      }

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated,
          user,
        },
      });
    });
  };

  const signOut = async () => {
    switch (localStorage.getItem("loginType")) {
      case "MSAL":
        msalSignOut();
        break;
      case "Google":
        firebaseSignOut();
        break;
      default:
        break;
    }
    dispatch({ type: SIGN_OUT });
  };

  const passwordLogin = async (
    email: string,
    password: string
  ): Promise<void> => {
    return signInWithEmailAndPassword(email, password).then(
      async (user: any) => {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: user,
          },
        });

        await updateUser(user);
      }
    );
  };

  const firebaseLogin = async () => {
    return firebaseAuth().then(async (user) => {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          user: user,
        },
      });
      await updateUser(user);
    });
  };

  const msalLogin = async () => {
    const authenticationResult = await msalAuth();
    const isAuthenticated = authenticationResult.accessToken !== null;
    let user = null;
    if (authenticationResult && isAuthenticated) {
      dispatchToStore(setAccessToken(authenticationResult.accessToken)); // Dispatch the action with the access token
      dispatchToStore(setTenantId(authenticationResult.tenantId)); // Dispatch the action with the access token
      // get the profile picture from the Microsoft Graph API
      await getUserPhoto(authenticationResult.uniqueId);
      user = {
        id: authenticationResult.uniqueId,
        avatar: photoSuccess ? userPhoto : null,
        email: authenticationResult.account?.username,
        displayName: authenticationResult.account?.name,
        role: "user",
      };
    }
    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated,
        user,
      },
    });
    await updateUser(user);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: localStorage.getItem("loginType") ?? "Google",
        user: {
          id: auth?.id || state.user?.uid,
          email: auth.email,
          avatar: auth.avatar || profile?.avatar,
          displayName: auth.displayName || profile?.displayName,
          role: "user",
        },
        passwordLogin,
        firebaseLogin,
        msalLogin,
        signOut,
        error: null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
