import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Alert,
} from "@mui/material";
import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";
import { DarkMode, LightMode } from "@mui/icons-material";
import {
  useGetContractsQuery,
  useGetSubscriptionInfoQuery,
} from "../../redux/slices/indexApiSlice";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

type themeOptionsType = {
  [key: string]: {
    img: string;
    name: string;
    theme: any;
  };
};

function SubscriptionInfo() {
  const { t } = useTranslation();

  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery();

  return (
    <React.Fragment>
      <Alert severity="info">
        {t("subscriptionInfo", {
          contractsLimit: subscriptionInfo?.contractsLimit,
        })}
      </Alert>
    </React.Fragment>
  );
}

export default SubscriptionInfo;
