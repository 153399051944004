import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const initialize = () => {
  return firebase.auth();
};

export const login = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return await firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      var user = result.user;
      return {
        id: user?.uid,
        avatar: user?.photoURL,
        email: user?.email,
        displayName: user?.displayName,
        role: "user",
      };
    });
};

export const signInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((result) => {
      var user = result.user;
      return {
        id: user?.uid,
        avatar: user?.photoURL,
        email: user?.email,
        displayName: user?.displayName,
        role: "user",
      };
    });
};

export const signOut = async () => {
  await firebase.auth().signOut();
};
